const compareLastNames = (a, b) => {
    a = a.name;
    b = b.name;
    let splitA = a.split(" ");
    let splitB = b.split(" ");
    let lastA = splitA[splitA.length - 1];
    let lastB = splitB[splitB.length - 1];

    if (lastA < lastB) return -1;
    if (lastA > lastB) return 1;
    return 0;
};

const getInitials = (name) => {
    if (typeof name !== "string") return "";
    var names = name.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

const sortAlphabetically = (arr, key = null) =>
    Array.isArray(arr) &&
    arr.sort((a, b) => {
        if (typeof a === "string" && typeof b === "string") {
            return a.localeCompare(b);
        } else if (
            typeof a === "object" &&
            !Array.isArray(a) &&
            typeof b === "object" &&
            !Array.isArray(b) &&
            !!key
        ) {
            return a[key].localeCompare(b[key]);
        }
        return;
    });

export { compareLastNames, getInitials, sortAlphabetically };
