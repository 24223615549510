import { useAuth } from "../contexts/authContext";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { sortAlphabetically } from "../helpers/nameHelpers";

const sortKey = "name";

const useFilteredAdvisers = () => {
    const {
        user: { details: userDetails },
    } = useAuth();
    const userState = useSelector((state) => state.userReducer);

    return useMemo(
        () =>
            sortAlphabetically(
                Object.values(userState.users).filter((adviser) =>
                    userDetails.adviceTypes?.some((x) =>
                        adviser.adviceTypes?.includes(x)
                    )
                ),
                sortKey
            ),
        [userState.users, userDetails.adviceTypes]
    );
};

export default useFilteredAdvisers;
