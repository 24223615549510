import React, { useState } from "react";
import { Card, CardContent, Box, Typography, CircularProgress, makeStyles, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import useCaseActions from "../hooks/queries/useCaseActions";
import CaseAction from "../components/CaseAction";
import { grey } from "@material-ui/core/colors";
import CaseActionDialog from "../components/CaseActionDialog";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(6),
    },
    iconButton: {
        padding: "2px",
        marginTop: "-2px",
        marginLeft: "2px",
        minWidth: 0,
        color: grey[700],
    },
}));

const CaseActionsCard = ({ caseId }) => {
    const { data, isLoading, isError, error } = useCaseActions({ caseId });
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [activeAction, setActiveAction] = useState({});

    const handleClickEdit = (action) => {
        setActiveAction(action);

        setOpen(true);
    };

    const cardContent = () => {
        if (isLoading)
            return (
                <Box display="flex" justifyContent="center" width="100%">
                    <CircularProgress />
                </Box>
            );

        if (isError)
            return (
                <Box display="flex" justifyContent="center" width="100%">
                    <Alert variant="outlined" severity="error">
                        {error?.message || "Unable to load case actions"}
                    </Alert>
                </Box>
            );

        return data
            .filter((action) => action.completedOn == null)
            .sort((a, b) => new Date(a.due) - new Date(b.due))
            .map((action, index) => <CaseAction key={action.id} action={action} index={index} handleClickEdit={handleClickEdit} />);
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    <Box display="flex" mb={6} justifyContent="space-between">
                        <Typography variant="h6" gutterBottom>
                            Actions
                        </Typography>
                        <Button onClick={() => setOpen(true)} className={classes.iconButton} data-cy="add_action_button">
                            <Add />
                        </Button>
                    </Box>
                    {cardContent()}
                </CardContent>
            </Card>

            <CaseActionDialog open={open} setOpen={setOpen} caseId={caseId} action={activeAction} setAction={setActiveAction} />
        </>
    );
};

export default CaseActionsCard;
