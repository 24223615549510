import React from 'react'
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';

export default function SwitchControl(props) {

    const { name, size, label, checked, onChange } = props;
    //console.log('xxx', { checked });
    return (
        <FormGroup>
            
            <FormControlLabel
                
                control={<Switch size={size} name={name} checked={checked} onChange={onChange} />}
                label={label}
            />
        </FormGroup>
    )
}