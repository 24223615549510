import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import avatarStyles from "../theme/avatars";
import axios from "../plugins/axios";
import { setSnackAction } from "../redux/actions/snackActions";
import { setCaseAdvisers } from "../redux/actions/caseActions";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";

import {
    Badge,
    CardContent,
    Card as MuiCard,
    Button as MuiIconButton,
    Avatar,
    Typography,
    Tooltip,
    Box,
    CircularProgress,
    Menu,
    MenuItem,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { grey } from "@material-ui/core/colors";

import {
    Add as AddIcon,
    Star as PrimaryIcon,
    Delete as DeleteIcon,
    MoreHoriz,
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const CustomMenu = styled(Menu)`
    border-radius: 0px;
    ul {
        padding: 0px;
    }
`;

const LawyerCard = ({ setCaseAdvisers, setSnackAction }) => {
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector(
        (state) => state.caseReducer
    );
    const userState = useSelector((state) => state.userReducer);

    const c = cases[caseId];
    const currentAdviserId = c.caseSummary.currentAdviserId;
    const caseAdvisers = c.advisers;
    const accountId = c.account.accountId;

    const avatarClasses = avatarStyles();
    const [mode, setMode] = useState("view");
    const [adviserId, setAdviserId] = useState(currentAdviserId);

    const [currentCaseAdvisers, setCurrentCaseAdvisers] = useState(null);

    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextAdviser, setContextAdviser] = useState(null);

    const [accountAdvisors, setAccountAdvisors] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    const getCaseAdvisers = () => {
        const adviserIds = caseAdvisers.map((adviser) => adviser.adviserId);

        setCurrentCaseAdvisers(
            Object.values(userState.users)
                .filter((p) => adviserIds.includes(p.userId))
                .map((t) => ({
                    ...t,
                    isPrimary: caseAdvisers.filter(
                        (adv) => adv.adviserId == t.userId
                    )[0].isPrimary,
                }))
        );
    };

    const unusedAdvisers = () => {
        return accountAdvisors
            ? accountAdvisors.filter(
                  (ac) =>
                      !caseAdvisers.map((c) => c.adviserId).includes(ac.userId)
              )
            : [];
    };

    useEffect(() => {
        setAdviserId(currentAdviserId);
        getCaseAdvisers();
        getAccountAdvisors();
    }, [currentAdviserId, caseAdvisers]);

    const getAccountAdvisors = useCallback(async () => {
        if (accountId) {
            //reset when account is altered then gather
            var response = await axios.get(
                `${apiUrl}/getaccountadvisors/${accountId}`
            );
            setAccountAdvisors(response.data);
        }
    }, [accountId]);    

    const ViewAdviser = (adviser) => (
        <Box
            key={adviser.userId}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
            mb={2}
            ml={2}
            mr={2}
            style={{ textAlign: "center" }}
        >
            <Box alignSelf="flex-end">
                <IconButton
                    onClick={(ev) => handleOpenContextMenu(adviser, ev)}
                >
                    <MoreHoriz />
                </IconButton>
            </Box>
            <Box>
                <Tooltip title={adviser.isPrimary ? "Primary Adviser" : ""}>
                    {adviser.isPrimary ? (
                        <Badge
                            overlap="rectangular"
                            variant="dot"
                            color="primary"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <Avatar
                                alt={adviser.name}
                                src={adviser.photo}
                                className={avatarClasses.large}
                            />
                        </Badge>
                    ) : (
                        <Avatar
                            alt={adviser.name}
                            src={adviser.photo}
                            className={avatarClasses.large}
                        />
                    )}
                </Tooltip>
            </Box>
            <Box>
                <Typography variant="subtitle2">{adviser.name}</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2">{adviser.position}</Typography>
            </Box>
        </Box>
    );

    const handleOpenContextMenu = (e, event) => {
        setContextAdviser(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextAdviser(null);
    };

    const handlePrimary = async (adviserId) => {
        handleCloseContextMenu();
        let response = await axios.post(
            `${apiUrl}/setcaseadvisers/${caseId}`,
            currentCaseAdvisers.map((ca) => ({
                adviserId: ca.userId,
                isPrimary: ca.userId === adviserId,
            }))
        );

        if (response.status === 200) {
            setCaseAdvisers(
                caseId,
                currentCaseAdvisers.map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.userId === adviserId,
                }))
            );

            setSnackAction("Saved!", "success");
            setMode("view");
        }
    };

    const handleAdd = async (adviserId) => {
        let response = await axios.post(`${apiUrl}/setcaseadvisers/${caseId}`, [
            ...currentCaseAdvisers.map((ca) => ({
                adviserId: ca.userId,
                isPrimary: ca.isPrimary,
            })),
            ...accountAdvisors
                .filter((ac) => ac.userId === adviserId)
                .map((ac) => ({
                    adviserId: ac.userId,
                    isPrimary: ac.isPrimary,
                })),
        ]);
        if (response.status === 200) {
            setCaseAdvisers(caseId, [
                ...currentCaseAdvisers.map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.isPrimary,
                })),
                ...accountAdvisors
                    .filter((ac) => ac.userId === adviserId)
                    .map((ac) => ({
                        adviserId: ac.userId,
                        isPrimary: ac.isPrimary,
                    })),
            ]);

            setSnackAction("Saved!", "success");
            setMode("view");
        }

        setAnchorEl(null);
    };

    const handleDelete = async (adviserId) => {
        handleCloseContextMenu();
        setMode("saving");

        let response = await axios.post(
            `${apiUrl}/setcaseadvisers/${caseId}`,
            currentCaseAdvisers
                .filter((c) => c.userId !== adviserId)
                .map((ca) => ({
                    adviserId: ca.userId,
                    isPrimary: ca.isPrimary,
                }))
        );
        if (response.status === 200) {
            setCaseAdvisers(
                caseId,
                currentCaseAdvisers
                    .filter((c) => c.userId !== adviserId)
                    .map((ca) => ({
                        adviserId: ca.userId,
                        isPrimary: ca.isPrimary,
                    }))
            );
            setSnackAction("Saved!", "success");
            setMode("view");
        }
    };

    return (
        <React.Fragment>
            <Card mb={6}>
                <CardContent>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>Adviser</Typography>
                        </Box>
                        <Box>
                            {mode !== "saving" && (
                                <IconButton
                                    onClick={(e) =>
                                        setAnchorEl(e.currentTarget.parentNode)
                                    }
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                            {mode === "saving" && (
                                <CircularProgress
                                    size={21}
                                    style={{ marginLeft: "9px" }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-around"
                        flexWrap="wrap"
                        m={-2}
                    >
                        {currentCaseAdvisers &&
                            currentCaseAdvisers.map((e) => ViewAdviser(e))}
                    </Box>
                </CardContent>
            </Card>
            <CustomMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {unusedAdvisers()
                    .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                    .map((c) => (
                        <MenuItem
                            onClick={() => handleAdd(c.userId)}
                            key={c.userId}
                        >
                            <Avatar
                                alt={c.name}
                                src={c.photo}
                                style={{ marginRight: "8px" }}
                            />
                            <Typography variant="body2">
                                {c.name} - {c.position}
                            </Typography>
                        </MenuItem>
                    ))}
            </CustomMenu>
            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={Boolean(contextAnchorEl)}
                onClose={handleCloseContextMenu}
            >
                <MenuItem
                    disabled={contextAdviser && contextAdviser.isPrimary}
                    onClick={() => handlePrimary(contextAdviser.userId)}
                >
                    <PrimaryIcon
                        style={{ marginRight: "6px", color: grey[700] }}
                    />
                    {" Make primary"}
                </MenuItem>
                <MenuItem
                    disabled={contextAdviser && contextAdviser.isPrimary}
                    onClick={() => handleDelete(contextAdviser.userId)}
                >
                    <DeleteIcon
                        style={{ marginRight: "6px", color: grey[700] }}
                    />
                    {" Delete"}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnackAction: (message, severity) => dispatch(setSnackAction(message, severity)),
        setCaseAdvisers: (caseId, advisers) => dispatch(setCaseAdvisers(caseId, advisers)),
    };
};

export default connect(null, mapDispatchToProps)(LawyerCard);
