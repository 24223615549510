import React, { useMemo, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setReplyStarRating } from "../redux/actions/caseActions";
import { alpha } from "@material-ui/core/styles";
import { CheckCircleOutline } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import copilotLogo from "../images/copilot1.svg";

const useStyles = makeStyles((theme) => ({
    star: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
    },
    scoreContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(6),
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    customButton: (props) => ({
        backgroundColor: props.selected
            ? alpha(theme.palette.secondary.main, 0.3)
            : "initial",
        "&:hover": {
            backgroundColor: props.selected
                ? alpha(theme.palette.secondary.main, 0.3)
                : "initial",
        },
        marginTop: theme.spacing(4),
        marginRight: props.isCenter ? theme.spacing(3) : 0,
        marginLeft: props.isCenter ? theme.spacing(3) : 0,
        width: "80px",
    }),
    centerButton: {
        margin: theme.spacing(0, 3),
    },
    feedbackComplete: {
        display: "flex",
        justifyContent: "flex-end",
        color: green[700],
        alignItems: "center",
    },
    feedbackCompleteText: {
        marginLeft: theme.spacing(2),
    },
}));

const labels = [
    "Legally and factually accurate",
    "Risk",
    "Quality of written language",
    "Pragmatic and efficient advice",
    "Client centric approach",
    "Did it answer the question?"
];

const initialState = {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
};

const CustomButton = ({ selected, label, onClick, isCenter }) => {
    const classes = useStyles({ selected, isCenter });

    return (
        <Button
            variant="outlined"
            color="primary"
            className={classes.customButton}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};

const StarRater = ({ caseId, rating }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [ratings, setRatings] = useState(initialState);

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setRatings(initialState);
    };

    const handleClickButton = (index, rating) => {
        setRatings((prev) => ({ ...prev, [index]: rating }));
    };

    const handleSubmit = () => {
        dispatch(setReplyStarRating(caseId, ratings));
        handleCloseDialog();
    };

    const submitDisabled = useMemo(() => {
        return Object.values(ratings).some((x) => x === null);
    }, [ratings]);

    return (
        <>
            <img src={copilotLogo} alt="logo" width={150} />
            {rating === null ? (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        Please complete feedback on the AI generated response
                        before editing/sending the email
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => setDialogOpen(true)}
                    >
                        Complete Feedback
                    </Button>
                </Box>
            ) : (
                <div className={classes.feedbackComplete}>
                    <CheckCircleOutline />
                    <Typography
                        variant="subtitle2"
                        className={classes.feedbackCompleteText}
                    >
                        Feedback Complete
                    </Typography>
                </div>
            )}

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    {labels.map((label, i) => (
                        <div className={classes.scoreContainer}>
                            <Typography variant="subtitle2">{label}</Typography>
                            <div className={classes.buttonContainer}>
                                <CustomButton
                                    label="Poor"
                                    selected={ratings[i] === 1}
                                    onClick={() => handleClickButton(i, 1)}
                                />
                                <CustomButton
                                    label="OK"
                                    selected={ratings[i] === 2}
                                    onClick={() => handleClickButton(i, 2)}
                                    isCenter
                                />
                                <CustomButton
                                    label="Good"
                                    selected={ratings[i] === 3}
                                    onClick={() => handleClickButton(i, 3)}
                                />
                            </div>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        onClick={handleSubmit}
                        disabled={submitDisabled}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StarRater;
