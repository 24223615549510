import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import "../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import PrecedentDialog from "./PrecedentDialog";

import { Search as SearchIcon } from "react-feather";

import {
    Typography,
    Box,
    Button as MuiIconButton,
    Chip as MuiChip,
    Collapse,
    Paper as MuiPaper,
    List,
    ListItemIcon,
    ListItem,
    ListItemText,
    TextField,
    InputAdornment,
} from "@material-ui/core";

import {
    MoveToInbox as InboxIcon,
    FolderOpenRounded as FolderIcon,
    VisibilityTwoTone as PreviewIcon,
    ExpandLess,
    ExpandMore,
} from "@material-ui/icons";

import { red } from "@material-ui/core/colors";
import documentService from "../services/documentService";
import { openFileViewer } from "../redux/actions/userActions";
import {useAuth} from "../contexts/authContext";
import {setSnackAction} from "../redux/actions/snackActions";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${red[700]};
`;

const Chip = styled(MuiChip)`
    margin-left: 10px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0,
    },
    //used for the treeview
    nested: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 30,
    },
    file_nested: {
        paddingLeft: 50,
        paddingTop: 0,
        paddingBottom: 0,
    },
    sub_nested: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 50,
    },
    sub_file_nested: {
        paddingLeft: 70,
        paddingTop: 0,
        paddingBottom: 0,
    },
    dialog: {
        height: "calc(100% - 64px)",
    },
}));

const Paper = styled(MuiPaper)`
    padding: 10px;
    margin-bottom: 10px;
`;

const PrecedentChooser = ({ onSelect, isEmailReply = false }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const configState = useSelector((state) => state.configReducer);
    const { cases, currentCaseId: caseId } = useSelector(
        (state) => state.caseReducer
    );
    const [precedentId, setPrecedentId] = useState(null);

    const c = cases[caseId];
    const sectorId = c.account.sectorId;
    const caseTypeId = c.caseSummary.caseTypeId;

    const sector = useMemo(
        () => configState.sectors[sectorId],
        [configState.sectors, sectorId]
    );
    const caseType = useMemo(
        () => configState.caseTypes[caseTypeId],
        [configState.caseTypes, caseTypeId]
    );

    // TODO: Set to false as part of task 1004 - will be used properly when predecents are set up properly
    // const [sectorFiltered, setSectorFiltered] = useState(!!sector);
    // const [caseTypeFiltered, setCaseTypeFiltered] = useState(true);
    const [sectorFiltered, setSectorFiltered] = useState(false);
    const [caseTypeFiltered, setCaseTypeFiltered] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [selectedSectorIndex, setSelectedSectorIndex] = React.useState("");
    const [selectedCaseTypeIndex, setSelectedCaseTypeIndex] =
        React.useState("");
    const [selectedCaseTypeFolderIndex, setSelectedCaseTypeFolderIndex] =
        React.useState("");
    
    const { user } = useAuth();

    const openDocument = async (attachment) => {
        try {
            const previewPath =
                await documentService.retrieveDocumentPreviewPath({
                    reference: attachment.reference,
                    contentType: attachment.contentType,
                });

            if (previewPath === null) {
                dispatch(setSnackAction("File is password protected and cannot be previewed.", "warning"));
                return;
            }

            let parsedFileName = attachment.filename.split(".");

            dispatch(
                openFileViewer(
                    {
                        type: parsedFileName[parsedFileName.length - 1],
                        path: previewPath,
                        name: attachment.filename,
                    },
                    caseId,
                    attachment
                )
            );
        } catch (e) {
            console.error(e);
        }
    };

    const usedPrecedents = useMemo(() => {
        //precedents are either docs to be filled or physical files (pdf, jog, etc).
        var totalprec = cases[caseId].reply.precedents.map(
            (p) => p.precedentId
        );
        var totalattach = cases[caseId].reply.attachmentsLinked.map(
            (p) => p.precedentId
        );

        return totalprec.concat(totalattach);
    }, [c.reply.precedents, c.reply.attachmentsLinked]);

    const filteredPrecedents = useMemo(() => {
        let precedents = Object.values(configState.precedents)
            .filter((p) => !p.disabled && user.details.adviceTypes.includes(p.adviceTypeId) && p.isAdvisor==true)
            .sort((s1, s2) => s1.sortIndex - s2.sortIndex);

        if (sectorFiltered)
            precedents = precedents.filter((p) => p.sectorId === sectorId);

        if (caseTypeFiltered)
            precedents = precedents.filter((p) => p.caseTypeId === caseTypeId);

        if (searchText !== "")
            precedents = precedents.filter((p) =>
                p.name?.toLowerCase().includes(searchText.toLowerCase())
            );

        precedents = precedents.filter(
            (p) => !usedPrecedents.includes(p.precedentId)
        );

        let sects = Object.values(configState.sectors);
        if (sectorFiltered)
            sects = sects.filter((p) => p.sectorId === sectorId);

        let cTypes = Object.values(configState.caseTypes);
        if (caseTypeFiltered)
            cTypes = cTypes.filter((p) => p.caseTypeId === caseTypeId);

        const uniqueFolders = (ct, se) =>
            Object.values(configState.precedentFolders).filter(
                (p) =>
                    p.caseTypeId === ct.caseTypeId &&
                    p.sectorId === se.sectorId &&
                    p.disabled === false
            );

        const treeData = sects.map((p) => {
            return {
                sectorId: p.sectorId,
                name: p.name,
                letterCount: precedents.filter(
                    (pp) => pp.sectorId == p.sectorId
                ).length,
                caseTypes: cTypes
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((c) => {
                    return {
                        caseTypeId: c.caseTypeId,
                        name: c.name,
                        letterCount: precedents.filter(
                            (pp) => pp.caseTypeId == c.caseTypeId
                        ).length,
                        folders: uniqueFolders(c, p).map((pf) => {
                            return {
                                precedentFolderId: pf.precedentFolderId,
                                name: pf.name,
                                letterCount: precedents.filter(
                                    (fpr) =>
                                        fpr.sectorId === p.sectorId &&
                                        fpr.caseTypeId === c.caseTypeId &&
                                        fpr.precedentFolderId ===
                                            pf.precedentFolderId
                                ).length,
                                precedents: precedents
                                    .filter(
                                        (fpr) =>
                                            fpr.sectorId === p.sectorId &&
                                            fpr.caseTypeId === c.caseTypeId &&
                                            fpr.precedentFolderId ===
                                                pf.precedentFolderId
                                    )
                                    .sort(
                                        (s1, s2) => s1.sortIndex - s2.sortIndex
                                    )
                                    .map((fpr) => {
                                        return {
                                            precedentId: fpr.precedentId,
                                            name: fpr.name,
                                            azureFileReference:
                                                fpr.azureFileReference,
                                            azureFileContentType:
                                                fpr.azureFileContentType,
                                            azureFileId: fpr.azureFileId
                                        };
                                    }),
                            };
                        }),
                        precedents: precedents
                            .filter(
                                (pr) =>
                                    pr.sectorId === p.sectorId &&
                                    pr.caseTypeId === c.caseTypeId &&
                                    !pr.precedentFolderId
                            )
                            .sort((s1, s2) => s1.sortIndex - s2.sortIndex)
                            .map((pr) => {
                                return {
                                    precedentId: pr.precedentId,
                                    name: pr.name,
                                    precedentFolderId: pr.precedentFolderId,
                                    azureFileReference: pr.azureFileReference,
                                    azureFileContentType:
                                        pr.azureFileContentType,
                                    azureFileId: pr.azureFileId
                                };
                            }),
                    };
                }),
            };
        });

        return Object.values(treeData);
    }, [
        configState.precedents,
        sectorFiltered,
        caseTypeFiltered,
        sectorId,
        caseTypeId,
        searchText,
        usedPrecedents,
    ]);

    const removeSectorFilter = () => {
        setSectorFiltered(false);
    };
    const removeCaseTypeFilter = () => {
        setCaseTypeFiltered(false);
    };

    const handleSectorClick = (sectorIndex) => {
        if (selectedSectorIndex === sectorIndex) {
            setSelectedSectorIndex("");
        } else {
            setSelectedSectorIndex(sectorIndex);
        }
    };

    const handleCaseTypeClick = (caseTypeIndex) => {
        if (selectedCaseTypeIndex === caseTypeIndex) {
            setSelectedCaseTypeIndex("");
        } else {
            setSelectedCaseTypeIndex(caseTypeIndex);
        }
    };

    const handleCaseTypeFolderClick = (caseTypeFolderIndex) => {
        if (selectedCaseTypeFolderIndex === caseTypeFolderIndex) {
            setSelectedCaseTypeFolderIndex("");
        } else {
            setSelectedCaseTypeFolderIndex(caseTypeFolderIndex);
        }
    };

    const handleClosePreview = () => {
        setPrecedentId(null);
    };

    const triggerOpenDocument = async (staticFileToPreview) => {
        console.log(staticFileToPreview);
        var staticFileAzureDetails = {
            reference: staticFileToPreview.azureFileReference,
            filename: staticFileToPreview.name,
            contentType: staticFileToPreview.azureFileContentType,
        };
        var previewRequest = { ...staticFileAzureDetails };
        openDocument(previewRequest);
    };

    const PrecedentItem = ({ p }) => {
        let name = p.name;

        if (isEmailReply) name = "+ " + name;

        return (
            <ListItem button={!!isEmailReply} className={classes.file_nested}>
                <ListItemText
                    title={
                        isEmailReply ? "Add the highlighted item to case" : ""
                    }
                    onClick={() => {
                        isEmailReply ? onSelect(p, true) : null;
                    }}
                    primary={name}
                />
                {p.azureFileReference ? (
                    <IconButton title="Preview file">
                        {" "}
                        <PreviewIcon
                            onClick={() => triggerOpenDocument(p)}
                            style={{ color: "royalblue" }}
                        />
                    </IconButton>
                ) : (
                    <IconButton title="Preview document">
                        <PreviewIcon
                            onClick={() => {
                                setPrecedentId(p.precedentId);
                            }}
                        />
                    </IconButton>
                )}
            </ListItem>
        );
    };

    return (
        <Paper>
            <Box display="flex" flexDirection="column">
                <Typography variant="h6" gutterBottom>
                    Precedent Bank
                </Typography>
                <Box display="flex" flexWrap="wrap">
                    {sectorFiltered && sector && (
                        <Box m={1}>
                            <Chip
                                label={sector.name}
                                onDelete={removeSectorFilter}
                            />
                        </Box>
                    )}
                    {caseTypeFiltered && caseType && (
                        <Box m={1}>
                            <Chip
                                label={caseType.name}
                                onDelete={removeCaseTypeFilter}
                            />
                        </Box>
                    )}
                </Box>
                <TextField
                    style={{ margin: "5px" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    size="small"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <PerfectScrollbar style={{ maxHeight: "452px" }}>
                    {filteredPrecedents
                        .filter((s) => s.letterCount > 0)
                        .map((s, index) => (
                            <List key={index} component="nav">
                                <ListItem
                                    className={classes.root}
                                    key={index}
                                    button
                                    onClick={() => {
                                        handleSectorClick(index);
                                    }}
                                >
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={s.name} />
                                    {index === selectedSectorIndex ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={index === selectedSectorIndex}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {s.caseTypes
                                        .filter((c) => c.letterCount > 0)
                                        .map((c, cindex) => (
                                            <List key={cindex} component="div">
                                                <ListItem
                                                    button
                                                    className={classes.nested}
                                                    onClick={() => {
                                                        handleCaseTypeClick(
                                                            cindex
                                                        );
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <FolderIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={c.name}
                                                    />
                                                    {cindex ===
                                                    selectedCaseTypeIndex ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </ListItem>
                                                <Collapse
                                                    in={
                                                        cindex ===
                                                        selectedCaseTypeIndex
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <List component="div">
                                                        {c.precedents
                                                            .filter(
                                                                (p) =>
                                                                    isEmailReply ||
                                                                    !p.azureFileReference ||
                                                                    p.name.endsWith(
                                                                        ".doc"
                                                                    ) ||
                                                                    p.name.endsWith(
                                                                        ".docx"
                                                                    )
                                                            )
                                                            .map((p) => (
                                                                <PrecedentItem
                                                                    key={
                                                                        p.precedentId
                                                                    }
                                                                    p={p}
                                                                />
                                                            ))}
                                                    </List>
                                                    {c.folders
                                                        .filter(
                                                            (f) =>
                                                                f.letterCount >
                                                                0
                                                        )
                                                        .map((f, findex) => (
                                                            <List
                                                                key={findex}
                                                                component="div"
                                                            >
                                                                <ListItem
                                                                    button
                                                                    className={
                                                                        classes.sub_nested
                                                                    }
                                                                    onClick={() => {
                                                                        handleCaseTypeFolderClick(
                                                                            findex
                                                                        );
                                                                    }}
                                                                >
                                                                    <ListItemIcon>
                                                                        <FolderIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            f.name
                                                                        }
                                                                    />
                                                                    {findex ===
                                                                    selectedCaseTypeFolderIndex ? (
                                                                        <ExpandLess />
                                                                    ) : (
                                                                        <ExpandMore />
                                                                    )}
                                                                </ListItem>
                                                                <Collapse
                                                                    in={
                                                                        findex ===
                                                                        selectedCaseTypeFolderIndex
                                                                    }
                                                                    timeout="auto"
                                                                    unmountOnExit
                                                                >
                                                                    <List component="div">
                                                                        {f.precedents.map(
                                                                            (
                                                                                fp
                                                                            ) => (
                                                                                <ListItem
                                                                                    key={
                                                                                        fp.precedentId
                                                                                    }
                                                                                    className={
                                                                                        classes.sub_file_nested
                                                                                    }
                                                                                >
                                                                                    <ListItemText
                                                                                        pl={
                                                                                            5
                                                                                        }
                                                                                        id={
                                                                                            fp.precedentId
                                                                                        }
                                                                                        title="Add the highlighted item to case"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            onSelect(
                                                                                                fp,
                                                                                                true
                                                                                            );
                                                                                        }}
                                                                                        primary={
                                                                                            fp.name
                                                                                        }
                                                                                    />
                                                                                    {fp.azureFileReference ? (
                                                                                        <IconButton title="Preview file">
                                                                                            {" "}
                                                                                            <PreviewIcon
                                                                                                onClick={() =>
                                                                                                    triggerOpenDocument(
                                                                                                        fp
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    color: "royalblue",
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <IconButton title="Preview document">
                                                                                            <PreviewIcon
                                                                                                onClick={() => {
                                                                                                    setPrecedentId(
                                                                                                        fp.precedentId
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    )}
                                                                                </ListItem>
                                                                            )
                                                                        )}
                                                                    </List>
                                                                </Collapse>
                                                            </List>
                                                        ))}
                                                </Collapse>
                                            </List>
                                        ))}
                                </Collapse>
                            </List>
                        ))}
                </PerfectScrollbar>
            </Box>
            <PrecedentDialog
                open={!!precedentId}
                precedentId={precedentId}
                onClose={handleClosePreview}
                caseId={caseId}
            />
        </Paper>
    );
};

export default PrecedentChooser;
