import {useQuery} from "react-query";
import queryKeys from "../../constants/queryKeys";
import userAutomaticEmailReplyService from "../../services/userAutomaticEmailReplyService";

const useUserAutomaticEmailReply = ({ userId }) =>
    useQuery(
        [queryKeys.userAutomaticEmailReply, userId],
        () => userAutomaticEmailReplyService.fetchForUser(userId),
        {
            enabled: !!userId,
        }
    );

export default useUserAutomaticEmailReply;