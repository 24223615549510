import featureFlagService from "../services/featureFlagService";
import { useQuery } from "react-query";
import queryKeys from "../constants/queryKeys";

const useFeatureFlag = (featureName) =>
    useQuery([queryKeys.featureFlag, featureName], () => featureFlagService.getFeatureFlag(featureName), {
        enabled: !!featureName,
        staleTime: Infinity,
    });

export default useFeatureFlag;
