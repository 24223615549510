import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    CardContent,
    Card as MuiCard,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';

const Card = styled(MuiCard)(spacing);

const MilestoneDocumentsCard = ({
    setMilestoneViewerOpen,
    setDocumentGuid,
    documentGuid,
}) => {
    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const documents = cases[caseId].documents;

    useEffect(() => {
        if (documentGuid) setMilestoneViewerOpen(true);
    }, [documentGuid]);

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant='h6' gutterBottom>
                    Milestone Documents
                </Typography>
                <List component='nav' dense={true}>
                    {documents.map(d => (
                        <ListItem
                            button
                            key={d.documentId}
                            onClick={() => setDocumentGuid(d.identifier)}>
                            <ListItemText primary={d.name} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default MilestoneDocumentsCard;
