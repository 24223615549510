import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Snackbar } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { actionCreators as userActions } from "./redux/reducers/userReducer";
import { actionCreators as configActions } from "./redux/reducers/configReducer";
import maTheme from "./theme";
import Routes from "./routes/Routes";
import axios from "./plugins/axios";
import { removeSnack } from "./redux/actions/snackActions";
import { Alert, AlertTitle } from "@material-ui/lab";
import "./custom.css";
import TokenCheckerDialog from "./components/TokenCheckerDialog";
import LandingPage from "./pages/LandingPage";
import history from "./redux/history";
import { useAuth } from "./contexts/authContext";
import SecurityTimeoutDialog from "./components/dialogs/SecurityTimeoutDialog";
import { register } from "extendable-media-recorder";
import { connect as connectCodec } from "extendable-media-recorder-wav-encoder";
import { userActivityEvents } from "./constants/userActivityEvents";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

function App({
    themeState,
    snackState,
    setServerValues,
    setUserProfile,
    setUsers,
    setUserTimerRunning,
    setConfigInfo,
    removeSnack,
}) {
    const { user, userProfileId } = useAuth();

    useEffect(() => {
        const registerAsync = async () => {
            await register(await connectCodec());
        };
        registerAsync();
    }, []);

    useEffect(() => {
        async function initialiseServerValues() {
            try {
                const response = await axios.get(`${apiUrl}/vars`);
                setServerValues(response.data);

                const redirect = localStorage.getItem("redirect");
                if (redirect != null) {
                    history.push(redirect);
                    localStorage.removeItem("redirect");
                }
            } catch (error) {
                console.error(error);
            }
        }
        initialiseServerValues();
    }, [setServerValues]);

    useEffect(() => {
        async function fetchProfile() {
            try {
                var initialDataResponse = await axios.get(
                    `${apiUrl}/initialData`
                );

                setUsers(initialDataResponse.data.users);
                setConfigInfo(initialDataResponse.data);
            } catch (error) {
                console.error(error);
            }
        }

        if (user && userProfileId) fetchProfile();
    }, [
        setConfigInfo,
        setUserProfile,
        setUserTimerRunning,
        setUsers,
        user,
        userProfileId,
    ]);

    const closeSnack = () => {
        removeSnack();
    };
    
    const { logout, setIgnoreBrowserBehaviour } = useAuth();

    const logoutAfter = 4 * 60 * 60 * 1000;
    const alertAfter = 3 * 75 * 60 * 1000;

    let timers = []
    const isAlertDialogOpen = useRef(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  
    const alertUser = () => {
        setIgnoreBrowserBehaviour(true);
        isAlertDialogOpen.current = true;
        setAlertDialogOpen(true);
    }

    const handleStayLoggedIn = () => {
        isAlertDialogOpen.current = false;
        setAlertDialogOpen(false);
        resetTimer
    }

    const resetTimer = () => {
        if (!user) return;
        if (isAlertDialogOpen.current) return;

        timers.forEach(clearTimeout)
        setIgnoreBrowserBehaviour(false);

        const logoutTimer = setTimeout(logout, logoutAfter)
        const alertTimer = setTimeout(alertUser, alertAfter)

        timers = [logoutTimer, alertTimer]
    }
    
    useEffect(() => {
        resetTimer();  
        Object.values(userActivityEvents).forEach(event => document.addEventListener(event, resetTimer));

        return () => {
            Object.values(userActivityEvents).forEach(event => document.removeEventListener(event, resetTimer));
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet titleTemplate="%s | CaseNest" defaultTitle="CaseNest" />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiThemeProvider theme={maTheme[themeState.currentTheme]}>
                        <ThemeProvider theme={maTheme[themeState.currentTheme]}>
                            <SecurityTimeoutDialog open={alertDialogOpen} handleStayLoggedIn={handleStayLoggedIn} logout={logout} />
                            {user && userProfileId ? (
                                <Routes />
                            ) : (
                                <LandingPage />
                            )}
                            <TokenCheckerDialog />
                            {snackState.snack.message && (
                                <Snackbar
                                    style={{
                                        height: "100%",
                                    }}
                                    open={snackState.open}
                                    autoHideDuration={
                                        snackState.snack.severity === "success"
                                            ? 6000
                                            : null
                                    }
                                    onClose={closeSnack}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <Alert
                                        onClose={closeSnack}
                                        variant={
                                            snackState.snack.severity ===
                                            "error"
                                                ? "filled"
                                                : "standard"
                                        }
                                        severity={snackState.snack.severity}
                                    >
                                        <AlertTitle>
                                            {snackState.snack.severity ===
                                            "error"
                                                ? "Error"
                                                : null}
                                        </AlertTitle>
                                        {snackState.snack.message || "TEST"}
                                    </Alert>
                                </Snackbar>
                            )}
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

const mapStateToProps = (store) => ({
    userState: store.userReducer,
    themeState: store.themeReducer,
    snackState: store.snackReducer,
    configState: store.configReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(configActions, dispatch),
        ...bindActionCreators(userActions, dispatch),
        removeSnack: () => dispatch(removeSnack()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
