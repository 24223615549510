import React from "react";
import { alpha, makeStyles, Typography } from "@material-ui/core";
import { formatTimeDigital } from "../helpers/formatTime";
import {
    getUnitEnding,
    getUnitisedSeconds,
    getUnits,
} from "../helpers/unitTimeHelpers";

const baseTextStyles = {
    fontSize: 15,
    fontWeight: 500,
    wordBreak: "break-all",
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2, 4),
    },
    highlightContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2, 4),
        background: theme.palette.secondary.light,
        borderRadius: 6,
    },
    textBold: {
        ...baseTextStyles,
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    textHighlight: {
        ...baseTextStyles,
        color: theme.palette.common.black,
        marginRight: theme.spacing(1),
    },
    text: {
        ...baseTextStyles,
        color: alpha(theme.palette.primary.main, 0.6),
        marginRight: theme.spacing(1),
    },
    unitsContrainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    unitsText: {
        ...baseTextStyles,
        fontSize: 14,
        marginRight: theme.spacing(1),
    },
}));

const CaseTimeDurationTableItem = ({
    title,
    time,
    isTotal = false,
    isChargeable = false,
    isUnitised = false,
}) => {
    const classes = useStyles();

    const getTextStyles = () => {
        if (isTotal) {
            return classes.textBold;
        } else if (isChargeable) {
            return classes.textHighlight;
        }

        return classes.text;
    };

    const units = isUnitised ? getUnits(time) : null;

    return (
        <div
            className={
                isChargeable ? classes.highlightContainer : classes.container
            }
        >
            <Typography variant="body2" className={getTextStyles()}>
                {title}
            </Typography>
            {!isUnitised && (
                <Typography variant="body2" className={getTextStyles()}>
                    {formatTimeDigital(time)}
                </Typography>
            )}
            {isUnitised && (
                <div className={classes.unitsContrainer}>
                    <Typography variant="body2" className={getTextStyles()}>
                        {formatTimeDigital(time)}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="secondary"
                        className={classes.unitsText}
                    >
                        {`(${units} ${getUnitEnding(units)})`}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default CaseTimeDurationTableItem;
