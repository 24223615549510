import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../plugins/axios";
import "../../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import AICaseHistoryCard from "./AICaseHistoryCard";
import { openFileViewer } from "../../redux/actions/userActions";
import documentService from "../../services/documentService";

import {
    Grid,
    Paper,
    Box,
    TextField,
    Button,
    Card,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    IconButton,
    FormControlLabel,
    Switch,
    makeStyles,
    OutlinedInput,
    Chip,
    Avatar,
    Typography
} from "@material-ui/core";
import {
    Delete as DeleteIcon,
    AttachFile as AttachFileIcon
} from "@material-ui/icons";
import {setSnackAction} from "../../redux/actions/snackActions";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles(theme => ({
    insert: {
        fontSize: '0.8em',
        padding: '4px',
        margin: '8px',
        height: 0,
        cursor: 'pointer',
        background: 'inherit',
        '&:hover': {
            height: 'auto',
            background: 'white'
        }
    },
    attachmentChip: {
        paddingTop: "5px",
        paddingBottom: "5px",
    }
}));

const styles = {
    targetDiv:
    {
        height: "calc(100vh - 220px)",
        marginTop: '5px'
    }
};

const AIThread = ({ thread, back, updateThread, refresh }) => {

    const [messages, setMessages] = useState([]);
    const [saving, setSaving] = useState(false);
    const [localThread, setLocalThread] = useState(thread);
    const [changed, setChanged] = useState(false);
    const [addDocumentAnchorEl, setAddDocumentAnchorEl] = useState();
    const [addDocumentMessage, setAddDocumentMessage] = useState();
    const [documentFilter, setDocumentFilter] = useState("");
    const [caseTypeId, setCaseTypeId] = useState(thread.caseTypeId);

    const classes = useStyles();  
    const dispatch = useDispatch();

    const configState = useSelector((state) => state.configReducer);

    const partnerships = useMemo(() => {
        return Object.values(configState.partnerships)
            .sort((a, b) => a.title > b.title ? 1 : b.title > a.title ? -1 : 0);
    }, [configState.partnerships]);

    const precedents = useMemo(() => {
        return Object.values(configState.precedents)
            .filter(p => p.azureFileId != null);
    }, [configState.precedents]);

    const filteredPrecedents = useMemo(() => {
        let filtered = precedents.filter(p => !p.disabled && p.caseTypeId === caseTypeId && addDocumentMessage && !addDocumentMessage.precedentIds.includes(p.precedentId));

        if (documentFilter != '')
            filtered = filtered.filter(p => p.name.toLowerCase().includes(documentFilter.toLowerCase()));

        return filtered;
    }, [precedents, documentFilter, addDocumentMessage, caseTypeId]);

    const fetchThread = async () => {

        var response = await axios.get(`${apiUrl}/getthreadmessages/${thread.threadId}`);
        setMessages(response.data);
    }

    const updateLocalThread = (field, value) => {

        setLocalThread({
            ...localThread,
            [field]: value
        })

        setChanged(true);
    }

    const updateMessage = (index, field, value) => {

        setMessages(messages.map((m, i) => index === i ? {
            ...m,
            [field]: value
        } : m));

        setChanged(true);
    };

    useEffect(() => {
        if (thread.threadId)
            fetchThread();
    }, [thread]);

    const addMessage = (index) => {

        let array = [...messages];
        array.splice(index, 0, {
            tag: '',
            message: '',
            messageId: messages.length ? (Math.max(...messages.map(m => m.messageId)) + 1) : 1,
            precedentIds: []
        });

        setMessages(array);

        setChanged(true);
    }

    const removeMessage = index => {
        let array = [...messages];
        array.splice(index, 1);
        setMessages(array);

        setChanged(true);
    };

    const save = async () => {
        setSaving(true);

        await axios.post(`${apiUrl}/saveaithread`, { ...localThread, messages });
        updateThread(localThread);

        setSaving(false);
        setChanged(false);

        if (!thread.threadId) {
            refresh();
            back();
        }
    };

    const deleteThread = async () => {
        setSaving(true);

        await axios.delete(`${apiUrl}/thread/${thread.threadId}`);

        refresh();
        back();
    }

    const tags = ["Client", "Adviser"];

    const valid = useMemo(() => {
        return messages.every(m => m.tag != '')
    }, [messages]);

    const insertBar = (index) => (
        <Card className={classes.insert}>
            <Box display="flex" justifyContent="center" onClick={() => addMessage(index)}>--- insert here ---</Box>
        </Card>
    );

    const openDocument = async (attachment) => {
        console.log('opendoc', attachment);
    };

    const openMenu = (target, message) => {
        setAddDocumentAnchorEl(target);
        setAddDocumentMessage(message);
    }

    const addDocument = (precedentId) => {
        setMessages(messages.map(m => m.messageId == addDocumentMessage.messageId ? { ...m, precedentIds: [...m.precedentIds || [], precedentId] } : m));
        closeDocumentMenu();
    }

    const closeDocumentMenu = () => {
        setAddDocumentAnchorEl(null);
        setAddDocumentMessage(null);
        setDocumentFilter("");
    }

    const removePrecedent = (messageId, precedentId) => {
        setMessages(messages.map(m => m.messageId == messageId
            ? {
                ...m,
                precedentIds: m.precedentIds.filter(id => id !== precedentId)
            }
            : m
        ));
    }

    const handlePrecedentClick = async precedentId => {

        const precedent = precedents.find(p => p.precedentId == precedentId);

        let attachment = {
            reference: precedent.azureFileReference,
            filename: precedent.name,
            contentType: precedent.azureFileContentType,
        };

        console.log(precedentId, attachment);

        const response = await documentService.openDocument(attachment.reference);

        if (response === null) {
            dispatch(setSnackAction("File is password protected and cannot be previewed. Download from the Case Files tab to view locally.", "warning"));
            return;
        }
        
        let blob = new Blob([response], { type: attachment.contentType });
        let parsedFileName = attachment.filename.split(".");
        dispatch(openFileViewer(
            {
                type: parsedFileName[parsedFileName.length - 1],
                path: window.URL.createObjectURL(blob),
                name: attachment.filename,
            },
            null,
            attachment
        ));
    };

    const FileChip = ({ messageId, precedentId }) => {

        const precedent = precedents.find(p => p.precedentId == precedentId);

        return (
            <Box m={1}>
                <Chip
                    className={classes.attachmentChip}
                    avatar={<Avatar><AttachFileIcon /></Avatar>}
                    label={<Typography variant="body2">{precedent.name}</Typography>}
                    onClick={() => handlePrecedentClick(precedentId)}
                    onDelete={() => removePrecedent(messageId, precedentId)}
                />
            </Box>
        );
    };

    return (
        <React.Fragment >
            <Box display="flex" gridGap='5px'>
                <Button variant="contained" onClick={back} disabled={saving}>{changed ? "Discard" : "Back"}</Button>
                <Button variant="contained" onClick={save} disabled={saving || !valid}>{saving ? "Saving ..." : "Save"}</Button>
                <Button variant="contained" onClick={deleteThread} disabled={saving}>Delete</Button>
                <Box flexGrow={1}></Box>
                <FormControl flexGrow={1}>
                    <InputLabel shrink>Case Type</InputLabel>
                    <Select
                        displayEmpty
                        value={caseTypeId}
                        style={{ width: "200px" }}
                        onChange={e => setCaseTypeId(e.target.value)}
                    >
                        <MenuItem value="">Any</MenuItem>
                        {
                            Object.values(configState.caseTypes)
                                .filter((x) => x.isActive)
                                .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                                .map((ct) => <MenuItem key={ct.caseTypeId} value={ct.caseTypeId}>{ct.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>          
            </Box>
            <Grid container spacing={3} style={styles.targetDiv}>
                <Grid item xs={4} style={{ height: "100%" }}>
                    <Box display="flex" style={{ height: "100%" }} flexDirection="column">
                        <Paper>
                            <Box display="flex" flexDirection="column" p={3}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={localThread.approved}
                                                onChange={e => updateLocalThread("approved", e.target.checked)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Approved"
                                    />
                                    {thread.caseId ? `Case ${thread.caseId} (${thread.caseType})` : "No case"}
                                </Box>
                                <FormControl variant="outlined" margin="dense">
                                    <InputLabel shrink>Partner</InputLabel>
                                    <Select
                                        value={localThread.partner || ''}
                                        fullWidth
                                        input={<OutlinedInput notched label="Partner" />}
                                        onChange={e => updateLocalThread('partner', e.target.value)}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {
                                            partnerships.map((p) => <MenuItem key={p.title} value={p.title}>{p.title}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Description"
                                    margin="dense"
                                    variant="outlined"
                                    disabled={saving}
                                    fullWidth
                                    multiline
                                    value={localThread.description}
                                    onChange={e => updateLocalThread("description", e.target.value)}
                                />
                                <TextField
                                    label="Subject"
                                    margin="dense"
                                    disabled={saving}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={localThread.subject}
                                    onChange={e => updateLocalThread("subject", e.target.value)}
                                />
                            </Box>
                        </Paper>
                        {
                            thread.caseId &&
                            <Box my={3}>
                                <AICaseHistoryCard caseId={thread.caseId} openDocument={openDocument} />
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid xs={8} item style={{ height: "100%" }}>
                    <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
                        <Box flexGrow={1} style={{ height: 0 }}>
                            <PerfectScrollbar>
                                {insertBar(0)}
                                {
                                    messages.map((m, i) => (
                                        <React.Fragment key={m.messageId}>
                                            <Card style={{ padding: '8px', margin: '8px' }}>
                                                <Box display="flex" alignItems="center" gridGap="5px">
                                                    <FormControl disabled={saving} variant="outlined" margin="dense" >
                                                        <InputLabel>Tag</InputLabel>
                                                        <Select
                                                            displayEmpty
                                                            value={m.tag}
                                                            label="Tag"
                                                            style={{ width: "150px" }}
                                                            onChange={e => updateMessage(i, "tag", e.target.value)}
                                                        >
                                                            {tags.map(tag => (<MenuItem key={tag} value={tag}>{tag}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                    <Button
                                                        style={{ width: "150px" }}
                                                        onClick={e => openMenu(e.currentTarget, m)}
                                                        variant="contained"
                                                    >Add Document</Button>
                                                    <Box display="flex" flexGrow={1} justifyContent="right">
                                                        <IconButton onClick={() => removeMessage(i)}><DeleteIcon /></IconButton>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" flexWrap="wrap">
                                                    {
                                                        m.precedentIds.map(precedentId => (
                                                            <FileChip
                                                                key={precedentId}
                                                                precedentId={precedentId}
                                                                messageId={m.messageId} />
                                                        ))
                                                    }
                                                </Box>
                                                <TextField
                                                    label="Message"
                                                    margin="dense"
                                                    variant="outlined"
                                                    disabled={saving}
                                                    fullWidth
                                                    multiline
                                                    onChange={e => updateMessage(i, "message", e.target.value)}
                                                    value={m.message}
                                                />
                                            </Card>
                                            {insertBar(i + 1)}
                                        </React.Fragment>
                                    ))
                                }
                                <Menu
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                                    anchorEl={addDocumentAnchorEl}
                                    open={!!addDocumentAnchorEl}
                                    onClose={closeDocumentMenu}
                                >
                                    <MenuItem onKeyDown={e => e.stopPropagation()}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            placeholder="filter"
                                            value={documentFilter}
                                            onChange={e => setDocumentFilter(e.target.value)}
                                        />
                                    </MenuItem>
                                    {
                                        filteredPrecedents.map(p => <MenuItem key={p.precedentId} onClick={() => addDocument(p.precedentId)}>{p.name}</MenuItem>)
                                    }
                                </Menu>
                            </PerfectScrollbar>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AIThread
