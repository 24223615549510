import React from "react";
import ComposeReply from "./ComposeReply";
import PrecedentDocument from "./PrecedentDocument";
import TabPanel from "./TabPanel";
import { useDispatch, useSelector } from "react-redux";
import {
    setBackdropOpen,
    setCaseResearchOpen,
} from "../redux/actions/caseActions";
import { setSnackAction } from "../redux/actions/snackActions";
import { startCaseResearch } from "../services/caseResearchService";

const replyTabIndex = 5;
const precedentTabIndexOffset = 6;

function CaseReplyAndPrecedentTabs({ currentTab, onCloseReply, onAddPrecedent }) {
    const { cases, currentCaseId } = useSelector((state) => state.caseReducer);
    const { inCall } = useSelector((state) => state.flexReducer);
    const dispatch = useDispatch();

    const currentCase = cases[currentCaseId] || {};
    const { reply } = currentCase || {};
    const { precedents } = reply || {};
    

    const handleOpenCaseResearch = async () => {
        if (inCall) {
            dispatch(setSnackAction("Cannot enter research mode whilst on a call", "error"));
            return;
        }

        dispatch(setBackdropOpen(true));
        try {
            await startCaseResearch();
            dispatch(setCaseResearchOpen(true));
        } catch (error) {
            console.error(error);
        }
        dispatch(setBackdropOpen(false));
    };

    return (
        <>
            <TabPanel value={currentTab} index={replyTabIndex}>
                <ComposeReply
                    onClose={onCloseReply}
                    onAddPrecedent={onAddPrecedent}
                    handleOpenCaseResearch={handleOpenCaseResearch}
                />
            </TabPanel>
            {precedents.map((p, index) => (
                <TabPanel value={currentTab} index={index + precedentTabIndexOffset} key={p.precedentId}>
                    <PrecedentDocument precedent={p} handleOpenCaseResearch={handleOpenCaseResearch} />
                </TabPanel>
            ))}
        </>
    );
}

export default CaseReplyAndPrecedentTabs;
