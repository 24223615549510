import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import useCaseDraftEmailsSummary from "../hooks/queries/useCaseDraftEmailsSummary";
import {useDispatch, useSelector} from "react-redux";
import {
    setCaseAttachmentsSelected,
    setCaseViewStateAction,
    setDraftAttachmentsSelected,
    setInboundEmailContactsAction,
    setInboundEmailNonContactsAction,
    setReplyAttachmentsAction,
    setReplyContactsAction,
    setReplyDescription,
    setReplyEditorHtmlAction,
    setReplyIntCcsAction,
    setReplyNonContactsAction,
    setReplyPrecedentsAction,
    setReplySubjectAction,
    setReplyingToAction, updateCaseDraftEmail, clearCaseDraftEmail,
} from "../redux/actions/caseActions";
import caseTabs from "../constants/caseTabs";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import emailDraftService from "../services/emailDraftService";
import {setSnackAction} from "../redux/actions/snackActions";
import {saveDraft} from "../redux/actions/thunks";

const replyTabIndex = 5;
const precedentTabIndexOffset = 6;
const oneMinuteInterval = 60000;

const EmailDraftContext = createContext();

function EmailDraftProvider({ children }) {
    const [draftEmailSummaryDialogOpen, setDraftEmailSummaryDialogOpen] = useState(false);
    const [lastModifiedDate, setLastModifiedDate] = useState(null);
    const [lastModifiedBy, setLastModifiedBy] = useState(null);
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const { cases, currentCaseId: caseId } = useSelector(state => state.caseReducer);
    const c = cases[caseId];
    const draftEmail = c?.draftEmail;
    const { precedents } = c?.reply || {};
    const currentTab = c?.viewState?.currentTab;

    const draftEmailsSummary = useCaseDraftEmailsSummary(caseId);

    function reset() {
        dispatch(setReplyEditorHtmlAction(caseId, ""));
        dispatch(setReplyContactsAction(caseId, []));
        dispatch(setReplyNonContactsAction(caseId, []));
        dispatch(setReplyIntCcsAction(caseId, []));
        dispatch(setInboundEmailNonContactsAction(caseId, []));
        dispatch(setInboundEmailContactsAction(caseId, []));
        dispatch(setReplySubjectAction(""));
        dispatch(setReplyDescription(""));
        dispatch(setReplyPrecedentsAction([]));
        dispatch(setReplyAttachmentsAction(caseId, []));
        dispatch(setCaseAttachmentsSelected([], caseId));
        dispatch(setDraftAttachmentsSelected([], caseId));
        dispatch(setReplyingToAction(false));
        dispatch(clearCaseDraftEmail({ caseId }));
        setLastModifiedDate(null);
        setLastModifiedBy(null);
    }
    
    const refetchDraftEmails = useCallback(() => {
        reactQueryClient.invalidateQueries([queryKeys.draftEmailsSummary, caseId]);
    }, [caseId]);
    
    useEffect(() => {
        refetchDraftEmails();
    }, [draftEmail?.id, refetchDraftEmails]);
    
    async function fetchDraftEmail(id) {
        try {
            const response = await emailDraftService.getById(id);
            const {
                caseId,
                replyContactsJson,
                replyContactsNotKnownJson,
                ccUserIdsJson,
                ccNonContactsJson,
                ccContactsJson,
                subject,
                content,
                description,
                precedents = [],
                attachments = [],
                draftAttachmentsSelected = [],
                lastModified,
                lastModifiedByName,
            } = response.data;
            dispatch(updateCaseDraftEmail(response.data));
            dispatch(setReplyEditorHtmlAction(caseId, content));

            if (replyContactsJson)
                dispatch(setReplyContactsAction(caseId, JSON.parse(replyContactsJson)));

            if (replyContactsNotKnownJson)
                dispatch(setReplyNonContactsAction(caseId, JSON.parse(replyContactsNotKnownJson)));

            if (ccUserIdsJson)
                dispatch(setReplyIntCcsAction(caseId, JSON.parse(ccUserIdsJson)));

            if (ccNonContactsJson)
                dispatch(setInboundEmailNonContactsAction(caseId, JSON.parse(ccNonContactsJson)));

            if (ccContactsJson)
                dispatch(setInboundEmailContactsAction(caseId, JSON.parse(ccContactsJson)));

            if (subject)
                dispatch(setReplySubjectAction(subject));

            if (description)
                dispatch(setReplyDescription(description));

            dispatch(setReplyPrecedentsAction(precedents));

            dispatch(setReplyAttachmentsAction(
                caseId,
                attachments
                    .filter(x => !x.isCaseFile)
                    .map(x => (
                        {
                            ...x,
                            id: x.reference,
                            file: {
                                name: x.name,
                                size: x.size,
                                type: x.type
                            }
                        }
                    ))
            ));

            dispatch(setCaseAttachmentsSelected(attachments
                .filter(x => x.isCaseFile)
                .map(x => (
                    {
                        ...x,
                        id: x.reference,
                        file: {
                            name: x.name,
                            size: x.size,
                            type: x.type
                        }
                    }
                )), caseId));

            dispatch(setDraftAttachmentsSelected(draftAttachmentsSelected, caseId));

            setLastModifiedDate(lastModified);
            setLastModifiedBy(lastModifiedByName);

            setDraftEmailSummaryDialogOpen(false);
            dispatch(setReplyingToAction(null));
            goToReplyTab();
        }
        catch (e) {
            console.error(e);
            dispatch(setSnackAction("There was an error fetching the draft email"));
        }
    }

    function goToReplyTab() {
        dispatch(
            setCaseViewStateAction(caseId, {
                ...c.viewState,
                mode: caseTabs.replying,
                currentTab: 5,
            })
        );
    }

    const isReplyOrPrecedentTab = useMemo(() => {
        const precedentTabIndexes = precedents?.map((_, index) => index + precedentTabIndexOffset);
        return currentTab === replyTabIndex || precedentTabIndexes?.includes(currentTab);
    }, [currentTab, precedents]);
    
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!caseId || !isReplyOrPrecedentTab)
                return;
            
            const editorData = editorRef.current?.getData()
            
            if (!editorData)
                return;
            
            dispatch(saveDraft({ editorHtml: editorData, setLastModifiedBy, setLastModifiedDate, isAutoSave: true, caseId }));
        }, oneMinuteInterval);
        
        return () => clearInterval(intervalId);
    }, [caseId, isReplyOrPrecedentTab]);
    

    const value = {
        draftEmailSummaryDialogOpen,
        setDraftEmailSummaryDialogOpen,
        draftEmailsSummary,
        reset,
        lastModifiedDate,
        setLastModifiedDate,
        lastModifiedBy,
        setLastModifiedBy,
        editorRef,
        fetchDraftEmail,
        refetchDraftEmails,
    };

    return <EmailDraftContext.Provider value={value}>{children}</EmailDraftContext.Provider>;
}

const useEmailDraft = () => useContext(EmailDraftContext);

export { EmailDraftProvider, useEmailDraft };
