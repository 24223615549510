import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setSnackAction } from "../../../redux/actions/snackActions";
import { caseFileTypes } from "../../../constants/caseFiles";
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    diaTitle: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
    },
    folderNameField: {
        width: "95%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
}));

export default function FolderNameDialog({
    treeData,
    setTreeToSave,
    folderDialogOpen,
    setFolderDialogOpen,
    folderName,
    setFolderName,
    validateFileFolder,
    folderId,
    setFolderId,
    newParent,
    untitledBaseName,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const folderNameMessage = (newFolderName, originalTitle =null) => {
        if (folderId === null) {
            dispatch(setSnackAction('Folder created', "success"));
        } else {
            dispatch(setSnackAction(`'${originalTitle}' renamed as '${newFolderName}'`, "success"));
        }
    }

    const updateFolderNameRecursively = useCallback((node, newFolderName) => {
        if (node.folderId === folderId) {
            folderNameMessage(newFolderName, node.title);
            return {
                ...node,
                title: newFolderName,
            };
        } else if (newParent && node.folderId === newParent.folderId) {
            const updatedChildren = node.children.map((child) => {
                if (child.folderId === folderId) {
                    // Update the existing child folder title
                    folderNameMessage(newFolderName, child.title);
                    return {
                        ...child,
                        title: newFolderName,
                    };
                }
                return child;
            });
            if (folderId === null) {
                // Create a new child folder
                folderNameMessage(newFolderName);
                updatedChildren.push({
                    title: newFolderName,
                    docType: caseFileTypes.FOLDER,
                    children: []
                });
            }
            return {
                ...node,
                children: updatedChildren,
            };
        } else if (node.children) {
            return {
                ...node,
                children: node.children.map((child) =>
                    updateFolderNameRecursively(child, newFolderName)
                ),
            };
        }
        return node;
    }, [folderId, newParent]);

    const handleFolderSave = useCallback((e) => {
        e.preventDefault();

        let newFolderName = validateFileFolder(folderName, caseFileTypes.FOLDER)
        if (!newFolderName) return;

        let updatedTreeData = null;
        if (newParent || folderId) {
            //renaming folders on root level & folder inside folder
            updatedTreeData = treeData.map((node) => {
                return updateFolderNameRecursively(node, newFolderName)
            });
            //setTreeToSave([...updatedTreeData]);
            setTreeToSave(updatedTreeData);
        } else {
            //new folder on root level
            updatedTreeData = treeData.concat({
                title: newFolderName,
                docType: caseFileTypes.FOLDER,
            });
            //setTreeToSave([...updatedTreeData]);
            
            folderNameMessage(newFolderName);
        }
        setTreeToSave(updatedTreeData);
        console.log('new folder updatedTreeData', updatedTreeData);
        setFolderName("");
        setFolderId(null);
        setFolderDialogOpen(false);
    }, [folderId, folderName, newParent, treeData, updateFolderNameRecursively, validateFileFolder]);

    const handleFolderDialogClose = useCallback(() => {
        setFolderName("");
        setFolderId(null);
        setFolderDialogOpen(false);
    }, []);

    const handleFocus = (event) => event.target.select();

    return (
        <Dialog
            open={folderDialogOpen}
            onClose={handleFolderDialogClose}
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        component="div"
                        className={classes.diaTitle}
                    >
                        {folderId > 0
                            ? "Edit Folder Name"
                            : "Create New Case File Folder"}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    defaultValue={folderName ? folderName : untitledBaseName}
                    autoFocus
                    onFocus={handleFocus}
                    onChange={(e) => setFolderName(e.target.value)}
                    className={classes.folderNameField}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => handleFolderDialogClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleFolderSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}