import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import axios from "../plugins/axios";

import { blue, orange, green, red } from "@material-ui/core/colors";

import {
    Card as MuiCard,
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Doughnut } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 168px;
    position: relative;
`;

const DoughnutInner = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -22px;
    text-align: center;
    z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
    height: 42px;
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const GreenText = styled.span`
    color: ${() => green[400]};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
    color: ${() => red[400]};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const UtilisationCard = ({ adviser, theme }) => {
    const [dbData, setDbData] = useState(null);
    const [dbTodaysMinutesData, setDbTodaysMinutesData] = useState(0);

    //get the data from the UserController/Service
    const updateAdvisorUtilStats = async () => {
        if (adviser.userId) {
            var response = await axios.get(
                `${apiUrl}/getuserstats/${adviser.userId}`
            );
            setDbData(response.data.todaysChart);
            setDbTodaysMinutesData(response.data.todaysMinutes);
        }
    };

    useEffect(() => {
        //update case count when page is hit
        updateAdvisorUtilStats();
    }, []);

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        cutoutPercentage: 80,
    };

    return (
        <Card mb={3}>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVertical />
                    </IconButton>
                }
                title="Utilisation"
            />

            <CardContent>
                {dbData != null && (
                    <ChartWrapper>
                        <DoughnutInner variant="h4">
                            <Typography variant="h4">
                                {dbTodaysMinutesData}
                            </Typography>
                            <Typography variant="caption">
                                Productive Minutes
                            </Typography>
                        </DoughnutInner>
                        <Doughnut data={dbData} options={options} />
                    </ChartWrapper>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">Minutes</TableCell>
                        </TableRow>
                    </TableHead>

                    {dbData != null && (
                        <TableBody>
                            {dbData.records.map(
                                (c) =>
                                    c.value > 0 && (
                                        <TableRow key={c.label}>
                                            <TableCell>{c.label}</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">
                                                {c.value}
                                            </TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    )}
                </Table>
            </CardContent>
        </Card>
    );
};

export default withTheme(UtilisationCard);
