import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import axios from "../../plugins/axios";
import { setSnackAction } from "../../redux/actions/snackActions";
import {
    Card as MuiCard,
    CardContent,
    Checkbox,
    Typography as MuiTypography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Grid,
    FormControlLabel,
    Switch,
    Box,
    TablePagination,
    TableFooter,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Avatar,
    Button as MuiIconButton, makeStyles,
} from "@material-ui/core";
import styled from "styled-components";
import avatarStyles from "../../theme/avatars";
import { spacing } from "@material-ui/system";
import {
    Work as WorkIcon,
    Edit as EditIcon,
    Notifications as NotificationsIcon,
    DeleteForever as DeleteIcon,
    InfoOutlined as InfoIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Controls } from "../../helpers/Controls";
import CreateContact from "../../pages/CreateContact";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/accountReducer";
import {
    setCreateContactEmail,
    setCreateContactPhone,
} from "../../redux/actions/createContactActions";
import {
    setCaseContacts,
    setReplyContactsAction,
} from "../../redux/actions/caseActions";
import {
    setCallbackRequestData,
    setCallbackRequestOpen,
} from "../../redux/actions/accountActions";
import ContactPermissionSummary from "../../components/ContactPermissionSummary";
import contactService from "../../services/contactService";
import useFilteredAdvisers from "../../hooks/useFilteredAdvisers";
import salesforceRoles from "../../constants/salesforceRoles";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    detailsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    phoneNumbers: {
        marginBottom: theme.spacing(1),
    },
    receiveAdvice: {
        color: theme.palette.secondary.main,
    }
}));

const IconButton = styled(MuiIconButton)`
    padding: 0px;
    margin-top: 2px;
    margin-left: 0px;
    min-width: 0;
`;

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AccountContactsCard = ({
    setSnack,
    account,
    getAccount,
    caseState,
    setCaseContacts,
    setReplyContacts,
    setCreateContactEmail,
    setCreateContactPhone,
}) => {
    const classes = useStyles();
    const avatarClasses = avatarStyles();
    const [requesting, setRequesting] = useState(false);

    const [openPopup, setOpenPopup] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const [popupTitleText, setPopupTitleText] = useState("Add New Contact");

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState(null);

    const [dupeResults, setDupeResults] = useState([]);
    const [checkForDupes, setCheckForDupes] = useState(true);

    // for callback request modal
    const [userId, setUserId] = useState("");
    const [important, setImportant] = useState(false);
    const [note, setNote] = useState("");
    const { callbackRequest } = useSelector((state) => state.accountReducer);
    const { data: callbackData, isOpen } = callbackRequest;

    const [deleteOnAllAccounts, setDeleteOnAllAccounts] = React.useState(false);
    const [openDeleteContactDialog, setDeleteContactDialog] = useState(false);

    const [recordForDelete, setRecordForDelete] = useState(null);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const filteredAdvisers = useFilteredAdvisers();
    const openInPopup = (contact) => {
        if (contact) {
            const cont = {};
            cont.id = contact.contactId;
            cont.firstName = contact.firstName;
            cont.lastName = contact.lastName;
            cont.position = contact.position;
            cont.phone = contact.phone;
            cont.mobile = contact.mobile;
            cont.email = contact.email;
            cont.accountId = contact.accountId;
            cont.email2 = contact.email2;
            cont.emailOptOut = contact.emailOptOut;
            cont.externalId = contact.externalId;

            setRecordForEdit({ ...cont });
            setPopupTitleText("Edit Existing Contact");
        } else {
            setRecordForEdit();
            setPopupTitleText("Add New Contact");
            setCheckForDupes(true);
            setDupeResults(null);
        }

        setOpenPopup(true);
    };

    const createLink = async (contactId) => {
        await axios.post(`${apiUrl}/createaccountcontactlink`, {
            contactId: contactId,
            accountId: account.accountId,
        });

        window.frames[0].postMessage({ event: "refresh" }, "*");
        setCheckForDupes(true);
        setDupeResults(null);
        setOpenPopup(false);
        getAccount(account.accountId, history);
    };

    const requestCallback = async () => {
        setRequesting(true);

        await axios.post(`${apiUrl}/requestcallback`, {
            ...callbackData,
            important,
            accountId: account.accountId,
            partner: account.partnershipName,
            userIds: account.elTeam.map((u) => u.userId),
            adviserId: userId,
            accountName: account.name,
            note,
        });
        setRequesting(false);
        handleCloseRequestCallback();
        setImportant(false);
        setUserId(null);
        setSnack("Callback created", "success");
    };

    const updateCaseContacts = (contact) => {
        for (const [caseId, c] of Object.entries(caseState.cases)) {
            if (c.account.accountId === contact.accountId) {
                let existingCaseContact = c.contacts.find(
                    (cc) => cc.externalId === contact.externalId
                );
                if (existingCaseContact) {
                    setCaseContacts(
                        caseId,
                        c.contacts.map((cc) =>
                            cc.externalId === contact.externalId
                                ? {
                                      contactId: contact.contactId,
                                      accountId: contact.accountId,
                                      email: contact.email || "",
                                      email2: contact.email2 || "",
                                      name:
                                          (contact.firstName || "") +
                                          " " +
                                          (contact.lastName || ""),
                                      firstName: contact.firstName || "",
                                      lastName: contact.lastName || "",
                                      emailOptOut: contact.emailOptOut || false,
                                      isPrimary: existingCaseContact?.isPrimary
                                          ? true
                                          : false,
                                      externalId: contact.externalId,
                                      disabled: existingCaseContact?.disabled
                                          ? true
                                          : false,
                                  }
                                : cc
                        )
                    );
                }

                setReplyContacts(
                    caseId,
                    c.reply.contacts.map((rc) =>
                        rc.contactId === contact.contactId
                            ? {
                                  ...contact,
                                  name:
                                      (contact.firstName || "") +
                                      " " +
                                      (contact.lastName || ""),
                              }
                            : rc
                    )
                );
            }
        }
    };

    const addOrEdit = async (contact, resetForm, checkForDupes) => {
        contact.accountId = account.accountId;
        contact.accountExternalId = account.externalId;

        if (contact.id === 0) {
            if (checkForDupes) {
                //for new records we first check that the record is not a potential dupe
                let response = await axios.post(`${apiUrl}/getdupecontacts`, {
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.email,
                    phone: contact.phone,
                });
                if (response.data.count === 0) {
                    try {
                        let response =
                            await contactService.createAccountContact({
                                contact,
                            });
                        contact.externalId = response.data;

                        getAccount(account.accountId, history);
                        window.frames[0].postMessage({ event: "refresh" }, "*");
                        setOpenPopup(false);

                        setCreateContactPhone("");
                        setCreateContactEmail("");

                        updateCaseContacts(contact);
                    } catch (e) {
                        setSnackAction(
                            "There was an error adding the contact",
                            "error"
                        );
                        console.error(e);
                    }
                } else {
                    setCheckForDupes(false);
                    setDupeResults(response.data.contacts);                   
                }
            } else {
                try {
                    let response = await contactService.createAccountContact({
                        contact,
                    });
                    contact.externalId = response.data;
                    getAccount(account.accountId, history);
                    window.frames[0].postMessage({ event: "refresh" }, "*");
                    setOpenPopup(false);

                    setCreateContactPhone("");
                    setCreateContactEmail("");

                    updateCaseContacts(contact);
                } catch (e) {
                    setSnackAction(
                        "There was an error adding the contact",
                        "error"
                    );
                    console.error(e);
                }
            }
        } else {
            contact.contactId = contact.id;
            await axios.post(`${apiUrl}/editexistingaccountcontact`, contact);
            getAccount(account.accountId, history);
            window.frames[0].postMessage({ event: "refresh" }, "*");
            setOpenPopup(false);

            setCreateContactPhone("");
            setCreateContactEmail("");

            updateCaseContacts(contact);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const filteredContacts = () => {
        let contacts = account.contacts;

        if (name.length) {
            contacts = contacts.filter(
                (c) =>
                    c.name && c.name.toLowerCase().includes(name.toLowerCase())
            );
        }

        if (position.length) {
            contacts = contacts.filter(
                (c) =>
                    c.position &&
                    c.position.toLowerCase().includes(position.toLowerCase())
            );
        }

        if (email.length) {
            contacts = contacts.filter(
                (c) =>
                    c.email &&
                    c.email.toLowerCase().includes(email.toLowerCase())
            );
        }

        if (phone) {
            // strip whitespace
            let newPhone = phone.replace(/\s+/g, "");

            // replace leading '0' with '+44'
            let newPhonePlus = newPhone;
            if (newPhone.startsWith("0"))
                newPhonePlus = "+44" + newPhone.substring(1);

            // check against phone and mobile fields
            contacts = contacts.filter(
                (c) =>
                    (c.phone &&
                        (c.phone.includes(newPhone) ||
                            c.phone.includes(newPhonePlus))) ||
                    (c.mobile &&
                        (c.mobile.includes(newPhone) ||
                            c.mobile.includes(newPhonePlus)))
            );
        }

        return contacts;
    };

    const openDeleteContactPopup = (contactId) => {
        setRecordForDelete(contactId);
        setDeleteOnAllAccounts(false);
        setDeleteContactDialog(true);
    };

    const handleDeleteAccountContact = async (delContactId) => {
        setRequesting(true);
        //update the contact enabled flag
        await axios.post(`${apiUrl}/deleteAccountContact/${delContactId}/${account.accountId}/${deleteOnAllAccounts}`);

        setDeleteContactDialog(false);
        setRequesting(false);
        getAccount(account.accountId, history);
    };

    const handleCloseDeleteContactDialog = () => {
        setDeleteContactDialog(false);
    };

    const handleAllAccountsChange = (event) => {
        setDeleteOnAllAccounts(event.target.checked);
    };

    const handleClickRequestCallback = (c) => {
        dispatch(
            setCallbackRequestData({
                contactId: c.contactId,
                name: c.name,
                phone: c.mobile || c.phone,
            })
        );
        dispatch(setCallbackRequestOpen(true));
    };

    const handleCloseRequestCallback = () => {
        dispatch(
            setCallbackRequestData({ contactId: null, name: "", phone: "" })
        );
        dispatch(setCallbackRequestOpen(false));
    };

    return (
        <React.Fragment>
            <Card mb={3} style={{ height: "100%" }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom display="inline">
                        Contacts
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Name</InputLabel>
                            <Input
                                type="text"
                                value={name || ""}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Position</InputLabel>
                            <Input
                                type="text"
                                value={position || ""}
                                onChange={(e) => setPosition(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Email</InputLabel>
                            <Input
                                type="text"
                                value={email || ""}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl style={{ marginRight: "16px" }}>
                            <InputLabel>Phone</InputLabel>
                            <Input
                                type="tel"
                                value={phone || ""}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <Controls.ButtonControl
                                variant="outlined"
                                text="Add Contact"
                                size="small"
                                color="primary"
                                onClick={() => openInPopup()}
                            />
                        </FormControl>
                    </Box>
                    {account.contacts.length ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Position
                                    </TableCell>
                                    <TableCell>
                                        Contact Details
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Number Of Cases">
                                            <WorkIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Contact's Permissions">
                                            <InfoIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Request Callback">
                                            <NotificationsIcon />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        Edit
                                    </TableCell>
                                    <TableCell>
                                        Delete
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredContacts()
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((c) => (
                                        <React.Fragment key={c.contactId}>
                                            <TableRow>
                                                <TableCell>
                                                    <div className={classes.detailsContainer}>
                                                        <Typography>{c.name || "-"}</Typography>
                                                        {c.clientRoles?.[account.accountId]?.includes(salesforceRoles.CASENEST_RECEIVE_ADVICE) ?
                                                            <Typography variant="subtitle2" className={classes.receiveAdvice}>Can Receive Advice</Typography> :
                                                            null
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {c.position || "-"}
                                                </TableCell>
                                                <TableCell>
                                                    <div className={classes.detailsContainer}>
                                                        {(!c.phone?.trim() && !c.mobile?.trim() && !c.email?.trim()) ?
                                                            "-" :
                                                            <>
                                                                <Typography className={classes.phoneNumbers}>
                                                                    {c.phone && c.mobile
                                                                        ? c.phone +
                                                                        " / " +
                                                                        c.mobile
                                                                        : c.phone ||
                                                                        c.mobile}
                                                                </Typography>
                                                                <Typography>{c.email}</Typography>
                                                            </>
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {c.numberOfCases}
                                                </TableCell>
                                                <TableCell>
                                                    <ContactPermissionSummary
                                                        permissions={
                                                            c?.clientRoles[
                                                                account
                                                                    .accountId
                                                            ]
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Request Callback">
                                                        <IconButton onClick={() => handleClickRequestCallback(c)}>
                                                            <NotificationsIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Edit Contact">
                                                        <IconButton onClick={() => openInPopup(c)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Delete Contact">
                                                        <IconButton onClick={() => openDeleteContactPopup(c.contactId)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 15]}
                                        count={filteredContacts().length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    ) : (
                        <div></div>
                    )}
                </CardContent>
            </Card>

            <Dialog
                open={openDeleteContactDialog}
                onClose={handleCloseDeleteContactDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Contact
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to delete this contact.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description_text">
                        If you want to remove the contact record stored against
                        all other Accounts they exist under then please tick the
                        box below, otherwise the contact will be removed from
                        only this account.
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="allAccounts"
                                checked={deleteOnAllAccounts}
                                onChange={handleAllAccountsChange}
                            />
                        }
                        label="Remove contact on all accounts"
                    />
                </DialogContent>
                <DialogActions>
                    <Controls.ButtonControl
                        disabled={requesting}
                        variant="outlined"
                        text="Cancel"
                        size="small"
                        color="primary"
                        onClick={() => handleCloseDeleteContactDialog()}
                    />
                    <Controls.ButtonControl
                        disabled={requesting}
                        variant="outlined"
                        text="Confirm"
                        size="small"
                        color="primary"
                        onClick={() => handleDeleteAccountContact(recordForDelete)}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpen}
                onClose={handleCloseRequestCallback}
                maxWidth="sm"
                fullWidth
            >
                <React.Fragment>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between">
                            <Box>Create callback request</Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={important}
                                            onChange={() => setImportant(!important)}
                                        />
                                    }
                                    label="Important"
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={6}
                            style={{ marginBottom: "6px" }}
                        >
                            <Grid item md={6}>
                                <TextField
                                    label="Contact Name"
                                    m={2}
                                    fullWidth
                                    value={callbackData.name}
                                    onChange={(e) =>
                                        dispatch(
                                            setCallbackRequestData({
                                                ...callbackData,
                                                name: e.target.value,
                                            })
                                        )
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Phone"
                                    m={2}
                                    fullWidth
                                    value={callbackData.phone}
                                    onChange={(e) =>
                                        dispatch(
                                            setCallbackRequestData({
                                                ...callbackData,
                                                phone: e.target.value,
                                            })
                                        )
                                    }
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            id="outlined-name"
                            label="Note"
                            m={2}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />

                        <Box display="flex">
                            <Box display="flex" ml={1} mr={3} pt={6}>
                                If callback is for a specific user please select
                                here :{" "}
                            </Box>
                            <Box pt={6}>
                                <Select
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {filteredAdvisers
                                        .filter(
                                            (u) => u.isActive && !u.isDisabled
                                        )
                                        .sort((a, b) =>
                                            a.name > b.name
                                                ? 1
                                                : b.name > a.name
                                                ? -1
                                                : 0
                                        )
                                        .map((u) => (
                                            <MenuItem
                                                key={u.userId}
                                                value={u.userId}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Avatar
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                        className={
                                                            avatarClasses.small
                                                        }
                                                        alt={u.name}
                                                        src={u.photo}
                                                    />
                                                    {u.name}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {callbackData.name && callbackData.phone && (
                            <Button
                                onClick={requestCallback}
                                disabled={requesting}
                                color="primary"
                            >
                                Create
                            </Button>
                        )}
                    </DialogActions>
                </React.Fragment>
            </Dialog>
            <Controls.PopupControl
                title={popupTitleText}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CreateContact
                    recordForEdit={recordForEdit}
                    isExistingRecord={true}
                    addOrEdit={addOrEdit}
                    possibleDupes={dupeResults}
                    checkForDupes={checkForDupes}
                    createLink={createLink}
                    clickable
                    accountId={account.accountId}
                                    />
            </Controls.PopupControl>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ userState: state.userReducer });

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) =>
            dispatch(setSnackAction(message, severity)),
        setCaseContacts: (caseId, contacts) =>
            dispatch(setCaseContacts(caseId, contacts)),
        setReplyContacts: (caseId, contacts) =>
            dispatch(setReplyContactsAction(caseId, contacts)),
        setCreateContactPhone: (phone) =>
            dispatch(setCreateContactPhone(phone)),
        setCreateContactEmail: (email) =>
            dispatch(setCreateContactEmail(email)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountContactsCard);
