const caseFileActions = {
    MOVE: "Move",
    ADD: "Add",
    REMOVE: "Remove",
};

const caseFileTypes = {
    FILE: "File",
    FOLDER: "Folder",
};

const caseDocumentTypes = {
    ACTIVITY_FILE: 0,
    EVIDENCE_FILE: 1,
    PRECEDENT_DOCUMENT: 2
}

const sortNameCol = {
    DATE: "dateAdded",
    NAME: "title",
}

const sortByCol = {
    ASC: "asc",
    DESC: "desc",
}

export { caseFileActions, caseFileTypes, caseDocumentTypes, sortNameCol, sortByCol };