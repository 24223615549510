import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    Input,
    InputAdornment,
    makeStyles,
    Paper,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Tooltip,
} from "@material-ui/core";
import "react-sortable-tree/style.css";
import { setCaseFilesForDownload } from "../redux/actions/caseActions";
import useCaseFilesTree from "../hooks/queries/useCaseFilesTree";
import useUpdateCaseFiles from "../hooks/mutations/useUpdateCaseFiles";
import { useDispatch, useSelector } from "react-redux";
import caseDocumentTypes from "../constants/caseDocumentTypes";
import documentService from "../services/documentService";
import { openFileViewer } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";
import axios from "../plugins/axios";
import SortableTree, {
    removeNodeAtPath,
    toggleExpandedForAll,
} from "react-sortable-tree";
import { downloadFileFromBytes } from "../utils/fileUtils";
import { format } from "date-fns";

import {
    Edit as EditIcon,
    Search as SearchIcon,
    AccountTree as ExpandIcon,
    ExpandLess as CollapseIcon,
    CloudDownload as DownloadIcon,
    Undo as UndoIcon,
    Visibility,
    DeleteForever,
    CreateNewFolder,
    FolderOutlined,
} from "@material-ui/icons";

import caseFileTypes from "../constants/caseFileTypes";
import PasswordProtectedFileDialog from "./dialogs/PasswordProtectedFileDialog";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    profilePhoto: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    },
    saveButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    saveStructureButton: {
        marginRight: theme.spacing(2),
    },
    treeButton: {
        marginLeft: theme.spacing(3),
    },
    treeFolderTitle: {
        marginLeft: theme.spacing(1),
    },
    inProgress: {
        marginRight: theme.spacing(5),
    },
    treeTitle: {
        border: "0",
        outline: "0",
        width: "300px",
    },
    subTitleText: {
        fontWeight: 700,
    },
    treeSubtitle: {
        border: "0",
        outline: "0",
        width: "200px",
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    treeSubtitleText: {
        fontSize: "0.7rem",
    },
    folderNameField: {
        width: "95%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    customNameField: {
        width: "85%",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    diaTitle: {
        flexGrow: 1,
    },
    resetCustomName: {
        marginTop: theme.spacing(1),
    },
    treeContainer: {
        overflowX: "auto",
    },
}));

const CaseFiles = ({ caseId, setMilestoneViewerOpen, setDocumentGuid }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { cases } = useSelector((state) => state.caseReducer);
    const { data, isLoading, isError, error } = useCaseFilesTree(caseId, false);
    const updateCaseFiles = useUpdateCaseFiles();
    const [folderToRemove, setFolderToRemove] = useState(null);
    const [folderDeleteDialogOpen, setFolderDeleteDialogOpen] = useState(false);
    const [emptyFolderDialogOpen, setEmptyFolderDialogOpen] = useState(false);
    const [folderId, setFolderId] = useState(null);
    const [folderName, setFolderName] = useState("");
    const [searchString, setSearchString] = useState("");
    const [folderDialogOpen, setFolderDialogOpen] = useState(false);
    const [filesToMarkAsRemoved, setFilesToMarkAsRemoved] = useState([]);
    const [precedentsToMarkAsRemoved, setPrecedentsToMarkAsRemoved] = useState(
        []
    );
    const [treeData, setTreeData] = useState(data);
    const [fileInfo, setFileInfo] = useState(null);
    const [fileRenameDialogOpen, setFileRenameDialogOpen] = useState(false);
    const [customFileName, setCustomFileName] = useState("");
    const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
    const caseFilesForDownload = cases[caseId]?.caseFilesForDownload ?? [];
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [treeToSave, setTreeToSave] = useState();
    const [fileDeleteDialogOpen, setFileDeleteDialogOpen] = useState(false);
    const [fileToRemove, setFileToRemove] = useState(null);
    const [foldersToMarkAsRemoved, setFoldersToMarkAsRemoved] = useState([]);
    const [passwordProtectedFile, setPasswordProtectedFile] = useState(null)

    const onChangeNode = (newTreeStructure) => {
        //set treeData even if it was only an 'expand' event that happened
        setTreeData(newTreeStructure);

        if (
            JSON.stringify(
                newTreeStructure.map(({ expanded, ...keepAttrs }) => keepAttrs)
            ) !==
            JSON.stringify(
                treeData.map(({ expanded, ...keepAttrs }) => keepAttrs)
            )
        ) {
            //if and actual tree change then update treeToSave
            setTreeToSave(newTreeStructure);
        }
    };

    useEffect(() => {
        if (treeToSave) {
            setTreeData(treeToSave);
            const caseFilesContent = {
                treeData: treeToSave,
                filesToMarkAsRemoved,
                precedentsToMarkAsRemoved,
                foldersToMarkAsRemoved,
            };

            updateCaseFiles.mutate(
                { caseId, downloadableOnly: false, caseFilesContent },
                {
                    onError: (e) =>
                        dispatch(
                            setSnackAction(
                                e?.message ||
                                    "There was an error updating case files",
                                "error"
                            )
                        ),
                    onSuccess: () => {
                        setEmptyFolderDialogOpen(false);
                    },
                }
            );
        }
    }, [
        treeToSave,
        filesToMarkAsRemoved,
        precedentsToMarkAsRemoved,
        foldersToMarkAsRemoved,
    ]);

    const handleAttachmentChecked = (fileDownload, checked) => {
        if (!checked) {
            var filesToBeDownloaded = caseFilesForDownload.filter(
                (selFile) =>
                    selFile.azureFileMetadata.azureFileReference !==
                    fileDownload.node.azureFileMetadata.azureFileReference
            );
            dispatch(setCaseFilesForDownload(filesToBeDownloaded, caseId));
            setDisplayDownloadButton(filesToBeDownloaded?.length > 0);
            return;
        }
        dispatch(
            setCaseFilesForDownload(
                [...caseFilesForDownload, fileDownload.node],
                caseId
            )
        );
        setDisplayDownloadButton(true);
    };

    const handleDownload = async () => {
        try {
            setDownloadInProgress(true);
            const response = await axios.post(
                `${apiUrl}/downloadcaseattachments/${caseId}`,
                { CaseAttachments: caseFilesForDownload },
                {
                    responseType: "arraybuffer",
                }
            );
            downloadFileFromBytes({
                bytes: response.data,
                fileName: `Case${caseId}.zip`,
                fileType: "application/zip",
            });
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    "Could not download zip attachment file",
                    "error"
                )
            );
        } finally {
            setDownloadInProgress(false);
        }
    };

    useEffect(() => {
        setTreeData(data);
        dispatch(setCaseFilesForDownload([], caseId));
    }, [data]);

    const onDocumentViewClick = async (file) => {
        if (file.node.type === caseDocumentTypes.PRECEDENT_DOCUMENT) {
            handlePrecedentFilePreview(
                file.node.precedentFileMetadata.precedentIdentifier
            );
            return;
        }

        await handleAzureFilePreview({
            documentName: file.node.customName || file.node.name,
            reference: file.node.azureFileMetadata.azureFileReference,
            contentType: file.node.azureFileMetadata.contentType,
            extension: file.node.extension,
        });
    };

    const handlePrecedentFilePreview = (identifier) => {
        setDocumentGuid(identifier);
        setMilestoneViewerOpen(true);
    };

    const handleAzureFilePreview = async ({
        documentName,
        reference,
        contentType,
        extension,
    }) => {

        let attachment = {
            reference,
            contentType,
            filename: documentName
        }

        try {                 

            const previewPath =  await documentService.retrieveDocumentPreviewPath(attachment);
            
            if (previewPath === null) {
                setPasswordProtectedFile(attachment);                
                return;
            }           

            dispatch(
                openFileViewer(
                    {
                        type: extension,
                        path: previewPath,
                        name: documentName,
                    },
                    caseId,
                    attachment
                )
            );
        } catch (e) {            
            setPasswordProtectedFile(attachment);
            console.error(e);
        }
    };

    const removeNode = ({ node, path, treeIndex }) => {
        const newTree = removeNodeAtPath({
            treeData: treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });
        if (node.type === caseDocumentTypes.PRECEDENT_DOCUMENT) {
            setPrecedentsToMarkAsRemoved([
                ...precedentsToMarkAsRemoved,
                node.precedentFileMetadata.precedentIdentifier,
            ]);
        } else {
            setFilesToMarkAsRemoved([
                ...filesToMarkAsRemoved,
                node.azureFileMetadata.azureFileReference,
            ]);
        }
        setTreeToSave(newTree);
    };

    const removeFolder = () => {
        setFoldersToMarkAsRemoved([
            ...foldersToMarkAsRemoved,
            folderToRemove.node.folderId,
        ]);

        const newTree = treeData
            .filter((obj) => obj.folderId != folderToRemove.node.folderId)
            .concat(folderToRemove.node.children);

        setTreeToSave(newTree);
        setFolderDeleteDialogOpen(false);
    };

    const handleFolderDialogClose = () => {
        setFolderName("");
        setFolderDialogOpen(false);
    };

    const handleFileRenameDialogClose = () => {
        setCustomFileName("");
        setFileRenameDialogOpen(false);
    };

    const removeFile = () => {
        removeNode(fileToRemove);
        setFileDeleteDialogOpen(false);
    };

    const handleFileRenameSave = (e) => {
        e.preventDefault();

        if (fileInfo.precedentFileMetadata) {
            const newPrecedentTree = treeData.map((obj) => {
                if (obj.docType === "Folder") {
                    if (
                        obj.children.some(
                            (test) =>
                                test.precedentFileMetadata
                                    ?.precedentIdentifier ===
                                fileInfo.precedentFileMetadata
                                    .precedentIdentifier
                        )
                    ) {
                        obj.children = obj.children.map((x) => {
                            const isEditedFile =
                                x.precedentFileMetadata?.precedentIdentifier ===
                                fileInfo.precedentFileMetadata
                                    .precedentIdentifier;
                            return isEditedFile
                                ? { ...x, customName: customFileName }
                                : x;
                        });
                        return obj;
                    }
                    return obj;
                } else if (
                    obj.precedentFileMetadata?.precedentIdentifier ===
                    fileInfo.precedentFileMetadata.precedentIdentifier
                ) {
                    return { ...obj, customName: customFileName };
                }
                return obj;
            });
            setTreeToSave(newPrecedentTree);
        } else {
            const newTree = treeData.map((obj) => {
                if (obj.docType === "Folder") {
                    if (
                        obj.children.some(
                            (test) =>
                                test.azureFileMetadata?.azureFileReference ===
                                fileInfo.azureFileMetadata.azureFileReference
                        )
                    ) {
                        obj.children = obj.children.map((x) => {
                            const isEditedFile =
                                x.azureFileMetadata?.azureFileReference ===
                                fileInfo.azureFileMetadata.azureFileReference;
                            return isEditedFile
                                ? { ...x, customName: customFileName }
                                : x;
                        });
                        return obj;
                    }
                    return obj;
                } else if (
                    obj.azureFileMetadata?.azureFileReference ===
                    fileInfo.azureFileMetadata.azureFileReference
                ) {
                    return { ...obj, customName: customFileName };
                }
                return obj;
            });
            setTreeToSave(newTree);
        }

        setCustomFileName("");
        setFileRenameDialogOpen(false);
    };

    const handleFolderSave = (e) => {
        e.preventDefault();

        if (folderId) {
            const newTree = treeData.map((obj) =>
                obj.folderId === folderId ? { ...obj, title: folderName } : obj
            );
            setTreeToSave(newTree);
        } else {
            setTreeToSave(
                treeData.concat({
                    title: folderName,
                    docType: caseFileTypes.FOLDER,
                })
            );
        }
        setFolderName("");
        setFolderId(null);
        setFolderDialogOpen(false);
    };

    const expandAndCollapse = (expanded) => {
        setTreeData(
            toggleExpandedForAll({
                treeData: treeData,
                expanded,
            })
        );
    };

    const canDrop = ({ node, nextParent, prevParent }) => {
        if (node.docType === caseFileTypes.FOLDER && nextParent === null) {
            return true;
        }

        if (prevParent && nextParent) {
            if (prevParent.folderId == nextParent.folderId) {
                return false;
            }
        }

        if (prevParent === nextParent) {
            return false;
        }

        return true;
    };

    const customSearchMethod = ({ node, searchQuery }) =>
        searchQuery &&
        node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;    

    const treeContent = () => {
        if (isLoading)
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress size={24} />
                </Box>
            );

        if (isError)
            return (
                <Box display="flex" justifyContent="center">
                    <Typography>
                        {error?.message ||
                            "Could not fetch document files for case."}
                    </Typography>
                </Box>
            );

        if (treeData && treeData.length > 0)
            return (
                <>
                    <Box display="flex" justifyContent="space-between" my={3}>
                        <Box>
                            <Button
                                startIcon={<CollapseIcon />}
                                className={classes.treeButton}
                                variant="outlined"
                                color="secondary"
                                onClick={() => expandAndCollapse(false)}
                            >
                                Collapse Folders
                            </Button>
                            <Button
                                startIcon={<ExpandIcon />}
                                className={classes.treeButton}
                                variant="outlined"
                                color="secondary"
                                onClick={() => expandAndCollapse(true)}
                            >
                                Expand All Folders
                            </Button>
                            <Input
                                size="mini"
                                placeholder="Search File Names"
                                value={searchString}
                                onChange={(event) =>
                                    setSearchString(event.target.value)
                                }
                                className={classes.treeButton}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box>
                            <Box display="flex" justifyContent="flex-end">
                                {downloadInProgress ? (
                                    <CircularProgress
                                        className={classes.inProgress}
                                    />
                                ) : (
                                    displayDownloadButton && (
                                        <Button
                                            startIcon={<DownloadIcon />}
                                            className={
                                                classes.saveStructureButton
                                            }
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleDownload}
                                        >
                                            Download
                                        </Button>
                                    )
                                )}
                                <Button
                                    startIcon={<CreateNewFolder />}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setFolderDialogOpen(true)}
                                >
                                    Add New Folder
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <div className={classes.treeContainer}>
                        <SortableTree
                            isVirtualized={false}
                            treeData={treeData}
                            onChange={onChangeNode}
                            searchMethod={customSearchMethod}
                            searchQuery={searchString}
                            canNodeHaveChildren={(node) =>
                                node.docType != caseFileTypes.FILE
                            }
                            maxDepth={2}
                            canDrop={canDrop}
                            generateNodeProps={(rowInfo, path) => ({
                                title: (
                                    <Box display="flex" alignItems="flex-start">
                                        {rowInfo.node.docType ==
                                        caseFileTypes.FOLDER ? (
                                            <>
                                                <Box>
                                                    <FolderOutlined fontSize="small" />
                                                </Box>

                                                <Box
                                                    className={
                                                        classes.treeFolderTitle
                                                    }
                                                    flexGrow={1}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.subTitleText
                                                        }
                                                    >
                                                        {rowInfo.node.title}
                                                    </Typography>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box
                                                className={
                                                    classes.treeFolderTitle
                                                }
                                                flexGrow={1}
                                            >
                                                <Typography>
                                                    {rowInfo.node.customName
                                                        ?.length > 0
                                                        ? rowInfo.node
                                                              .customName
                                                        : rowInfo.node.title}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ),
                                subtitle:
                                    rowInfo.node.docType ==
                                    caseFileTypes.FILE ? (
                                        <Box
                                            display="flex"
                                            alignItems="flex-start"
                                        >
                                            <Box
                                                className={classes.treeSubtitle}
                                            >
                                                <Typography
                                                    className={
                                                        classes.treeSubtitleText
                                                    }
                                                >
                                                    {"Date Added : " +
                                                        format(
                                                            new Date(
                                                                rowInfo.node.dateAdded
                                                            ),
                                                            "dd/MM/yyyy"
                                                        )}
                                                </Typography>
                                            </Box>
                                            <Box
                                                className={classes.treeSubtitle}
                                            >
                                                <Typography
                                                    className={
                                                        classes.treeSubtitleText
                                                    }
                                                >
                                                    {"Added By : " +
                                                        rowInfo.node.addedBy}
                                                </Typography>
                                            </Box>
                                            <Box
                                                className={classes.treeSubtitle}
                                            >
                                                <Typography
                                                    className={
                                                        classes.treeSubtitleText
                                                    }
                                                >
                                                    {"File Type : " +
                                                        rowInfo.node.extension}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : null,
                                buttons: [
                                    rowInfo.node.docType ==
                                    caseFileTypes.FILE ? (
                                        <>
                                            {rowInfo.node
                                                ?.azureFileMetadata && (
                                                <Checkbox
                                                    onChange={(event) =>
                                                        handleAttachmentChecked(
                                                            rowInfo,
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    setFileInfo(rowInfo.node);
                                                    setCustomFileName(
                                                        rowInfo.node?.customName
                                                    );
                                                    setFileRenameDialogOpen(
                                                        true
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setFileToRemove(rowInfo);
                                                    setFileDeleteDialogOpen(
                                                        true
                                                    );
                                                }}
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setFileInfo(rowInfo.node)
                                                    onDocumentViewClick(rowInfo)
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </>
                                    ) : rowInfo.node.docType ==
                                          caseFileTypes.FOLDER &&
                                      rowInfo.node.folderId > 0 ? (
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    setFolderId(
                                                        rowInfo.node.folderId
                                                    );
                                                    setFolderName(
                                                        rowInfo.node.title
                                                    );
                                                    setFolderDialogOpen(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setFolderToRemove(rowInfo);
                                                    setFolderDeleteDialogOpen(
                                                        true
                                                    );
                                                }}
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                        </>
                                    ) : null,
                                ],
                            })}
                        />
                    </div>
                </>
            );

        return (
            <Paper className={classes.card}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    className={classes.padTop}
                >
                    <Button
                        startIcon={<CreateNewFolder />}
                        variant="contained"
                        color="secondary"
                        onClick={() => setFolderDialogOpen(true)}
                    >
                        Add New Folder
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography>No associated documents found.</Typography>
                </Box>
            </Paper>
        );
    };

    return (
        <Paper className={classes.card}>
            <PasswordProtectedFileDialog
                open={!!passwordProtectedFile}
                onClose={() => setPasswordProtectedFile(null)}
                fileName={passwordProtectedFile?.filename}
                caseString={" Case " + caseId}
                attachment={passwordProtectedFile}
            />
            {treeContent()}
            <Dialog
                open={folderDialogOpen}
                onClose={handleFolderDialogClose}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            {folderId > 0
                                ? "Edit Folder Name"
                                : "Create New Case File Folder"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label="Folder Name"
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        className={classes.folderNameField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => handleFolderDialogClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleFolderSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={fileDeleteDialogOpen}
                onClose={() => setFileDeleteDialogOpen(false)}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            Confirm removal of File
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm that you want to remove this file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setFileDeleteDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => removeFile()}
                    >
                        Remove File
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={fileRenameDialogOpen}
                onClose={handleFileRenameDialogClose}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            Edit File Name
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label="Original File Name"
                        value={fileInfo?.title}
                        className={classes.folderNameField}
                    />
                    <TextField
                        variant="outlined"
                        label="New File Name"
                        value={customFileName || ""}
                        onChange={(e) => setCustomFileName(e.target.value)}
                        className={
                            fileInfo?.customName?.length > 0
                                ? classes.customNameField
                                : classes.folderNameField
                        }
                    />
                    {fileInfo?.customName?.length > 0 && (
                        <Tooltip title="Reset custom name">
                            <IconButton
                                className={classes.resetCustomName}
                                onClick={() => setCustomFileName("")}
                            >
                                <UndoIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => handleFileRenameDialogClose(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleFileRenameSave}
                    >
                        Save Custom Name
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={folderDeleteDialogOpen}
                onClose={() => setFolderDeleteDialogOpen(false)}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            Confirm removal of Folder
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm that you want to remove this folder? Note
                        that once removed any files contained within it will
                        move to the root folder.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setFolderDeleteDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => removeFolder()}
                    >
                        Remove Folder
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={emptyFolderDialogOpen}
                onClose={() => setEmptyFolderDialogOpen(false)}
                fullWidth
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            component="div"
                            className={classes.diaTitle}
                        >
                            Empty Folder
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        One of the folders is empty, please note that empty
                        folders are not permitted. Do you want to proceed with
                        the Save and remove the empty folders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setEmptyFolderDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => saveFileStructure()}
                    >
                        Proceed with Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default CaseFiles;
