import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setReplyContactsAction,
    addReplyPrecedent,
    removeReplyAttachment,
    removeReplyPrecedent,
    removeReplyPrecedents,
    setReplyAttachmentsAction,
    setReplyAttachmentsLinkedAction,
    addReplyCc,
    removeReplyIntCc,
    removeReplyContactNotKnown,
    removeReplyCCContactNotKnown,
    addReplyNonContactCc,
    setReplyToIndexAction,
    setReplySubjectAction,
    setReplyEditorHtmlAction,
    setEditingReplyChainAction,
    setShowEditor,
    addReplyContactNotKnown,
    setReplyDescription,
    setCaseAttachmentsSelected,
    setReplyCcContactsAction,
    setReplyBccContactsAction,
    addReplyBcc,
    removeReplyBcc,
    addReplyNonContactBcc,
    removeReplyNonContactBcc,
    setDraftAttachmentsSelected,
    removeReplyCc, clearCaseDraftEmail, setReplyFromAddressAction,
} from "../redux/actions/caseActions";
import { openFileViewer, setUserTimerRunning } from "../redux/actions/userActions";
import { format } from "date-fns";
import { filesize } from "filesize";
import PrecedentChooser from "../components/PrecedentChooser";
import TemplateChooser from "../components/TemplateChooser";
import AttachFileModal from "../components/AttachFileModal";
import { emailIsValid } from "../utils/validators";
import userRoles from "../constants/userRoles";
import { FileText as FileTextIcon, Paperclip } from "react-feather";
import useCaseExternalContacts from "../hooks/queries/useCaseExternalContacts";
import {
    Typography,
    Box,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Avatar,
    Menu,
    MenuItem,
    Paper,
    Button,
    ListSubheader,
    InputAdornment,
    Input,
    MenuList,
    TextField,
    Grid,
    makeStyles,
    Fab,
    CircularProgress,
} from "@material-ui/core";
import { AttachFile as AttachFileIcon, Add as AddIcon, Search as SearchFilterIcon } from "@material-ui/icons";
import { setSnackAction } from "../redux/actions/snackActions";
import avatarStyles from "../theme/avatars";
import MyCKEditor from "../ckeditor/MyCKEditor";
import {
    appendEditorContent,
    getNodesByType,
    insertEvidenceLink,
    insertPrecedentLink,
    insertTemplateHtml,
    removePrecedentById,
} from "../ckeditor/ckEditorUtils";
import { useAuth } from "../contexts/authContext";
import emailDraftService from "../services/emailDraftService";
import requestStatus from "../constants/requestStatus";
import { sendEmail, saveDraft } from "../redux/actions/thunks";
import accountService from "../services/accountService";
import documentService from "../services/documentService";
import clsx from "clsx";
import { MAX_ACTIVITY_DESCRIPTION } from "../constants/lengthConstants";
import useCaseTimeEventGroupsForUserAndAccount from "../hooks/queries/useCaseTimeEventGroupsForUserAndAccount";
import CaseTimeEventGroupsDialog from "./dialogs/CaseTimeEventGroupsDialog";
import attachmentTypes from "../constants/attachmentTypes";
import PasswordProtectedFileDialog from "./dialogs/PasswordProtectedFileDialog";
import sharepointService from "../services/sharepointService";
import { useEmailDraft } from "../contexts/emailDraftContext";
import EmailDrafts from "./EmailDrafts";
import storageService from "../services/storageService";
import StarRater from "./StarRater";
import parseEmailService from "../services/parseEmailService";
import openAiService from "../services/openAiService";
import { getFileExtension } from "../utils/fileNameUtils";
import * as types from "../redux/constants";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";

const useStyles = makeStyles((theme) => ({
    editorActionButton: {
        marginRight: theme.spacing(2),
    },
    draftSavedInfo: {
        marginTop: theme.spacing(1),
        color: theme.palette.grey[700],
    },
    textField: {
        marginLeft: "12px",
        width: "100%",
    },
    addContactButton: {
        minWidth: 0,
    },
    menuList: {
        border: "1px solid #e0e0e0",
        marginTop: "12px",
    },
    avatar: {
        marginRight: "8px",
    },
    captureEvidenceButton: {
        marginRight: "8px",
    },
    attachmentChip: {
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    captureEvidenceInput: {
        display: "none",
    },
    attachButton: {
        marginRight: "8px",
    },
    attachIcon: {
        marginLeft: "8px",
    },
    replyingToWrapper: {
        borderRadius: "0px",
        boxShadow: "none",
        padding: "10px",
        marginBottom: "10px",
    },
    replyingToContent: {
        overflow: "auto",
    },
    paper: {
        padding: "10px",
        marginBottom: "10px",
    },
    addContactMenu: {
        borderRadius: "0px",
        "& ul": {
            padding: "0px",
        },
    },
    chip: {
        marginLeft: "10px",
        marginBottom: "6px",
    },
    fabButton: {
        position: "absolute",
        zIndex: 300,
        transform: "rotate(90deg)",
        right: "-100px",
        height: "22px",
    },
    replyContainer: {
        position: "relative",
    },
    rightAligned: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
}));

const ComposeReply = ({ onClose, onAddPrecedent, handleOpenCaseResearch, }) => {
    const dispatch = useDispatch();

    const { currentCaseId: caseId, cases } = useSelector((state) => state.caseReducer);
    const configState = useSelector((state) => state.configReducer);
    const userState = useSelector((state) => state.userReducer);
    const { editorRef } = useEmailDraft();

    const contractId = cases[caseId].caseSummary.contractId;

    const { draftEmailsSummary, lastModifiedBy, lastModifiedDate, setLastModifiedBy, setLastModifiedDate, refetchDraftEmails } = useEmailDraft();

    const avatarClasses = avatarStyles();
    const classes = useStyles();
    const [addContactEl, setAddContactEl] = useState(null);
    const [addCcContactEl, setAddCcContactEl] = useState(null);
    const [addBccContactEl, setAddBccContactEl] = useState(null);
    const [accountContacts, setAccountContacts] = useState([]);
    const [removingDraft, setRemovingDraft] = useState(requestStatus.IDLE);
    const [evidenceSelected, setEvidenceSelected] = useState(false);
    const [ccQuery, setCcQuery] = useState("");
    const [bccQuery, setBccQuery] = useState("");
    const [ccResults, setCcResults] = useState([]);
    const [bccResults, setBccResults] = useState([]);
    const [draftRemovalModalOpen, setDraftRemovalModalOpen] = useState(false);
    const { user, hasRole } = useAuth();
    const [toQuery, setToQuery] = useState("");
    const [toResults, setToResults] = useState([]);
    const [toOpen, setToOpen] = useState(false);
    const [ccOpen, setCcOpen] = useState(false);
    const [bccOpen, setBccOpen] = useState(false);
    const [showPrecedents, setShowPrecedents] = useState(false);
    const { showEditor } = useSelector((state) => state.caseReducer);
    const c = cases[caseId];
    const draftId = c.draftEmail?.id;
    const savingDraft = c && c.reply.savingDraft;
    const { starRating, isAiGenerated, replyingToPlainText, precedents } =
        c.reply;
    const [usingPartnershipEmailSettings, setUsingPartnershipEmailSettings] = useState(false);

    const [heldTimeOpen, setHeldTimeOpen] = useState(false);
    const { data: caseTimeEventGroups } = useCaseTimeEventGroupsForUserAndAccount({
        userId: userState.userProfile.userId,
        accountId: c.account.accountId,
    });

    const { isEditingDraft, sending, editorHtml, aiGeneratedPrecedentIds } =
        c.reply;

    const [attachFileModalOpen, setAttachFileModalOpen] = useState(false);
    const { caseAttachmentsSelected, draftAttachmentsSelected, attachments } = c && c.reply;   
       
    const [passwordProtectedFile, setPasswordProtectedFile] = useState(null);

    const {
        data: externalContacts,
        isError: isExternalContactsError,
        isLoading: isExternalContactsLoading,
        error: externalContactsError,
    } = useCaseExternalContacts({ caseId });

    useEffect(() => {
        dispatch(setShowEditor(true));
    }, [caseId, dispatch]);

    const partnershipEmailSettings = useMemo(() => {
        
        let settings = { isConfigured: false, email: '', signOff: '' }
        const caseContract = c.account.contracts.filter(
                (ac) => ac.contractId == c.caseSummary.contractId
            )[0];
        if (caseContract?.isPartnership){
            let partnership = configState.partnerships[caseContract.partnershipId];
            if (partnership) {
                return { 
                    ...settings, 
                    isConfigured: !!partnership.fromEmail || !!partnership.signoffText, 
                    email: partnership.fromEmail, 
                    signOff: partnership.signoffText.trim() 
                }
            }
        }

        return settings;
    }, [c, configState]);

    useEffect(() => {

        dispatch(setReplyFromAddressAction(caseId, user.details.email));
        if (partnershipEmailSettings.isConfigured) {
            setUsingPartnershipEmailSettings(true)
        }

        //set account level signoff text if exists
        //only set up for 1 or 2 clients
        if (c.account.bccEmails.length) {
            if (!c.reply.inboundEmailBCCNonContacts || !c.reply.inboundEmailBCCNonContacts.includes(c.account.bccEmails)) {
                dispatch(addReplyNonContactBcc(caseId, c.account.bccEmails));
            }
        }

        if (c.account.emailAccountSignOffText) {
            let updatedEditorHtml = editorHtml;
            let accountSignOffMarkup = `<p>${c.account.emailAccountSignOffText}</p>`;
            if (!updatedEditorHtml.includes(accountSignOffMarkup)) {
                updatedEditorHtml += accountSignOffMarkup;
            }
            setTimeout(() => {
                dispatch(setReplyEditorHtmlAction(caseId, updatedEditorHtml));
            });
        }

    }, [])

    useEffect(() => {

        if (partnershipEmailSettings.isConfigured) {

            const fromAddress = usingPartnershipEmailSettings
                ? partnershipEmailSettings.email
                    ? partnershipEmailSettings.email
                    : user.details.email
                : user.details.email;

            dispatch(setReplyFromAddressAction(caseId, fromAddress));

            let updatedEditorHtml = editorHtml;

            if (partnershipEmailSettings.signOff) {
                let partnershipSignOffMarkup = `<p>${partnershipEmailSettings.signOff}</p>`;
    
                if (usingPartnershipEmailSettings && !updatedEditorHtml.includes(partnershipSignOffMarkup)) {
                    updatedEditorHtml += partnershipSignOffMarkup;
                } else if (!usingPartnershipEmailSettings) {
                    const lastIndexOfSignOff = updatedEditorHtml.lastIndexOf(partnershipSignOffMarkup);
                    if (lastIndexOfSignOff !== -1){
                        updatedEditorHtml = updatedEditorHtml.slice(0, lastIndexOfSignOff) + updatedEditorHtml.slice(lastIndexOfSignOff + partnershipSignOffMarkup.length);
                    }
                }
    
                setTimeout(() => {
                    dispatch(setReplyEditorHtmlAction(caseId, updatedEditorHtml));
                });
            }
        }

    }, [usingPartnershipEmailSettings])
    

    const from = useMemo(() => c.reply.fromAddress);

    const nextTo = (contactId) => {
        const contact = c.reply.contacts.filter((contact) => contact.contactId === contactId)[0];

        dispatch(setReplyToIndexAction(caseId, contactId, (contact.toIndex + 1) % contact.emails.length));
    };

    const setEditorHtml = (html) => {
        console.log({ foo: viewToPlainText(editor.editing.view.document.getRoot()) })
        dispatch(setReplyEditorHtmlAction(caseId, html));

        if (editorHtml !== html) {
            editorRef.current.model.change((writer) => {
                const root = editorRef.current.model.document.getRoot();
                const evidenceLinks = getNodesByType(writer, "evidencelink", root);
                setEvidenceSelected(evidenceLinks.length > 0);

                const documentLinks = getNodesByType(writer, "documentlink", root);
                const newPrecedentIds = documentLinks.map((x) => x.getAttribute("precedentId"));

                const existingPrecedentIds = c.reply.precedents.map((p) => p.precedentId);
                const redundantPrecedentIds = existingPrecedentIds.filter((p) => !newPrecedentIds.includes(p));

                // CKCHANGE _ NEED TO PREVENT ACCIDENTAL REMOVAL
                if (redundantPrecedentIds.length > 0) dispatch(removeReplyPrecedents(caseId, redundantPrecedentIds));
            });
        }
    };

    const editReplyChain = () => {
        appendEditorContent(editorRef.current, getReplyChainContent());

        dispatch(setEditingReplyChainAction(true));
    };

    const getReplyChainContent = () => {
        let content = `
            <p>----------</p>
            <p>From: ${c.reply.replyingTo.person}</p>
            <p>Date: ${format(new Date(c.reply.replyingTo.eventTime), "dd/MM/yyyy HH:mm '(GMTZ)'")}</p>
        `;
        
        if (c.reply.replyingTo.toAddress)
            content += `<p>To: ${c.reply.replyingTo.toAddress}</p>`;
        
        content += `<p>Subject: ${c.reply.replyingTo.subject}</p>`;
        
        if (c.reply.replyingTo.ccAddresses)
        content += `<p>Cc: ${c.reply.replyingTo.ccAddresses || ""}</p>`;
        
        content += `${c.reply.replyingTo.content}`;
       
        return content;
    };

    const handleRemoveDraftClick = async () => {
        setDraftRemovalModalOpen(true);
    };

    const removeDraft = async () => {
        const removeDraftAsync = async () => {
            if (draftRemovalModalOpen) setDraftRemovalModalOpen(false);

            dispatch({ type: types.SAVE_DRAFT_REQUEST });
            setRemovingDraft(requestStatus.PENDING);            

            await emailDraftService.removeDraft(draftId);

            setRemovingDraft(requestStatus.PENDING);
            onClose();
        };
        try {
            await removeDraftAsync();
            dispatch(clearCaseDraftEmail({ caseId }));
            refetchDraftEmails();
        } catch (e) {
            console.error(e);
            dispatch(setSnackAction("Could not remove draft.", "error"));
            setRemovingDraft(requestStatus.REJECTED);
        }
    };

    const overwriteDraft = (editorData) => {
        dispatch(saveDraft({ editorHtml: editorData, setLastModifiedBy, setLastModifiedDate, isAutoSave: false, caseId }));
    };

    const handleSaveDraftClick = async () => {
        let editorData = editorRef.current.getData();
        if (c.reply.replyingTo) editorData += getReplyChainContent(c.reply);

        if (editorData == "") {
            dispatch(setSnackAction("Email must have content", "error"));
            return;
        }

        overwriteDraft(editorData);
    };

    const sendReply = async (_e, selectedCaseTimeEventGroupIds = []) => {
        if (userState.isUserTimerRunning) {
            dispatch(setUserTimerRunning(false));
        }

        if (editorHtml === "") {
            dispatch(setSnackAction("Email must have content", "warning"));
            return;
        }

        if (
            c.reply.contacts &&
            c.reply.contacts.length === 0 &&
            ((c.reply.contactsNotKnown && c.reply.contactsNotKnown.length === 0) || c.reply.contactsNotKnown === null)
        ) {
            dispatch(setSnackAction("Email must have at least one recipient", "warning"));
            return;
        }

        if (!c.reply.description.length > 0) {
            dispatch(setSnackAction("Email must have a description", "warning"));
            return;
        }

        var totalFileAttachSize = 0;

        c.reply.attachments.forEach((a) => {
            totalFileAttachSize += a.size || 0;
        });

        caseAttachmentsSelected.forEach((a) => {
            totalFileAttachSize += a.size || 0;
        });

        //150MB limit on graph attachments
        //https://docs.microsoft.com/en-us/graph/outlook-large-attachments?tabs=http
        if (totalFileAttachSize > 157286400) {
            dispatch(setSnackAction("Email not sent - attachments exceed 150mb limit", "warning"));
            return;
        }

        if (caseTimeEventGroups?.length && !heldTimeOpen) {
            setHeldTimeOpen(true);
            return;
        }
        setHeldTimeOpen(false);

        var contactsToSendTo = c.reply.contacts;

        const joinEmailArray = (arr) => (!arr.length ? "" : arr.join(";"));

        var toEmailNew = contactsToSendTo.map((c) => c.emails[c.toIndex]);

        const toEmailNotKnownNew = c.reply.contactsNotKnown ? c.reply.contactsNotKnown.map((email) => email) : "";

        var toEmailCombined = toEmailNew.concat(toEmailNotKnownNew);
        var toEmailList = toEmailCombined.join(";");

        const ccNonContactEmailListNew = c.reply.inboundEmailCCNonContacts ? c.reply.inboundEmailCCNonContacts.map((email) => email) : [];

        const ccContactsEmailList = c.reply.ccContacts.map((c) => c.emails[c.toIndex]);

        const ccIntContactEmailListNew = c.reply.ccUserIds ? c.reply.ccUserIds.map((id) => userState.users[id].email) : [];

        const inboundEmailCCContacts = c.reply?.inboundEmailCCContacts ? c.reply.inboundEmailCCContacts.map((contact) => contact.email) : [];

        const combinedCCEmails = [...ccNonContactEmailListNew, ...ccContactsEmailList, ...ccIntContactEmailListNew, ...inboundEmailCCContacts];

        const bccAdviserEmailList = c.reply.inboundEmailBCCNonContacts ? c.reply.inboundEmailBCCNonContacts.map((email) => email) : [];

        const bccContactsEmailList = c.reply.bccContacts.map((c) => c.emails[c.toIndex]);

        const bccAdviserIntEmailList = c.reply.bccUserIds ? c.reply.bccUserIds.map((id) => userState.users[id].email) : [];

        const combinedBCCEmails = [...bccAdviserEmailList, ...bccContactsEmailList, ...bccAdviserIntEmailList];

        let replyContent = editorRef.current.getData();

        if (c.reply.replyingTo && !c.reply.editingReplyChain) replyContent += getReplyChainContent();

        if (!!replyingToPlainText) {
            try {
                // send adviser reply html to sigparser
                const parsedEmail = await parseEmailService.parseEmail({
                    fromAddress: from,
                    htmlBody: editorHtml,
                });

                // send adviser reply plain
                if (!!parsedEmail?.cleanedBodyPlain) {
                    await openAiService.saveAiThread({
                        localThread: {
                            approved: true,
                            caseId,
                            caseType:
                                configState.caseTypes[c.caseSummary.caseTypeId]
                                    ?.name,
                            caseTypeId: c.caseSummary.caseTypeId,
                            partner:
                                c.account.partnerships?.[0]?.partnershipName,
                            subject: c.reply.replyingTo?.subject,
                        },
                        messages: [
                            {
                                message: replyingToPlainText,
                                tag: "Client",
                                precedentIds: [],
                            },
                            {
                                message: parsedEmail.cleanedBodyPlain,
                                tag: "Adviser",
                                precedentIds: [],
                            },
                        ],
                    });
                }
            } catch (e) {
                // ignore
            }
        }

        var event = {
            adviserId: userState.userProfile.userId,
            itemType: "Email",
            eventTime: new Date(),
            content: replyContent,
            person: userState.userProfile.name,
            direction: 1,
            fromAddress: from,
            toAddress: toEmailList,
            contactIds: contactsToSendTo.filter((c) => !!c.contactId).map((c) => c.contactId),
            precedents: c.reply.precedents.map((p) => {
                return {
                    precedentId: p.precedentId,
                    name: p.name,
                    currentVersion: p.currentVersion,
                    htmlContent: p.htmlContent,
                };
            }),
            subject: c.reply.subject,
            replyingToEmailId: c.reply.replyingTo?.replyingToEmailId,
            ccAddresses: joinEmailArray(combinedCCEmails),
            bccAddresses: joinEmailArray(combinedBCCEmails),
            isSendingDraft: isEditingDraft,
            isChargeable: c.caseSummary.isChargeable,
            activityDescription: c.reply.description,
            hasPrecedents: Boolean(c.reply.precedents?.length),
        };
        dispatch(
            sendEmail({
                contractId,
                event,
                files: c.reply.attachments,
                isAdvisor: hasRole(userRoles.LEGAL_ADVISOR),
                from,
                draftAttachmentIds: c.reply.attachments.filter((x) => x.file && !!x.file.azureFileId).map((x) => x.file.azureFileId),
                caseTimeEventGroupIds: selectedCaseTimeEventGroupIds,
                liveCaseDocumentIds: draftAttachmentsSelected.map((x) => x.id),
                azureFileIds: caseAttachmentsSelected.map((x) => x.azureFileId),
                sizeExcludingLiveDocs: totalFileAttachSize,
                draftId,
                draftEmailsSummary,
                aIRating: starRating,
                plainText: viewToPlainText(editor.editing.view.document.getRoot()),
            })
        );
    };

    const handleRemoveExternalContact = (id) => {
        dispatch(
            setReplyContactsAction(
                caseId,
                c.reply.contacts.filter((c) => c.externalContactId !== id)
            )
        );
    };

    const handleRemoveContact = (id) => {
        dispatch(
            setReplyContactsAction(
                caseId,
                c.reply.contacts.filter((c) => c.contactId !== id)
            )
        );
    };

    const handleAddContact = (contact) => {
        dispatch(setReplyContactsAction(caseId, [...c.reply.contacts, contact]));
    };

    const handleRemoveExternalCcContact = (id) => {
        dispatch(
            setReplyCcContactsAction(
                caseId,
                c.reply.ccContacts.filter((c) => c.externalContactId !== id)
            )
        );
    };

    const handleRemoveCcContact = (id) => {
        dispatch(
            setReplyCcContactsAction(
                caseId,
                c.reply.ccContacts.filter((c) => c.contactId !== id)
            )
        );
    };

    const handleAddCcContact = (contact) => {
        dispatch(setReplyCcContactsAction(caseId, [...c.reply.ccContacts, contact]));
    };

    const handleRemoveExternalBccContact = (id) => {
        dispatch(
            setReplyBccContactsAction(
                caseId,
                c.reply.bccContacts.filter((c) => c.externalContactId !== id)
            )
        );
    };

    const handleRemoveBccContact = (id) => {
        dispatch(
            setReplyBccContactsAction(
                caseId,
                c.reply.bccContacts.filter((c) => c.contactId !== id)
            )
        );
    };

    const handleAddBccContact = (contact) => {
        dispatch(setReplyBccContactsAction(caseId, [...c.reply.bccContacts, contact]));
    };

    useEffect(() => {
        const getAccountContacts = async () => {
            try {
                const { data: accountContacts } = await accountService.getAccountContacts(c.account.accountId);
                setAccountContacts(accountContacts);
            } catch (e) {
                console.error(e);
            }
        };

        getAccountContacts();
    }, [c.account.accountId]);

    const handleRemovePrecedent = (p) => {
        removePrecedentById(editorRef.current, p.precedentId);
        dispatch(removeReplyPrecedent(caseId, p.precedentId));
        setEditorHtml(editorRef.current.getData());
    };

    useEffect(() => {
        if (
            !!editorRef.current &&
            aiGeneratedPrecedentIds?.length > 0 &&
            precedents?.length === 0
        ) {
            aiGeneratedPrecedentIds.forEach((id) => {
                const precedent = configState.precedents[id];
                if (precedent) precedentSelected(precedent, false);
            });
        }
    }, [aiGeneratedPrecedentIds, precedents, editorRef.current]);

    const precedentSelected = async (p, goToTab) => {
        //if the precedent that has been selected to be attached is a flat file then gather it from the azure store and add as an attachment
        if (p.azureFileReference) {
            try {
                const documentBytes = await documentService.openDocument(p.azureFileReference, false);

                const blob = new Blob([documentBytes], {
                    type: p.azureFileContentType,
                });
                blob.name = p.name;

                const blobAsFile = new File([blob], p.name, {
                    type: p.azureFileContentType,
                    lastModified: new Date().getTime(),
                    precedentId: p.precedentId,
                });

                dispatch(setReplyAttachmentsAction(caseId, [...c.reply.attachments, { id: p.azureFileReference, file: blobAsFile, azureFileId: p.azureFileId, precedentId: p.precedentId }]));

                dispatch(setReplyAttachmentsLinkedAction(caseId, p.precedentId, p.name));
            } catch (e) {
                console.error(e);
            }
        } else {
            if (userState.isUserTimerRunning) {
                dispatch(setUserTimerRunning(false));
            }

            insertPrecedentLink(editorRef.current, p.precedentId, p.name);
            dispatch(addReplyPrecedent(caseId, { ...p }));
            if (goToTab) onAddPrecedent();
            setEditorHtml(editorRef.current.getData());
        }
    };

    const captureEvidence = () => {
        insertEvidenceLink(editorRef.current, c.employees, c.caseSummary.caseGuid, configState.serverValues.ldmUrl);

        setEvidenceSelected(true);
    };

    const templateSelected = async (t) => {
        insertTemplateHtml(editorRef.current, t.htmlContent);
    };

    const handleFocus = () => {
        if (userState.isUserTimerRunning) dispatch(setUserTimerRunning(false));
    };

    const [recipient, setRecipient] = useState("");

    const handleAddCc = (id) => {
        dispatch(addReplyCc(caseId, id));
        setCcResults([]);
        setCcQuery("");
        setCcOpen(false);
    };

    const handleAddTo = (id) => {
        dispatch(addReplyContactNotKnown(caseId, userState.users[id].email));
        setToResults([]);
        setToQuery("");
        setToOpen(false);
    };

    const handleAddBcc = (id) => {
        dispatch(addReplyBcc(caseId, id));
        setBccResults([]);
        setBccQuery("");
        setBccOpen(false);
    };

    useEffect(() => {
        if (!ccQuery) {
            setCcOpen(false);
            setCcResults([]);
            return;
        }
        let query = ccQuery.trim().toLowerCase();

        if (query.endsWith(";")) {
            //need to establish if the string is a valid email address
            query = query.slice(0, -1);
            if (emailIsValid(query)) {
                dispatch(addReplyNonContactCc(caseId, query));
                //once the chip has been added for the oneoff email address then reset the query string
                setCcQuery("");
            }
        }

        let results = [];

        Object.values(userState.users).forEach((u) => {
            if (u.isActive && !u.isDisabled && (u.name.toLowerCase().includes(query) || u.email.toLowerCase().includes(query))) results.push(u);
        });

        setCcResults(results);
        setCcOpen(true);
    }, [ccQuery]);

    useEffect(() => {
        if (!toQuery) {
            setToOpen(false);
            setToResults([]);
            return;
        }
        let query = toQuery.trim().toLowerCase();

        if (query.endsWith(";")) {
            //need to establish if the string is a valid email address
            query = query.slice(0, -1);
            if (emailIsValid(query)) {
                dispatch(addReplyContactNotKnown(caseId, query));
                //once the chip has been added for the oneoff email address then reset the query string
                setToQuery("");
            }
        }

        let results = [];

        Object.values(userState.users).forEach((u) => {
            if (u.isActive && !u.isDisabled && (u.name.toLowerCase().includes(query) || u.email.toLowerCase().includes(query))) results.push(u);
        });

        setToResults(results);
        setToOpen(true);
    }, [toQuery]);

    useEffect(() => {
        if (!bccQuery) {
            setBccOpen(false);
            setBccResults([]);
            return;
        }
        let query = bccQuery.trim().toLowerCase();

        if (query.endsWith(";")) {
            query = query.slice(0, -1);
            if (emailIsValid(query)) {
                dispatch(addReplyNonContactBcc(caseId, query));
                setBccQuery("");
            }
        }

        let results = [];

        Object.values(userState.users).forEach((u) => {
            if (u.isActive && !u.isDisabled && (u.name.toLowerCase().includes(query) || u.email.toLowerCase().includes(query))) results.push(u);
        });

        setBccResults(results);
        setBccOpen(true);
    }, [bccQuery]);

    // Clear bcc query, cc query, results on case change
    useEffect(() => {
        setToQuery("");
        setToResults([]);
        setToOpen(false);
        setCcQuery("");
        setCcResults([]);
        setCcOpen(false);
        setBccQuery("");
        setBccResults([]);
        setBccOpen(false);
    }, [caseId]);

    const handleCloseAttachFileModal = () => {
        setAttachFileModalOpen(false);
    };

    const handleDeleteAttachment = async ({ attachment, attachmentType }) => {

        console.log({ attachment, attachmentType });

        switch (attachmentType) {
            case attachmentTypes.CASE:
                dispatch(
                    setCaseAttachmentsSelected(
                        caseAttachmentsSelected.filter((a) => a.azureFileId !== attachment.azureFileId),
                        caseId
                    )
                );
                break;

            case attachmentTypes.UPLOAD:
                if (attachment.azureFileId) {

                    let draftEmailId = c.draftEmail?.id;
                    if (draftEmailId) {
                        try {
                            dispatch({ type: types.SAVE_DRAFT_REQUEST });
                            await emailDraftService.removeDraftAttachment(attachment.azureFileId, draftEmailId);
                            dispatch({ type: types.SAVE_DRAFT_SUCCESS });
                        } catch (e) {
                            console.error(e);
                            dispatch(setSnackAction("Could not remove attachment from draft email.", "error"));
                            return;
                        }
                    }                    
                }

                else {
                    try {
                        await storageService.deleteBlob(attachment.id);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }

                dispatch(
                    setReplyAttachmentsAction(
                        caseId,
                        c.reply.attachments.filter((a) => a.id !== attachment.id)
                    )
                );

                dispatch(removeReplyAttachment(caseId, attachment.file.name));
                break;

            case attachmentTypes.DRAFT:
                dispatch(
                    setDraftAttachmentsSelected(
                        draftAttachmentsSelected.filter((a) => a.id !== attachment.id),
                        caseId
                    )
                );
                break;

            default:
                break;
        }
    };

    const handleAttachmentClick = async (file) => {
        
        if (!!file?.azureFileId) {
            if (file?.azureFileMetadata) {
                return await handleAzureFilePreview({
                    documentName: file.customName || file.name,
                    reference: file.azureFileMetadata.azureFileReference,
                    contentType: file.azureFileMetadata.contentType,
                    extension: file.extension,
                });
            }
            else {
                return await handleAzureFilePreview({
                    documentName: file.file.name,
                    reference: file.id,
                    contentType: file.file.type,
                    extension: getFileExtension(file.file.name).replace(".", "").toUpperCase(),
                });
            }
        } else if (file?.sharepointFileName) {
            try {
                let { data: info } = await sharepointService.getAccessToSharePointDocument(file);

                if (info.link) {
                    window.open(info.link, "_blank");
                }

                if (info.error == "DELETED") {
                    dispatch(setSnackAction("File has been deleted from sharepoint.", "error"));
                }
            } catch (e) {
                console.error(e);
            }
        }        
        else {
            try {
                return await handleAzureFilePreview({
                    documentName: file.file.name,
                    reference: file.id,
                    contentType: file.file.type,
                    extension: getFileExtension(file.file.name).replace(".", "").toUpperCase(),
                });                
            } catch (e) {
                console.error(e);
            }
        }
    };

    const handleAzureFilePreview = async ({ documentName, reference, contentType, extension }) => {

        const attachment = {
            reference,
            filename: documentName,
            contentType
        };

        console.log({attachment});

        try {
            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference,
                contentType,
                filename: documentName
            });

            if (previewPath === null) {
                setPasswordProtectedFile(attachment);
                return;
            }

            dispatch(
                openFileViewer(
                    {
                        type: extension,
                        path: previewPath,
                        name: documentName,
                    },
                    caseId,
                    attachment,
                    true,
                    false
                )
            );
        } catch (e) {
            setPasswordProtectedFile(attachment);
            console.error(e);
        }
    };

    const FileChip = ({ file, attachmentType }) => {
        return (
            <Box m={1}>
                <Chip
                    className={classes.attachmentChip}
                    avatar={
                        <Avatar>
                            <AttachFileIcon />
                        </Avatar>
                    }
                    label={
                        <Typography variant="body2">
                            {(file.file ? file.file.customName ? file.file.customName + "." + file.file.extension : file.file.name : file.customName ? file.customName + "." + file.extension : file.name) +
                                (file.size ? " " + filesize(file.size, { round: 0 }) : "")}
                        </Typography>
                    }
                    onClick={() => handleAttachmentClick(file)}
                    onDelete={() => handleDeleteAttachment({attachment: file, attachmentType })
                    }
                />
            </Box>
        );
    };

    function lastModified() {
        if (!lastModifiedDate) return null

        return (
            <Typography className={classes.draftSavedInfo}>
                Draft last saved on {format(new Date(lastModifiedDate), "dd/MM/yyyy 'at' HH:mm")}
                {" by "}
                {lastModifiedBy ?? 'You'}
            </Typography>
        );
    }

    const buttonsDisabled = useMemo(() => {
        return removingDraft === requestStatus.PENDING || sending || savingDraft;
    }, [removingDraft, sending, savingDraft]);   

    const handleCloseDraftRemovalModal = async (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDraftRemovalModalOpen(false);
    };

    return (
        <>
            <Grid container className={classes.replyContainer} spacing={6}>
                <Fab
                    color="secondary"
                    aria-label="ShowPrecedents"
                    className={classes.fabButton}
                    onClick={() => setShowPrecedents((prevCheck) => !prevCheck)}
                    variant="extended"
                >
                    Precedents & Templates
                </Fab>
                <Grid item xs={12} lg={showPrecedents ? 8 : 12}>
                    <div className={classes.rightAligned}>
                        {draftEmailsSummary.data?.data && draftEmailsSummary.data.data.length > 0 && <EmailDrafts />}
                    </div>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="center">
                            <Typography>From:</Typography>
                            <Chip
                                className={classes.chip}
                                avatar={<Avatar alt={from} />}
                                label={from}
                                onClick={!partnershipEmailSettings.isConfigured ? null : () => setUsingPartnershipEmailSettings(!usingPartnershipEmailSettings)}
                            />
                        </Box>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="flex-start">
                            <Box mt={1}>
                                <Typography>To:</Typography>
                            </Box>
                            <Box flexGrow={1} display="flex" flexWrap="wrap" alignItems="center">
                                {c.reply.contacts.map((c, index) => (
                                    <Box mb={1} key={index}>
                                        <Chip
                                            className={classes.chip}
                                            avatar={<Avatar alt={c.name} src={c.photo} />}
                                            label={c.name + " - " + c.emails[c.toIndex]}
                                            onDelete={() =>
                                                c.contactId ? handleRemoveContact(c.contactId) : handleRemoveExternalContact(c.externalContactId)
                                            }
                                            onClick={c.emails.length === 1 ? null : () => nextTo(c.contactId)}
                                        />
                                    </Box>
                                ))}
                                {c.reply.contactsNotKnown &&
                                    c.reply.contactsNotKnown.map((cn) => (
                                        <Chip
                                            key={cn}
                                            className={classes.chip}
                                            avatar={<Avatar alt={cn} />}
                                            label={cn}
                                            onDelete={() => dispatch(removeReplyContactNotKnown(caseId, cn))}
                                        />
                                    ))}
                                <TextField
                                    className={classes.textField}
                                    InputProps={{ disableUnderline: true }}
                                    value={toQuery}
                                    onChange={(e) => setToQuery(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <Button className={classes.addContactButton} onClick={(e) => setAddContactEl(e.currentTarget)}>
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>
                        {toOpen ? (
                            <MenuList className={classes.menuList}>
                                {toResults.length ? (
                                    toResults.map((u) => (
                                        <MenuItem key={u.userId} value={u.userId} onClick={() => handleAddTo(u.userId)}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar className={clsx(avatarClasses.root, avatarClasses.small)} alt={u.name} src={u.photo} />
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">{u.name}</Typography>
                                                    <Typography variant="caption">{u.email}</Typography>
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>No matching advisers</MenuItem>
                                )}
                            </MenuList>
                        ) : null}
                        <Menu
                            className={classes.addContactMenu}
                            anchorEl={addContactEl}
                            keepMounted
                            open={Boolean(addContactEl)}
                            onClose={() => setAddContactEl(null)}
                        >
                            <ListSubheader>
                                <Typography variant="body2">
                                    <strong>Select the recipient(s) below to add the outbound Email.</strong>
                                </Typography>
                            </ListSubheader>

                            <MenuItem>
                                <Input
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                    type="text"
                                    onKeyDown={(e) => e.stopPropagation()}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchFilterIcon />
                                        </InputAdornment>
                                    }
                                />
                            </MenuItem>

                            {accountContacts
                                .filter(
                                    (ac) =>
                                        !c.reply.contacts.map((c) => c.contactId).includes(ac.contactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddContact(ac)} key={ac.contactId}>
                                        <Avatar alt={ac.name} src={ac.photo} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            {ac.name} - {ac.position}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            {isExternalContactsLoading && <CircularProgress />}
                            {isExternalContactsError && <Typography>{externalContactsError?.message || "Failed to load ExternalContacts"}</Typography>}
                            {externalContacts
                                ?.filter(
                                    (ac) =>
                                        !c.reply.contacts.map((c) => c.externalContactId).includes(ac.externalContactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddContact(ac)} key={ac.externalContactId}>
                                        <Avatar alt={ac.name} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            [Ext] {ac.name} - {ac.organisation}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="flex-start">
                            <Box mt={1}>
                                <Typography>Cc:</Typography>
                            </Box>
                            <Box flexGrow={1} display="flex" flexWrap="wrap" alignItems="center">
                                {c.reply.ccContacts.map((c, index) => (
                                    <Box mb={1} key={index}>
                                        <Chip
                                            className={classes.chip}
                                            avatar={<Avatar alt={c.name} src={c.photo} />}
                                            label={c.name + " - " + c.emails[c.toIndex]}
                                            onDelete={() =>
                                                c.contactId ? handleRemoveCcContact(c.contactId) : handleRemoveExternalCcContact(c.externalContactId)
                                            }
                                            onClick={c.emails.length === 1 ? null : () => nextTo(c.contactId)}
                                        />
                                    </Box>
                                ))}
                                {c.reply?.inboundEmailCCContacts &&
                                    c.reply.inboundEmailCCContacts.map((ccc) => (
                                        <Box mb={1} key={ccc.externalId}>
                                            <Chip
                                                className={classes.chip}
                                                avatar={<Avatar alt={ccc.name} />}
                                                label={ccc.name}
                                                onDelete={() => dispatch(removeReplyCc(caseId, ccc.contactId))}
                                            />
                                        </Box>
                                    ))}
                                {c.reply.inboundEmailCCNonContacts &&
                                    c.reply.inboundEmailCCNonContacts.map((ccn) => (
                                        <Box mb={1} key={ccn}>
                                            <Chip
                                                className={classes.chip}
                                                avatar={<Avatar alt={ccn} />}
                                                label={ccn}
                                                onDelete={() => dispatch(removeReplyCCContactNotKnown(caseId, ccn))}
                                            />
                                        </Box>
                                    ))}
                                {c.reply.ccUserIds &&
                                    c.reply.ccUserIds.map((id) => (
                                        <Chip
                                            className={classes.chip}
                                            avatar={<Avatar alt={userState.users[id].name} src={userState.users[id].photo} />}
                                            label={userState.users[id].name}
                                            onDelete={() => dispatch(removeReplyIntCc(caseId, id))}
                                        />
                                    ))}

                                <TextField
                                    className={classes.textField}
                                    InputProps={{ disableUnderline: true }}
                                    value={ccQuery}
                                    onChange={(e) => setCcQuery(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <Button className={classes.addContactButton} onClick={(e) => setAddCcContactEl(e.currentTarget)}>
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>
                        {ccOpen ? (
                            <MenuList className={classes.menuList}>
                                {ccResults.length ? (
                                    ccResults.map((u) => (
                                        <MenuItem key={u.userId} value={u.userId} onClick={() => handleAddCc(u.userId)}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar className={clsx(avatarClasses.root, avatarClasses.small)} alt={u.name} src={u.photo} />
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">{u.name}</Typography>
                                                    <Typography variant="caption">{u.email}</Typography>
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>No matching advisers</MenuItem>
                                )}
                            </MenuList>
                        ) : null}
                        <Menu
                            className={classes.addContactMenu}
                            anchorEl={addCcContactEl}
                            keepMounted
                            open={Boolean(addCcContactEl)}
                            onClose={() => setAddCcContactEl(null)}
                        >
                            <ListSubheader>
                                <Typography variant="body2">
                                    <strong>Select the recipient(s) below to cc into the outbound Email.</strong>
                                </Typography>
                            </ListSubheader>

                            <MenuItem>
                                <Input
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                    type="text"
                                    onKeyDown={(e) => e.stopPropagation()}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchFilterIcon />
                                        </InputAdornment>
                                    }
                                />
                            </MenuItem>

                            {accountContacts
                                .filter(
                                    (ac) =>
                                        !c.reply.ccContacts.map((c) => c.contactId).includes(ac.contactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddCcContact(ac)} key={ac.contactId}>
                                        <Avatar alt={ac.name} src={ac.photo} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            {ac.name} - {ac.position}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            {isExternalContactsLoading && <CircularProgress />}
                            {isExternalContactsError && <Typography>{externalContactsError?.message || "Failed to load ExternalContacts"}</Typography>}
                            {externalContacts
                                ?.filter(
                                    (ac) =>
                                        !c.reply.ccContacts.map((c) => c.externalContactId).includes(ac.externalContactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddCcContact(ac)} key={ac.externalContactId}>
                                        <Avatar alt={ac.name} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            [Ext] {ac.name} - {ac.organisation}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="flex-start">
                            <Box mt={1}>
                                <Typography>Bcc:</Typography>
                            </Box>
                            <Box flexGrow={1} display="flex" flexWrap="wrap" alignItems="center">
                                {c.reply.bccContacts.map((c, index) => (
                                    <Box mb={1} key={index}>
                                        <Chip
                                            className={classes.chip}
                                            avatar={<Avatar alt={c.name} src={c.photo} />}
                                            label={c.name + " - " + c.emails[c.toIndex]}
                                            onDelete={() =>
                                                c.contactId ? handleRemoveBccContact(c.contactId) : handleRemoveExternalBccContact(c.externalContactId)
                                            }
                                            onClick={c.emails.length === 1 ? null : () => nextTo(c.contactId)}
                                        />
                                    </Box>
                                ))}
                                {c.reply.inboundEmailBCCNonContacts &&
                                    c.reply.inboundEmailBCCNonContacts.map((bccn) => (
                                        <Box mb={1} key={bccn}>
                                            <Chip
                                                className={classes.chip}
                                                avatar={<Avatar alt={bccn} />}
                                                label={bccn}
                                                onDelete={() => dispatch(removeReplyNonContactBcc(caseId, bccn))}
                                            />
                                        </Box>
                                    ))}
                                {c.reply.bccUserIds &&
                                    c.reply.bccUserIds.map((id) => (
                                        <Chip
                                            className={classes.chip}
                                            avatar={<Avatar alt={userState.users[id].name} src={userState.users[id].photo} />}
                                            label={userState.users[id].name}
                                            onDelete={() => dispatch(removeReplyBcc(caseId, id))}
                                        />
                                    ))}
                                <TextField
                                    className={classes.textField}
                                    InputProps={{ disableUnderline: true }}
                                    value={bccQuery}
                                    onChange={(e) => setBccQuery(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <Button className={classes.addContactButton} onClick={(e) => setAddBccContactEl(e.currentTarget)}>
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>

                        {bccOpen ? (
                            <MenuList className={classes.menuList}>
                                {bccResults.length ? (
                                    bccResults.map((u) => (
                                        <MenuItem key={u.userId} value={u.userId} onClick={() => handleAddBcc(u.userId)}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar className={clsx(avatarClasses.root, avatarClasses.small)} alt={u.name} src={u.photo} />
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">{u.name}</Typography>
                                                    <Typography variant="caption">{u.email}</Typography>
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>No matching advisers</MenuItem>
                                )}
                            </MenuList>
                        ) : null}
                        <Menu
                            className={classes.addContactMenu}
                            anchorEl={addBccContactEl}
                            keepMounted
                            open={Boolean(addBccContactEl)}
                            onClose={() => setAddBccContactEl(null)}
                        >
                            <ListSubheader>
                                <Typography variant="body2">
                                    <strong>Select the recipient(s) below to bcc into the outbound Email.</strong>
                                </Typography>
                            </ListSubheader>

                            <MenuItem>
                                <Input
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                    type="text"
                                    onKeyDown={(e) => e.stopPropagation()}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchFilterIcon />
                                        </InputAdornment>
                                    }
                                />
                            </MenuItem>

                            {accountContacts
                                .filter(
                                    (ac) =>
                                        !c.reply.bccContacts.map((c) => c.contactId).includes(ac.contactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddBccContact(ac)} key={ac.contactId}>
                                        <Avatar alt={ac.name} src={ac.photo} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            {ac.name} - {ac.position}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            {isExternalContactsLoading && <CircularProgress />}
                            {isExternalContactsError && <Typography>{externalContactsError?.message || "Failed to load ExternalContacts"}</Typography>}
                            {externalContacts
                                ?.filter(
                                    (ac) =>
                                        !c.reply.bccContacts.map((c) => c.externalContactId).includes(ac.externalContactId) &&
                                        ac.name.toLowerCase().includes(recipient.toLowerCase())
                                )
                                .map((ac) => (
                                    <MenuItem onClick={() => handleAddBccContact(ac)} key={ac.externalContactId}>
                                        <Avatar alt={ac.name} className={avatarClasses.root} />
                                        <Typography variant="body2">
                                            [Ext] {ac.name} - {ac.organisation}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2">Subject:</Typography>
                            <TextField
                                className={classes.textField}
                                InputProps={{
                                    disableUnderline: true,
                                    style: { fontWeight: "bold" },
                                }}
                                value={c.reply.subject}
                                onChange={(e) => dispatch(setReplySubjectAction(e.target.value))}
                            />
                        </Box>
                    </Paper>
                    {c.reply.precedents && c.reply.precedents.length > 0 && (
                        <Paper className={classes.paper}>
                            <Box display="flex" alignItems="flex-start">
                                <Box mt={1}>
                                    <Typography>Attached Precedents</Typography>
                                </Box>
                                <Box flexGrow={1} display="flex" flexWrap="wrap">
                                    {c.reply.precedents.map((p) => (
                                        <Box mb={1} key={p.precedentId}>
                                            <Chip
                                                className={classes.chip}
                                                color="primary"
                                                avatar={
                                                    <Avatar>
                                                        <FileTextIcon />
                                                    </Avatar>
                                                }
                                                label={p.name}
                                                onDelete={() => handleRemovePrecedent(p)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Paper>
                    )}
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="center">
                            <Box mt={1}>
                                <Typography>Attachments: </Typography>
                            </Box>
                            <Box flexGrow={1} display="flex" flexWrap="wrap">
                                {attachments?.map((a) => (
                                    <FileChip key={a.id} file={a} attachmentType={attachmentTypes.UPLOAD} />
                                ))}
                                {draftAttachmentsSelected.map((a) => (
                                    <FileChip key={a.id} file={a} attachmentType={attachmentTypes.DRAFT} />
                                ))}
                                {caseAttachmentsSelected.map((a) => (
                                    <FileChip key={a.azureFileId} file={a} attachmentType={attachmentTypes.CASE} />
                                ))}
                            </Box>
                        </Box>
                    </Paper>
                    <Box display="flex" justifyContent="space-between" my={3}>
                        <Box>
                            <Button
                                disabled={evidenceSelected}
                                onClick={() => captureEvidence()}
                                className={classes.captureEvidenceButton}
                                variant="contained"
                            >
                                Capture Evidence
                            </Button>
                            <Button onClick={() => setAttachFileModalOpen(true)} className={classes.attachButton} variant="contained">
                                Attach Files
                                <Paperclip className={classes.attachIcon} />
                            </Button>
                            <Button onClick={handleOpenCaseResearch} variant="contained">
                                Research
                            </Button>
                        </Box>
                        <Box>
                            <Box display="flex" justifyContent="flex-end">
                                {
                                    draftId &&
                                    <Button
                                        className={classes.editorActionButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleRemoveDraftClick}
                                        disabled={buttonsDisabled}
                                    >
                                        {removingDraft === requestStatus.PENDING ? "Removing Draft" : "Remove Draft"}
                                    </Button>
                                }
                                <Button
                                    className={classes.editorActionButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleSaveDraftClick}
                                    disabled={buttonsDisabled}
                                >
                                    {savingDraft ? "Saving Draft" : "Save Draft"}
                                </Button>
                                {
                                    c.reply.replyingTo &&
                                    <Button
                                        className={classes.editorActionButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={editReplyChain}
                                        disabled={buttonsDisabled || c.reply.editingReplyChain}
                                    >
                                        Edit Reply Chain
                                    </Button>
                                }
                                <Button variant="contained" color="primary" onClick={sendReply} disabled={buttonsDisabled}>
                                    {sending ? "Sending" : "Send"}
                                </Button>
                            </Box>
                            {draftId && lastModified()}
                        </Box>
                    </Box>
                    <Paper className={classes.paper}>
                        <Box display="flex" alignItems="flex-start">
                            <Box mt={1}>
                                <Typography>Description:</Typography>
                            </Box>
                            <Box flexGrow={1} display="flex" flexWrap="wrap" alignItems="center">
                                <TextField
                                    className={classes.textField}
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{
                                        maxLength: MAX_ACTIVITY_DESCRIPTION,
                                    }}
                                    value={c.reply.description}
                                    onChange={(e) => dispatch(setReplyDescription(e.target.value))}
                                />
                            </Box>
                        </Box>
                    </Paper>
                    <Box width="100%" my={3}>
                        {isAiGenerated && (
                            <StarRater rating={starRating} caseId={caseId} />
                        )}
                    </Box>
                    {showEditor && (
                        <MyCKEditor initialData={editorHtml} editor={editorRef.current} setEditor={editor => editorRef.current = editor} onChange={setEditorHtml} onFocus={handleFocus} type="email" disabled={isAiGenerated && starRating === null} />
                    )}
                    {c.reply.replyingTo && !c.reply.editingReplyChain && (
                        <Paper className={classes.replyingToWrapper}>
                            <hr />
                            <div
                                className={classes.replyingToContent}
                                dangerouslySetInnerHTML={{
                                    __html: getReplyChainContent(),
                                }}
                            ></div>
                        </Paper>
                    )}
                </Grid>
                {
                    showPrecedents && (
                        <Grid item xs={12} lg={4}>
                            <PrecedentChooser isEmailReply onSelect={precedentSelected} />
                            <TemplateChooser onSelect={templateSelected} />
                        </Grid>
                    )}
                <AttachFileModal
                    emailDraftId={c.draftEmail?.id}
                    open={attachFileModalOpen}
                    closeAttachFileModal={handleCloseAttachFileModal}
                    caseId={c.caseSummary.caseId}
                    caseAttachmentsSelected={caseAttachmentsSelected}
                    draftAttachmentsSelected={draftAttachmentsSelected}
                    attachments={attachments.filter(x => !x.precedentId)}
                    handleDeleteAttachment={handleDeleteAttachment}
                    precedentAttachments={attachments.filter(x => x.precedentId)}
                />
                <Dialog open={draftRemovalModalOpen} onClose={handleCloseDraftRemovalModal}>
                    <DialogTitle>Remove current draft?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Please confirm that you wish to remove the current draft email.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => setDraftRemovalModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button color="primary" variant="outlined" onClick={removeDraft}>
                            Remove existing draft
                        </Button>
                    </DialogActions>
                </Dialog>
                <CaseTimeEventGroupsDialog open={heldTimeOpen} onClose={() => setHeldTimeOpen(false)} onConfirm={sendReply} confirmLabel="Send" />
            </Grid>
            <PasswordProtectedFileDialog
                open={!!passwordProtectedFile}
                onClose={() => setPasswordProtectedFile(null)}
                fileName={passwordProtectedFile?.customName || passwordProtectedFile?.title}
                caseString={" - Case " + caseId}
                attachment={passwordProtectedFile}
                caseId={caseId}
            />
        </>
    );
};

export default ComposeReply;