import React, { useMemo, useState } from "react";
import { Grow, ListItemIcon, ListItemText, ListSubheader, makeStyles, Menu, MenuItem, MenuList, CircularProgress } from "@material-ui/core";
import { Edit as EditIcon, Undo as ReplyIcon, FastForward as ReAssignIcon, Redo as ForwardIcon, WorkOutline, AccessTime } from "@material-ui/icons";

import caseActivityTypes from "../constants/caseActivityTypes";
import caseService from "../services/caseService";
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrentMiscActivityOpen,
    setExistingMiscActivityFiles,
    setInternalNoteDescription,
    setMiscActivityDate,
    setMiscActivityDescription,
    setMiscActivityHours,
    setMiscActivityMinutes,
    setMiscActivityNotes,
    setMiscActivityOpen,
    setMiscActivityStartTime,
    setMiscActivityTab,
    setMiscActivityTypeId,
    setMiscCaseActivityId,
    updateCaseChargeableTime,
    updateCaseEventChargeable,
    setCallNoteCaseActivityId,
    setCallNoteDescription,
    setCallNoteMode,
    setCallNoteOpen,
    setCallNoteText,
    setInternalNoteCaseActivityId,
    setInternalNoteOpen,
    setInternalNoteText,
    resetCaseTab,
    reassignCaseActivity,
    setAdjustCaseMinutes,
    setCaseActivityDuration,
} from "../redux/actions/caseActions";
import { miscActivityGroups } from "../constants/miscActivityGroupConstants";
import ChargeableIcon from "./ChargeableIcon";
import callNoteModes from "../constants/callNoteModes";
import ReassignActivityDialog from "./dialogs/ReassignActivityDialog";
import { setSnackAction } from "../redux/actions/snackActions";
import AdjustActivityTimeDialog from "./dialogs/AdjustActivityTimeDialog";
import caseActivityService from "../services/caseActivityService";
import queryKeys from "../constants/queryKeys";
import reactQueryClient from "../reactQueryClient";
import { MiscActivityCategory } from "../constants/miscActivityCategory";

const useStyles = makeStyles(() => ({
    loggedText: {
        fontSize: 12,
    },
}));

const { CALL, NOTE, MISC, INTERNAL_NOTE, EMAIL } = caseActivityTypes;

const CaseActivityMenu = ({
    caseId,
    contextEvent,
    contextAnchorEl,
    caseListOpen,
    handleCloseContextMenu,
    handleCaseListOpen,
    onReply,
    onForward,
    handleEditDescription,
    replyLoading,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const userState = useSelector((state) => state.userReducer);
    const { cases } = useSelector((state) => state.caseReducer);
    const c = cases[caseId];
    const caseIsChargeable = c.caseSummary.isChargeable;
    const contractId = c?.caseSummary?.contractId;

    // confirmation dialog
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [newCaseId, setNewCaseId] = useState(null);
    const [reassigning, setReassigning] = useState(false);

    // adjust activity time dialog
    const [adjustOpen, setAdjustOpen] = useState(false);
    const [adjustEvent, setAdjustEvent] = useState(null);
    const [adjustMinutes, setAdjustMinutes] = useState(0);
    const [adjustHours, setAdjustHours] = useState(0);
    const [dialogStep, setDialogStep] = useState(0);
    const [adjustSaving, setAdjustSaving] = useState(false);

    const caseList = Object.keys(cases).filter((c) => c != caseId);

    const handleSetCaseActivityChargeable = async (caseActivityId, activityIsChargeable) => {
        try {
            const { data } = await caseService.makeCaseActivityChargeable({
                caseActivityId,
                isChargeable: !activityIsChargeable,
            });

            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.caseActivities, caseId, 10]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);

            dispatch(
                updateCaseEventChargeable({
                    caseId,
                    caseActivityId: data.id,
                    isChargeable: data.isChargeable,
                })
            );
            dispatch(
                updateCaseChargeableTime({
                    caseId,
                    delta: data.isChargeable ? data.duration : -data.duration,
                })
            );

            handleCloseContextMenu();
        } catch (e) {
            console.error(e);
        }
    };

    const handleEditMiscActivity = (a) => {
        handleCloseContextMenu();
        dispatch(setMiscActivityOpen(true, caseId));
        dispatch(setMiscActivityTypeId(a.miscActivityTypeId, caseId, a.miscActivityGroup));
        dispatch(setMiscActivityDescription(a.activityDescription, caseId, a.miscActivityGroup));
        dispatch(setMiscCaseActivityId(a.caseActivityId, caseId, a.miscActivityGroup));
        dispatch(setExistingMiscActivityFiles(a.attachments, caseId, a.miscActivityGroup));
        dispatch(setMiscActivityNotes(a.content, caseId, a.miscActivityGroup));

        if (a.miscActivityGroup === miscActivityGroups.RETROSPECTIVE || a.miscActivityGroup === miscActivityGroups.DRAFTING) {
            dispatch(setMiscActivityDate(a.eventTime, caseId));
            dispatch(setMiscActivityStartTime(a.eventTime, caseId));
            const minutes = Math.floor((a.duration / 60) % 60);
            const hours = Math.floor((a.duration / (60 * 60)) % 24);
            dispatch(setMiscActivityMinutes(minutes, caseId));
            dispatch(setMiscActivityHours(hours, caseId));
            dispatch(
                setMiscActivityTab({
                    caseId,
                    tab: miscActivityGroups.RETROSPECTIVE,
                })
            );
        } else {
            dispatch(setCurrentMiscActivityOpen({ isOpen: true }));
            dispatch(setMiscActivityTab({ caseId, tab: miscActivityGroups.CURRENT }));
        }

        dispatch(resetCaseTab(caseId));
    };

    const handleReassignActivity = async () => {
        setReassigning(true);
        const response = await caseActivityService.reassignCaseActivity(newCaseId, caseId, contextEvent.caseActivityId, userState.userProfile.userId);

        if (response.status === 200) {
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.caseActivities, caseId, 10]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
            dispatch(reassignCaseActivity(contextEvent.caseActivityId, caseId, newCaseId));
            if (response.data) {
                dispatch(setAdjustCaseMinutes(caseId, (-1 * response.data) / 60));
                dispatch(setAdjustCaseMinutes(newCaseId, response.data / 60));
            }

            dispatch(setSnackAction("Successfully reassigned activity!", "success"));
        } else dispatch(setSnackAction("Something went wrong!", "error"));

        handleCloseContextMenu();
        handleCloseConfirmationDialog();
        setReassigning(false);
    };

    const handleOpenConfirmationDialog = (c) => {
        setNewCaseId(c);
        setConfirmOpen(true);
    };

    const handleCloseConfirmationDialog = () => {
        setNewCaseId(null);
        setConfirmOpen(false);
    };

    const handleClickAdjustEvent = (event) => {
        setAdjustEvent(event);

        const seconds = event.duration;

        setAdjustHours(Math.floor(seconds / 3600));
        setAdjustMinutes(Math.round((seconds % 3600) / 60));

        setAdjustOpen(true);
    };

    const handleCloseAdjustDialog = () => {
        setAdjustOpen(false);
        setDialogStep(0);
        setAdjustMinutes(0);
        setAdjustHours(0);
        setAdjustEvent(null);
    };

    const handleClickConfirmAdjust = async () => {
        if (adjustMinutes < 0 || adjustHours < 0) {
            dispatch(setSnackAction("Adjusted time can't be negative", "error"));
            return;
        }

        if (dialogStep === 0) {
            setDialogStep(1);
            return;
        }

        setAdjustSaving(true);
        const newDurationSeconds = adjustHours * 3600 + adjustMinutes * 60;
        const deltaSeconds = newDurationSeconds - adjustEvent.duration;

        const response = await caseActivityService.adjustCaseActivityDuration(
            adjustEvent.caseActivityId,
            userState.userProfile.userId,
            newDurationSeconds,
            caseId
        );

        if (response.status === 200) {
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.caseActivities, caseId, 10]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, contractId]);
            dispatch(setCaseActivityDuration(newDurationSeconds, caseId, adjustEvent.caseActivityId));
            setAdjustCaseMinutes(caseId, deltaSeconds / 60);

            if (adjustEvent.isChargeable) {
                dispatch(updateCaseChargeableTime({ caseId, delta: deltaSeconds }));
            }

            dispatch(setSnackAction("Successfully adjusted duration", "success"));
        }

        setAdjustSaving(false);
        handleCloseAdjustDialog();
        handleCloseContextMenu();
    };

    const handleEditCallNote = async (note) => {
        handleCloseContextMenu();
        dispatch(
            setCallNoteText(
                {
                    issue: note.issue,
                    advice: note.advice,
                    action: note.action,
                },
                caseId
            )
        );
        dispatch(setCallNoteMode(callNoteModes.NOTE, caseId));
        dispatch(setCallNoteOpen(true, caseId));
        dispatch(setCallNoteCaseActivityId(note.caseActivityId, caseId));
        dispatch(setCallNoteDescription(note.activityDescription));
        dispatch(resetCaseTab(caseId));
    };

    const handleEditInternalNote = async (note) => {
        handleCloseContextMenu();
        dispatch(
            setInternalNoteDescription({
                description: note.activityDescription,
                caseId,
            })
        );
        dispatch(setInternalNoteText(note.text, caseId));
        dispatch(setInternalNoteOpen(true, caseId));
        dispatch(setInternalNoteCaseActivityId(note.caseActivityId, caseId));
        dispatch(resetCaseTab(caseId));
    };

    return (
        <>
            <Menu anchorEl={contextAnchorEl} keepMounted open={Boolean(contextAnchorEl)} onClose={handleCloseContextMenu}>
                {contextEvent && (contextEvent.itemType === EMAIL || contextEvent.itemType === CALL) && (
                    <MenuItem onClick={() => handleEditDescription(contextEvent)}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>
                )}
                {contextEvent &&
                    ((contextEvent.itemType === EMAIL && contextEvent.direction === 0) ||
                        contextEvent.miscActivityCategory === MiscActivityCategory.SeekAdvice) && (
                        <MenuItem onClick={() => onReply(contextEvent, false)}>
                            <ListItemIcon>
                                <ReplyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Reply" />
                        </MenuItem>
                    )}
                {contextEvent && contextEvent.itemType === EMAIL && contextEvent.direction === 0 && (
                    <MenuItem onClick={() => onReply(contextEvent, true)}>
                        <ListItemIcon>
                            <ReplyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reply All" />
                    </MenuItem>
                )}
                {contextEvent && contextEvent.itemType === EMAIL && (
                    <MenuItem onClick={() => onForward(contextEvent)}>
                        <ListItemIcon>
                            <ForwardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Forward" />
                    </MenuItem>
                )}
                {contextEvent && contextEvent.itemType === NOTE && !contextEvent.isLocked && (
                    <MenuItem onClick={() => handleEditCallNote(contextEvent)}>
                        {" "}
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>
                )}
                {contextEvent && contextEvent.itemType === INTERNAL_NOTE && !contextEvent.isLocked && (
                    <MenuItem onClick={() => handleEditInternalNote(contextEvent)}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>
                )}
                {contextEvent &&
                    contextEvent.itemType === MISC &&
                    contextEvent.miscActivityCategory !== MiscActivityCategory.SeekAdvice &&
                    !contextEvent.isLocked && (
                        <MenuItem onClick={() => handleEditMiscActivity(contextEvent)}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>
                    )}
                {contextEvent &&
                    contextEvent.itemType !== CALL &&
                    contextEvent.miscActivityCategory !== MiscActivityCategory.SeekAdvice &&
                    contextEvent.direction !== 0 && (
                        <MenuItem onClick={() => handleClickAdjustEvent(contextEvent)}>
                            <ListItemIcon>
                                <AccessTime />
                            </ListItemIcon>
                            <ListItemText primary="Adjust activity time" />
                        </MenuItem>
                    )}
                <MenuItem onClick={() => handleCaseListOpen(true)} data-cy="reassign_activity">
                    <ListItemIcon>
                        <ReAssignIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reassign activity" />
                </MenuItem>
                {(caseIsChargeable || contextEvent?.isChargeable) && (
                    <MenuItem onClick={() => handleSetCaseActivityChargeable(contextEvent?.caseActivityId, contextEvent?.isChargeable)}>
                        <ListItemIcon>
                            <ChargeableIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Make Activity ${contextEvent?.isChargeable ? "Not Chargeable" : "Chargeable"}`} />
                    </MenuItem>
                )}
                {caseListOpen && (
                    <Grow in={caseListOpen}>
                        <MenuList className={classes.noPad}>
                            {reassigning ? (
                                <CircularProgress />
                            ) : !caseList.length ? (
                                <MenuItem>Please open a case</MenuItem>
                            ) : (
                                caseList.map((c) => (
                                    <MenuItem onClick={() => handleOpenConfirmationDialog(c)} key={c} data-cy="reassign_case_option">
                                        <ListItemIcon>
                                            <WorkOutline />
                                        </ListItemIcon>
                                        <ListItemText primary={`Case ${c}`} />
                                    </MenuItem>
                                ))
                            )}
                        </MenuList>
                    </Grow>
                )}
                {contextEvent?.direction === 0 && contextEvent?.itemType === EMAIL && (
                    <ListSubheader className={classes.loggedText}>Logged by {userState.users[contextEvent.adviserId].name}</ListSubheader>
                )}
            </Menu>

            <ReassignActivityDialog
                confirmOpen={confirmOpen}
                handleConfirmOpen={setConfirmOpen}
                newCaseId={newCaseId}
                handleCloseConfirmationDialog={handleCloseConfirmationDialog}
                handleReassignActivity={handleReassignActivity}
            />
            <AdjustActivityTimeDialog
                adjustOpen={adjustOpen}
                adjustHours={adjustHours}
                adjustMinutes={adjustMinutes}
                adjustEvent={adjustEvent}
                adjustSaving={adjustSaving}
                dialogStep={dialogStep}
                handleAdjustHours={setAdjustHours}
                handleAdjustMinutes={setAdjustMinutes}
                handleCloseAdjustDialog={handleCloseAdjustDialog}
                handleClickConfirmAdjust={handleClickConfirmAdjust}
            />
        </>
    );
};

export default CaseActivityMenu;
