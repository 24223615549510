import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    Typography as MuiTypography,
    Box,
    IconButton as MuiIconButton,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    Tooltip,
    makeStyles,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { Timer, Replay, Info as MuiInfoIcon } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";

import {
    getCaseContracts,
    addCaseContract,
    setCurrentCaseContract,
} from "../services/caseContractService";
import { formatTimeDigital } from "../helpers/formatTime";
import { setSnackAction } from "../redux/actions/snackActions";
import {
    setCaseContract,
    setCaseTypeAndStage,
} from "../redux/actions/caseActions";
import { grey } from "@material-ui/core/colors";
import { setCaseType } from "../services/caseTypeService";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
const Typography = styled(MuiTypography)(spacing);

const InfoIcon = styled(MuiInfoIcon)`
    margin-top: -10px;
    font-size: 1em;
    cursor: pointer;
    color: ${grey[700]};
`;

const modes = {
    view: "VIEW",
    add: "ADD",
    edit: "EDIT",
};

const useStyles = makeStyles((theme) => ({
    spacing: {
        columnGap: theme.spacing(2),
    },
    minWidth: {
        minWidth: 120,
    },
    marginBottomRemoval: {
        marginBottom: 0,
    },
}));

const ContractDialog = ({
    open,
    handleClose,
    caseId,
    contractId,
    accountContracts,
    dateCreated,
    totalActivityTime,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const configState = useSelector((state) => state.configReducer);
    const { cases } = useSelector((state) => state.caseReducer);
    const [caseContracts, setCaseContracts] = useState([]);
    const [mode, setMode] = useState(modes.view);
    const [saving, setSaving] = useState(false);
    const [newContract, setNewContract] = useState({
        contractId: "",
        startDate: null,
        adviceTypeId: null,
    });
    const [newCaseTypeId, setNewCaseTypeId] = useState(null);
    const [selectedContractAdviceTypeId, setSelectedContractAdviceTypeId] =
        useState(null);
    const [caseTypeId, setCaseTypeId] = useState(
        cases[caseId].caseSummary.caseTypeId
    );
    const currentCaseContract = accountContracts.filter(
        (c) => c.contractId == contractId
    )[0];

    const caseContractIds = caseContracts.map((c) => c.contractId);

    const account = cases[caseId].account;

    const isNewContractUnitised = (newContractId = 0) =>
        accountContracts.find(
            (contract) => contract.contractId === newContractId
        )?.isUnitisedTime ?? false;

    const DisplayContract = ({ contract }) => {
        if (!contract)
            return (
                <Typography variant="subtitle2">Invalid contract</Typography>
            );

        const accountContract = accountContracts.filter(
            (c) => c.contractId == contract.contractId
        )[0];

        if (!accountContract)
            return (
                <Typography variant="subtitle2">Invalid contract</Typography>
            );

        return (
            <Box display="flex" alignItems="center" my={3}>
                <Typography variant="subtitle2" mr={2}>
                    {accountContract.coreService || "Not set"}
                    {accountContract.summary && (
                        <Tooltip title={accountContract.summary}>
                            <InfoIcon />
                        </Tooltip>
                    )}
                </Typography>
                <Typography variant="body1" mr={2}>
                    {moment(contract.startDate).format("DD-MM-YYYY") +
                        " - " +
                        (contract.endDate
                            ? moment(contract.endDate).format("DD-MM-YYYY")
                            : "Current")}
                </Typography>
                <Timer />
                <Typography variant="subtitle2">
                    {formatTimeDigital(
                        caseContracts.length === 1
                            ? totalActivityTime
                            : contract.activityDuration || 0
                    )}
                </Typography>
            </Box>
        );
    };

    const getCaseContractsAsync = useCallback(async () => {
        if (open && caseId) {
            try {
                const contractDtos = await getCaseContracts(caseId);
                setCaseContracts(contractDtos);
                setSelectedContractAdviceTypeId(
                    contractDtos.filter((c) => c.contractId == contractId)[0]
                        ?.adviceTypeId
                );
            } catch (error) {
                console.error(error);
            }
        }
    }, [caseId, open]);

    const handleClickAdd = () => {
        setNewContract({ ...newContract, startDate: new Date() });
        setMode(modes.add);
    };

    const handleSave = async () => {
        const handleError = (message) =>
            dispatch(setSnackAction(message, "error"));

        const caseStageId =
            configState.caseTypes[newCaseTypeId].stages[0].caseStageId;
        const isET = configState.caseTypes[newCaseTypeId].isET;

        const accountContract = accountContracts.filter(
            (c) => c.contractId === newContract.contractId
        )[0];

        if (!accountContract) {
            handleError("Whoops! Couldn't find that contract");
            return;
        }

        if (!Date.parse(newContract.startDate)) {
            handleError("Start date is invalid");
            return;
        }

        const startDate = new Date(newContract.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (startDate > new Date()) {
            handleError("Start date cannot be in the future");
            return;
        }

        if (startDate < new Date(dateCreated)) {
            handleError("Start date cannot be before case was created");
            return;
        }

        if (
            startDate < new Date(accountContract.startDate) ||
            startDate > new Date(accountContract.endDate)
        ) {
            handleError("Start date is out of the contracts date range");
            return;
        }

        for (let i = 0; i < caseContracts.length; i++) {
            if (startDate <= new Date(caseContracts[i].startDate)) {
                handleError(
                    "Start date cannot be before start date of existing contract"
                );
                return;
            }
        }

        setSaving(true);
        try {
            const {
                prevContractId,
                prevActivityDuration,
                currentCaseContractId,
                currentActivityDuration,
                adviceTypeId,
            } = await addCaseContract(newContract, caseId);
            // add new contract, update activity duration of prev contract
            setCaseContracts([
                ...caseContracts.map((c) =>
                    c.contractId === prevContractId
                        ? {
                              ...c,
                              endDate: newContract.startDate,
                              activityDuration: prevActivityDuration,
                              adviceTypeId,
                          }
                        : c
                ),
                {
                    caseContractId: currentCaseContractId,
                    contractId: newContract.contractId,
                    startDate: newContract.startDate,
                    activityDuration: currentActivityDuration,
                    adviceTypeId,
                },
            ]);
            // update currentContractId
            setCaseTypeId(newCaseTypeId);

            dispatch(
                setCaseContract(
                    newContract.contractId,
                    isNewContractUnitised(newContract.contractId)
                )
            );
            dispatch(
                setCaseTypeAndStage(caseId, newCaseTypeId, caseStageId, isET)
            );
            dispatch(
                setSnackAction(
                    "Successfully added new contract and updated case type!",
                    "success"
                )
            );
            reactQueryClient.invalidateQueries([queryKeys.caseTime, caseId]);
            reactQueryClient.invalidateQueries([queryKeys.contractSummary, newContract.contractId]);
            handleReset();
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const handleStartDateChange = (date) => {
        const startDate = new Date(date);
        startDate.setUTCHours(0, 0, 0, 0);
        setNewContract({ ...newContract, startDate });
    };

    const handleReset = () => {
        setNewContract({
            contractId: "",
            startDate: null,
            adviceTypeId: null,
        });
        setMode(modes.view);
    };

    const handleCancel = () => {
        handleReset();
        handleClose();
    };

    const newContractApplicableCaseTypes = (ct) =>
        ct.isActive &&
        newContract.adviceTypeId === ct.adviceTypeId &&
        ct.stages.length > 0 &&
        (ct.isAccountSpecific
            ? ct.accounts.map((a) => a.accountId).includes(account.accountId)
            : true);

    const contractApplicableCaseTypes = (ct) =>
        ct.isActive &&
        selectedContractAdviceTypeId === ct.adviceTypeId &&
        ct.stages.length > 0 &&
        (ct.isAccountSpecific
            ? ct.accounts.map((a) => a.accountId).includes(account.accountId)
            : true);

    const handleCaseTypeChange = async (e) => {
        const newCaseTypeId = e.target.value;
        const caseStageId =
            configState.caseTypes[newCaseTypeId].stages[0].caseStageId;
        const isET = configState.caseTypes[newCaseTypeId].isET;
        setSaving(true);
        try {
            await setCaseType({
                caseId: caseId,
                caseTypeId: newCaseTypeId,
                currentStageId: caseStageId,
            });
            setCaseTypeId(newCaseTypeId);
            dispatch(
                setCaseTypeAndStage(caseId, newCaseTypeId, caseStageId, isET)
            );
            dispatch(
                setSnackAction("Successfully updated case type!", "success")
            );
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const handleChangeCurrentContract = async (e) => {
        const newContractId = e.target.value;

        setSaving(true);
        try {
            const { caseContractId, activityDuration, adviceTypeId } =
                await setCurrentCaseContract(newContractId, caseId);
            dispatch(
                setCaseContract(
                    newContractId,
                    isNewContractUnitised(newContractId)
                )
            );
            setCaseContracts(
                caseContractId === null && activityDuration === null
                    ? caseContracts.map((c) => ({
                          ...c,
                          contractId: newContractId,
                      }))
                    : [
                          {
                              caseContractId,
                              contractId: newContractId,
                              startDate: dateCreated,
                              endDate: null,
                              activityDuration,
                              adviceTypeId,
                          },
                      ]
            );
            dispatch(
                setSnackAction(
                    "Successfully updated current contract!",
                    "success"
                )
            );
        } catch (error) {
            console.error(error);
        }
        setSaving(false);

        const selectedContract = accountContracts.find(
            (c) => c.contractId === newContractId
        );

        if (!selectedContract) return;

        setSelectedContractAdviceTypeId(
            parseInt(selectedContract.adviceTypeId)
        );
    };

    useEffect(() => {
        getCaseContractsAsync();
    }, [getCaseContractsAsync]);

    return (
        <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="lg">
            <DialogTitle>Case Contracts</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h6" mb={3}>
                            Current Contract
                        </Typography>
                        {mode !== modes.edit && currentCaseContract && (
                            <DisplayContract contract={currentCaseContract} />
                        )}
                        {mode === modes.edit && (
                            <Box
                                className={classes.spacing}
                                display="flex"
                                alignItems="center"
                            >
                                <FormControl className={classes.minWidth}>
                                    <Select
                                        value={contractId}
                                        onChange={handleChangeCurrentContract}
                                    >
                                        {accountContracts.map((c) => (
                                            <MenuItem
                                                key={c.contractId}
                                                value={c.contractId}
                                            >
                                                {c.coreService || "Not set"}
                                                {c.summary && (
                                                    <Tooltip title={c.summary}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.minWidth}>
                                    <Select
                                        value={caseTypeId}
                                        onChange={handleCaseTypeChange}
                                    >
                                        {Object.values(configState.caseTypes)
                                            .filter(contractApplicableCaseTypes)
                                            .sort((a, b) =>
                                                a.name > b.name
                                                    ? 1
                                                    : b.name > a.name
                                                    ? -1
                                                    : 0
                                            )
                                            .map((ct) => (
                                                <MenuItem
                                                    key={ct.caseTypeId}
                                                    value={ct.caseTypeId}
                                                >
                                                    {ct.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                <MuiIconButton
                                    onClick={() => setMode(modes.view)}
                                >
                                    <Replay />
                                </MuiIconButton>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" mb={3}>
                            Contract History
                        </Typography>
                        {caseContracts &&
                            caseContracts.map((c) => (
                                <DisplayContract
                                    key={c.contractId}
                                    contract={c}
                                />
                            ))}
                    </Grid>
                </Grid>

                {mode === modes.add && (
                    <Box display="flex" flexDirection="column" my={3}>
                        <Typography variant="h6" mb={3}>
                            Add Contract
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            className={classes.spacing}
                        >
                            <FormControl className={classes.minWidth}>
                                <InputLabel>Select contract</InputLabel>
                                <Select
                                    value={newContract.contractId}
                                    onChange={(e) =>
                                        setNewContract({
                                            ...newContract,
                                            contractId: e.target.value,
                                            adviceTypeId: accountContracts.find(
                                                (x) =>
                                                    x.contractId ===
                                                    e.target.value
                                            )?.adviceTypeId,
                                        })
                                    }
                                >
                                    {!accountContracts.filter(
                                        (c) =>
                                            !caseContractIds.includes(
                                                c.contractId
                                            )
                                    ).length && (
                                        <MenuItem>No contracts found</MenuItem>
                                    )}
                                    {accountContracts
                                        .filter(
                                            (c) =>
                                                !caseContractIds.includes(
                                                    c.contractId
                                                )
                                        )
                                        .map((c) => (
                                            <MenuItem
                                                key={c.contractId}
                                                value={c.contractId}
                                            >
                                                {c.coreService}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.minWidth}>
                                <Select
                                    value={newCaseTypeId}
                                    onChange={(e) =>
                                        setNewCaseTypeId(e.target.value)
                                    }
                                >
                                    {Object.values(configState.caseTypes)
                                        .filter(newContractApplicableCaseTypes)
                                        .sort((a, b) =>
                                            a.name > b.name
                                                ? 1
                                                : b.name > a.name
                                                ? -1
                                                : 0
                                        )
                                        .map((ct) => (
                                            <MenuItem
                                                key={ct.caseTypeId}
                                                value={ct.caseTypeId}
                                            >
                                                {ct.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <KeyboardDatePicker
                                className={classes.marginBottomRemoval}
                                name="start"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Start"
                                value={newContract.startDate}
                                onChange={handleStartDateChange}
                            />
                            <MuiIconButton onClick={handleReset}>
                                <Replay />
                            </MuiIconButton>
                        </Box>
                    </Box>
                )}

                <Box display="flex" justifyContent="space-between" my={3}>
                    <div>
                        {mode === modes.view && Boolean(currentCaseContract) ? (
                            <Button
                                style={{ marginRight: "12px" }}
                                color="primary"
                                variant="contained"
                                onClick={handleClickAdd}
                            >
                                Add
                            </Button>
                        ) : (
                            <div></div>
                        )}
                        {mode === modes.view && caseContracts.length < 2 && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setMode(modes.edit)}
                            >
                                Edit current contract
                            </Button>
                        )}
                    </div>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        className={classes.spacing}
                    >
                        {saving ? (
                            <CircularProgress />
                        ) : (
                            <React.Fragment>
                                <Button
                                    color="default"
                                    variant="contained"
                                    onClick={handleCancel}
                                >
                                    Close
                                </Button>
                                {mode === modes.add && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSave}
                                        disabled={
                                            !newContract.contractId ||
                                            !newCaseTypeId
                                        }
                                    >
                                        Save
                                    </Button>
                                )}
                            </React.Fragment>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ContractDialog;
