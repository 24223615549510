const activityTypes = {
    DETAILS: "Details",
    REPLY: "Reply",
    PRECEDENT: "Precedent",
    FILE: "File",
    FLEX: "Flex",
    BLUR_CALL: "BlurCall",
    CALL_NOTE: "CallNote",
    INTERNAL_NOTE: "InternalNote",
    RESUME_CASE_ACTIVITY: "ResumeCaseActivity",
    DOCUMENT_LIBRARY: "DocumentLibrary",
};

const tabActivityTypesMap = {
    0: activityTypes.DETAILS,
    1: activityTypes.DOCUMENT_LIBRARY,
    2: activityTypes.DETAILS, // treat ET claims the same as details
    3: activityTypes.DETAILS, // treat Draft Files the same as details
    4: activityTypes.DETAILS, // treat History the same as details
    5: activityTypes.REPLY,
    6: activityTypes.PRECEDENT,
};

export function getTabActivityType(tab) {
    if (tab >= 6) return activityTypes.PRECEDENT;

    return tabActivityTypesMap[tab];
}

export default activityTypes;
