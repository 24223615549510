import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import caseService from "../../services/caseService";
import reactQueryClient from "../../reactQueryClient";

const useUpdateCaseFiles = () =>
    useMutation(
        ({ caseId, downloadableOnly, caseFilesContent }) =>
            caseService.updateCaseFiles(caseId, downloadableOnly, caseFilesContent),
        {
            onMutate: async ({ caseId, downloadableOnly, caseFilesContent }) => {
                const queryKey = [queryKeys.caseFilesTree, caseId, downloadableOnly];

                await reactQueryClient.cancelQueries(queryKey);

                const previousCaseFiles = reactQueryClient.getQueryData(queryKey);

                reactQueryClient.setQueryData(queryKey, caseFilesContent.treeData);

                return { queryKey, previousCaseFiles }
            },
            onError: (_err, _newAction, context) => {
                reactQueryClient.setQueryData(context.queryKey, context.previousCaseFiles);
            },
            onSettled: (_newAction, _err, _variables, context) => {
                reactQueryClient.invalidateQueries(context.queryKey);
            }
        }
    );

export default useUpdateCaseFiles;