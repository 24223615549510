import React, { useState, useEffect } from "react";
import axios from "../plugins/axios";
import { connect } from "react-redux";
import styled from "styled-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
    Box,
    Chip,
    Collapse,
    IconButton as MuiIconButton,
    FormControl,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TablePagination,
    Typography,
} from "@material-ui/core";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { Controls } from "../helpers/Controls";

import chipStyles from "../theme/chips";
import { setSnackAction } from "../redux/actions/snackActions";
import Loader from "../components/Loader";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </div>
    );
}

const AccountContactTable = ({
    postCode,
    accountName,
    clickable,
    dispatch,
    handleClick,
    accountPageLoading,
    partnershipNumber,
    partnershipId,
    accountStatus,
    contactName,
}) => {
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [initialOpen, setInitialOpen] = React.useState(false);

    const chipClasses = chipStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const updateAccounts = async () => {
        setLoading(true);
        let response = await axios.post(`${apiUrl}/getaccountscontacts`, {
            name: accountName,
            postCode: postCode,
            partnershipNumber: partnershipNumber,
            pageNum: page + 1,
            pageSize: rowsPerPage,
            partnershipId: partnershipId,
            accountStatus: accountStatus,
            contactName: contactName,
        });
        if (response.status !== 200) {
            dispatch(setSnackAction("Sorry, there was an error!", "error"));
        } else if (response.data.count === 0) {
            dispatch(setSnackAction("No results found!", "warning"));
        } else {
            setResults(response.data.accounts);
            setCount(response.data.count);

            //console.log('jawa_test', response.data);

            response.data.count == 1
                ? setInitialOpen(true)
                : setInitialOpen(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        page === 0 ? updateAccounts() : setPage(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        accountName,
        postCode,
        partnershipNumber,
        partnershipId,
        accountStatus,
        contactName,
    ]);

    useEffect(() => {
        updateAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    function AccountRow(results) {
        const { a } = results;
        const [open, setOpen] = React.useState(initialOpen);

        return (
            <React.Fragment>
                <TableRow
                    key={a.accountId}
                    style={{
                        cursor: clickable ? "pointer" : "default",
                        height: "100%",
                    }}
                    hover={clickable ? true : false}
                    onClick={() => clickable && setOpen(!open)}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {a.name || "-"}
                    </TableCell>
                    <TableCell>{a.address || "-"}</TableCell>
                    <TableCell>{a.postCode || "-"}</TableCell>
                    <TableCell>{a.phone || "-"}</TableCell>
                    <TableCell>
                        {!a.partnershipName
                            ? "-"
                            : !a.partnershipNumber
                            ? a.partnershipName
                            : a.partnershipName +
                              " (" +
                              a.partnershipNumber +
                              ")"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                        <Chip
                            className={
                                a.status === "Active"
                                    ? chipClasses.green
                                    : a.status === "Pending"
                                        ? chipClasses.amber
                                        : chipClasses.root                                            
                            }
                            label={a.status || "N/A"}
                            style={{ width: "100%" }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f9f9f9",
                        }}
                        colSpan={7}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Box display="flex">
                                    <Box flexGrow={1} alignItems="center">
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                        >
                                            Current users on above account
                                            record
                                        </Typography>
                                    </Box>
                                    <FormControl>
                                        <Controls.ButtonControl
                                            variant="outlined"
                                            text="Create New Contact"
                                            size="small"
                                            color="primary"
                                            onClick={() => handleClick(a, null)}
                                        />
                                    </FormControl>
                                </Box>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Contact Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>Mobile Phone</TableCell>
                                            <TableCell>Position</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {a.contacts.map((accContactRow) => (
                                            <TableRow
                                                key={accContactRow.contactId}
                                                style={{
                                                    cursor: clickable
                                                        ? "pointer"
                                                        : "default",
                                                    height: "100%",
                                                }}
                                                hover={clickable ? true : false}
                                                onClick={() =>
                                                    clickable &&
                                                    handleClick(
                                                        a,
                                                        accContactRow
                                                    )
                                                }
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {accContactRow.name}
                                                </TableCell>
                                                <TableCell>
                                                    {accContactRow.email}
                                                </TableCell>
                                                <TableCell>
                                                    {accContactRow.phone}
                                                </TableCell>
                                                <TableCell>
                                                    {accContactRow.mobile}
                                                </TableCell>
                                                <TableCell>
                                                    {accContactRow.position}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return !results || loading || accountPageLoading ? (
        <Loader />
    ) : (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Postcode</TableCell>
                        <TableCell>Telephone Number</TableCell>
                        <TableCell>PartnershipName</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((accountrow) => (
                        <AccountRow key={accountrow.name} a={accountrow} />
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </React.Fragment>
    );
};

export default connect()(AccountContactTable);
