import React, { useMemo } from "react";
import openAiService from "../services/openAiService";
import { completeAiResponse, initiateAiResponse, setReplyingToPlainText, setReplyIsAiGenerated } from "../redux/actions/caseActions";
import { useDispatch, useSelector } from "react-redux";
import emailService from "../services/emailService";

const useAiResponse = () => {
    const dispatch = useDispatch();
    const { currentCaseId: caseId, cases } = useSelector((state) => state.caseReducer);
    const { caseTypes } = useSelector((state) => state.configReducer);

    const allowAiResponse = useMemo(() => caseTypes[cases[caseId]?.caseSummary.caseTypeId]?.allowAiResponse, [caseTypes, caseId, cases]);

    const generateAiResponse = async ({ chatItems, emailId }) => {
        dispatch(initiateAiResponse(emailId));

        const chatCompletion = await openAiService.createChatCompletion(chatItems);

        await emailService.setEmailAiGeneratedResponse({
            emailId,
            aiGeneratedResponse: chatCompletion.choices[0].message.content,
        });

        dispatch(completeAiResponse(emailId, chatCompletion.choices[0].message.content));
    };

    const getAiResponse = ({ email }) => {
        if (email.aiGeneratedResponse && email.parsedBodyPlainText && allowAiResponse) {
            dispatch(setReplyingToPlainText(caseId, email.parsedBodyPlainText));
            dispatch(setReplyIsAiGenerated(caseId, true));
            return email.aiGeneratedResponse;
        }
        return "";
    };

    return { generateAiResponse, allowAiResponse, getAiResponse };
};

export default useAiResponse;
