import React from "react";
import { Box, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    permissionsIcon: {
        position: "absolute",
        fontSize: 20,
        left: 52,
        bottom: 0,
    },
}));

export const ContactPermissionSummary = ({ permissions, isContactsCard }) => {
    const classes = useStyles();

    const updatePermissionName = (permission) => {
        switch (permission) {
            case "Casenest Administrator":
                return "System Administrator";
                break;
            case "Casenest Receive Advice":
                return "Receive advice";
                break;
            case "Casenest All Cases":
                return "Access to all cases";
                break;
            default:
                return "None";
        }
    };

    return (
        <Tooltip
            title={
                <Box display="flex" flexDirection="column" p={2}>
                    <Typography variant="subtitle1">Access:</Typography>
                    {permissions?.length ? (
                        permissions.map((permission) => (
                            <Typography key={permission}>
                                - {updatePermissionName(permission)}
                            </Typography>
                        ))
                    ) : (
                        <Typography>- None</Typography>
                    )}
                </Box>
            }
            arrow
            interactive
            placement="right-start"
        >
            <InfoIcon
                className={!isContactsCard ? "" : classes.permissionsIcon}
            />
        </Tooltip>
    );
};

export default ContactPermissionSummary;
