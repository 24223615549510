import React, { useCallback } from "react";
import { Box, Divider, IconButton, LinearProgress, List, ListItem, ListItemText, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Clear, InsertDriveFile } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { getFileExtension } from "../utils/fileNameUtils";
import { grey, red, green } from "@material-ui/core/colors";
import { alpha } from "@material-ui/core/styles";
import formatBytes from "../helpers/formatBytes";
import storageService from "../services/storageService";
import { uploadStatus } from "../constants/uploadStatus";

const supportedFileTypes = [
    ".jpeg",
    ".gif",
    ".png",
    ".doc",
    ".docx",
    ".pdf",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".txt",
    ".msg",
    ".eml",
    ".csv",
    ".zip",
    ".mp4",
    ".webm",
    ".ogg",
    ".mov",
    ".wav",
    ".mp3",
    ".m4a",
];

const MAX_FILE_SIZE = 524288000;

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(2, 0),
        height: "2px",
        backgroundColor: "#C6C3D4",
    },
    supportedFileTypesContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: theme.spacing(3, 0),
    },
    supportedFileTypesText: {
        textDecoration: "underline",
        color: theme.palette.secondary.main,
        cursor: "default",
    },
    dragBoxText: {
        cursor: "default",
        whiteSpace: "pre-wrap",
        textAlign: "center",
    },
    errorMessage: {
        color: red[800],
    },
    listItem: {
        backgroundColor: alpha(grey[300], 0.3),
        borderRadius: "12px",
        margin: theme.spacing(3, 0),
    },
    progressContainer: {
        display: "flex",
        flexDirection: "column",
    },
    sizeContainer: {
        display: "flex",
        alignItems: "center",
    },
    queued: {
        marginLeft: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    progressBarContainer: {
        display: "flex",
        alignItems: "center",
    },
    progressPercentage: {
        color: theme.palette.primary.main,
    },
    listItemPrimary: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    progressBar: {
        width: "100%",
        marginRight: theme.spacing(1),
    },
    completeText: {
        color: green[700],
    },
}));

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 350px;
    height: 150px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
`;

const UploadedFile = ({ name, onClear, secondaryComponent = null, clearDisabled = false }) => {
    const classes = useStyles();
    return (
        <ListItem className={classes.listItem}>
            <ListItemText
                primary={
                    <div className={classes.listItemPrimary}>
                        <Typography color="primary" variant="subtitle2">
                            {name}
                        </Typography>
                        <IconButton size="small" onClick={onClear} disabled={clearDisabled}>
                            <Clear />
                        </IconButton>
                    </div>
                }
                secondary={secondaryComponent}
                disableTypography
            />
        </ListItem>
    );
};

const DocumentUploader = ({ attachments, setAttachments, setUploadStatus, failedUploads, setFailedUploads, removeDraftAttachment }) => {
    const classes = useStyles();

    const onDrop = useCallback(
        async (files) => {
            setFailedUploads((prev) => [
                ...prev,
                ...files
                    .filter((file) => !supportedFileTypes.includes(getFileExtension(file.name)))
                    .map((file) => ({ id: nanoid(), name: file.name, message: "Failed: File type not supported" })),
                ...files
                    .filter((file) => supportedFileTypes.includes(getFileExtension(file.name)) && file.size > MAX_FILE_SIZE)
                    .map((file) => ({ id: nanoid(), name: file.name, message: "Failed: File too large" })),
            ]);

            const acceptedFiles = files
                .filter((file) => supportedFileTypes.includes(getFileExtension(file.name)) && file.size <= MAX_FILE_SIZE)
                .map((file) => ({ id: nanoid(), file }));

            if (acceptedFiles.length === 0) return;

            setAttachments([
                ...attachments,
                ...acceptedFiles.map((x) => ({
                    ...x,
                    file: {
                        name: x.file.name,
                        size: x.file.size,
                        type: x.file.type,
                    },
                    status: uploadStatus.QUEUED,
                })),
            ]);

            for (const file of acceptedFiles) {
                try {
                    setUploadStatus({ id: file.id, status: uploadStatus.IN_PROGRESS });
                    const reference = await storageService.upload(file.file);
                    setUploadStatus({ id: file.id, status: uploadStatus.COMPLETE, reference });
                } catch (e) {
                    setAttachments(attachments.filter((x) => x.id !== file.id));
                    setFailedUploads((prev) => [
                        ...prev,
                        {
                            id: file.id,
                            name: file.file.name,
                            message: "Failed: Unknown error. Try again or contact support",
                        },
                    ]);
                    console.error(e);
                }
            }
        },
        [attachments]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
    });

    const handleClearAttachment = async (id) => {
        const attachment = attachments.filter((x) => x.id === id)[0];

        if (!attachment) return;

        if (!attachment.azureFileId) {
            if (attachment.status === uploadStatus.COMPLETE) {
                try {
                    await storageService.deleteBlob(id);
                } catch (e) {
                    console.error(e);
                }
            }
        } else {
            await removeDraftAttachment(attachment.azureFileId);
        }
        setAttachments(attachments.filter((x) => x.id !== id));
    };

    const handleClearFailedUpload = (id) => {
        setFailedUploads((prev) => prev.filter((file) => file.id !== id));
    };

    return (
        <React.Fragment>
            <section style={{ marginTop: "24px" }}>
                <Container
                    {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                    })}
                >
                    <input {...getInputProps()} />
                    <InsertDriveFile color="secondary" fontSize="large" data-cy="file_upload_box" />
                    <p className={classes.dragBoxText}>{"Drop your files here or click to browse\n(500 MB max)"}</p>
                </Container>
                <div className={classes.supportedFileTypesContainer}>
                    <Tooltip title={supportedFileTypes.join(", ")}>
                        <Typography className={classes.supportedFileTypesText}>Supported file types</Typography>
                    </Tooltip>
                </div>
                <Box display="flex" justifyContent="center">
                    <Box width="350px" my={3}>
                        {(!!attachments.length || !!failedUploads.length) && (
                            <>
                                <Typography variant="h6">Uploaded Files</Typography>
                                <Divider className={classes.divider} />
                                <List disablePadding>
                                    {attachments.map((file) => (
                                        <UploadedFile
                                            key={file.id}
                                            name={file.file?.path || file.path || file.file?.name || file.name}
                                            onClear={() => handleClearAttachment(file.id)}
                                            secondaryComponent={
                                                file.status === uploadStatus.COMPLETE ? (
                                                    <Typography variant="subtitle2" className={classes.completeText}>
                                                        Completed
                                                    </Typography>
                                                ) : file.status === uploadStatus.IN_PROGRESS || file.status === uploadStatus.QUEUED ? (
                                                    <div className={classes.progressContainer}>
                                                        <div className={classes.sizeContainer}>
                                                            <Typography variant="caption">{formatBytes(file.file.size, 0)}</Typography>
                                                            <Typography variant="caption" className={classes.queued}>
                                                                {file.status === uploadStatus.QUEUED ? "Queued" : "Uploading"}
                                                            </Typography>
                                                        </div>
                                                        {file.status === uploadStatus.IN_PROGRESS && (
                                                            <LinearProgress className={classes.progressBar} variant="indeterminate" />
                                                        )}
                                                    </div>
                                                ) : null
                                            }
                                        />
                                    ))}
                                    {failedUploads.map((file) => (
                                        <UploadedFile
                                            key={file.id}
                                            name={file.name}
                                            onClear={() => handleClearFailedUpload(file.id)}
                                            secondaryComponent={
                                                <Typography variant="caption" className={classes.errorMessage}>
                                                    {file.message}
                                                </Typography>
                                            }
                                        />
                                    ))}
                                </List>
                            </>
                        )}
                    </Box>
                </Box>
            </section>
        </React.Fragment>
    );
};

export default DocumentUploader;
