import React, { useState, useEffect } from "react";
import axios from "../../plugins/axios";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import AIThread from "./AIThread";
import Loader from "../Loader";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@material-ui/core";
import {
    ThumbUp as ApprovedIcon
} from "@material-ui/icons";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const AITraining = () => {

    const [threads, setThreads] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] = useState({
        pageNum: 0,
        pageSize: 10,
        approved: '',
        partner: '',
        caseTypeId: ''
    });
    
    const history = useHistory();
    const location = useLocation();    

    const configState = useSelector((state) => state.configReducer);

    const updateCriteria = (field, value) => {
        console.log('field', field, value);
        setCriteria({
            ...criteria,
            [field]: value,
            pageNum: field == 'pageNum' ? value : 0
        })
    }

    const handleChangePage = (event, newPage) => {
        console.log('newPage', newPage);
        setCriteria({
            ...criteria,
            pageNum: newPage
        });
    };

    const fetchThreads = async () => {

        setLoading(true);

        var response = await axios.post(`${apiUrl}/getthreads`, criteria);

        setThreads(response.data.threads);
        setCount(response.data.count);

        setLoading(false);
    }

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search)
        const caseId = queryParams.get('caseId');

        const checkForThread = async () => {
            var response = await axios.get(`${apiUrl}/getThreadForCase/${caseId}`);
            await fetchThreads();
            setThread(response.data);            
        }

        if (caseId) {
            console.log('found', caseId);
            queryParams.delete('caseId');
            history.replace({ search: queryParams.toString() });
            checkForThread();
        }
    }, []);

    useEffect(() => {

        fetchThreads();
    }, [criteria]);

    const [thread, setThread] = useState(null);

    const newThread = () => {
        setThread({});
    };

    const updateThread = (thread) => {
        setThreads(threads.map(t => t.threadId == thread.threadId ? thread : t));
    }

    const AIThreads = () => {

        return (
            <React.Fragment >
                <Box display="flex" gridGap="5px">
                    <FormControl>
                        <InputLabel shrink>Approved</InputLabel>
                        <Select
                            displayEmpty
                            value={criteria.approved}
                            style={{ width: "150px" }}
                            onChange={e => updateCriteria('approved', e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink>Partner</InputLabel>
                        <Select
                            displayEmpty
                            value={criteria.partner}
                            style={{ width: "150px" }}
                            onChange={e => updateCriteria('partner', e.target.value)}
                        >
                            <MenuItem value="">Any</MenuItem>
                            {
                                Object.values(configState.partnerships)
                                    .sort((a, b) => a.title > b.title ? 1 : b.title > a.title ? -1 : 0)
                                    .map((p) => <MenuItem key={p.title} value={p.title}>{p.title}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink>Case Type</InputLabel>
                        <Select
                            displayEmpty
                            value={criteria.caseTypeId}
                            style={{ width: "150px" }}
                            onChange={e => updateCriteria('caseTypeId', e.target.value)}
                        >
                            <MenuItem value="">Any</MenuItem>
                            {
                                Object.values(configState.caseTypes)
                                    .filter((x) => x.isActive)
                                    .sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)
                                    .map((ct) => <MenuItem key={ct.caseTypeId} value={ct.caseTypeId}>{ct.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <Box flexGrow={1} textAlign="right">
                        <Button variant="contained" size="small" onClick={newThread}>New Thread</Button>
                    </Box>                    
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>CaseId</TableCell>
                            <TableCell>CaseType</TableCell>
                            <TableCell>Messages</TableCell>
                            <TableCell>Partner</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>Approved</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {threads.map(t => (
                            <TableRow key={t.threadId} style={{ cursor: 'pointer' }} onClick={() => setThread(t)}>
                                <TableCell>{t.caseId}</TableCell>
                                <TableCell>{t.caseType}</TableCell>
                                <TableCell>{t.messageCount}</TableCell>
                                <TableCell>{t.partner}</TableCell>
                                <TableCell>{t.description}</TableCell>
                                <TableCell>{t.subject}</TableCell>
                                <TableCell>{t.approved && <ApprovedIcon />}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[criteria.pageSize]}
                    component="div"
                    count={count}
                    rowsPerPage={criteria.pageSize}
                    page={criteria.pageNum}
                    onPageChange={handleChangePage}
                />
            </React.Fragment>
        );
    };

    if (loading)
        return <Loader />;

    return (
        <React.Fragment>
            <Helmet title="AITraining" />
            {
                !thread && <AIThreads />
            }
            {
                thread &&
                <AIThread
                    thread={thread}
                    updateThread={updateThread}
                    back={() => setThread(null)}
                    refresh={fetchThreads}
                />
            }
        </React.Fragment>
    );
};

export default AITraining
