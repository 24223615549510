import { Box, Card, CardContent, CircularProgress, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Replay as UndoIcon
} from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { viewModeStates } from "../../constants/viewModeStates";

const useStyles = makeStyles((theme) => ({
    boxSpacing: {
        gap: theme.spacing(3),
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)'
    },
    rightHalf: {
        display: 'flex',
        justifyContent: 'end',
        width: '100%'
    },
    fullGrid: {
        gridColumn: '1 / span 4',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`
    },
    leftHalf: {
        minWidth: 'max-content'
    }
}))

export default function ETClaimsDetailsCard({
    onSave,
    data = {}, 
    isLoading, 
    isError, 
    error
}) {
    const classes = useStyles()
    const [eTCaseNumber, setETCaseNumber] = useState()
    const [earlyConciliationNumber, setEarlyConciliationNumber] = useState()
    const [viewMode, setViewMode] = useState(viewModeStates.VIEWING) 

    useEffect(() => {
        setETCaseNumber(data?.caseNum || '')
        setEarlyConciliationNumber(data?.earlyConciliationNumber || '')
    }, [data])

    function handleSave() {
        const changes = {
            caseNum: eTCaseNumber ? eTCaseNumber : data?.eTCaseNumber ?? '',
            earlyConciliationNumber: earlyConciliationNumber ? earlyConciliationNumber : data?.earlyConciliationNumber ?? ''
        }
        
        onSave(changes)
        setViewMode(viewModeStates.VIEWING)
    }

    return (
        <Card>
            <CardContent>
                <div className={classes.boxSpacing}>
                    <div className={classes.fullGrid}> 
                        <Typography className={classes.leftHalf} variant="h6">ET Claim Details</Typography>
                        <div className={classes.rightHalf}>
                    </div>
                        {viewMode === viewModeStates.VIEWING && 
                            <IconButton 
                                onClick={e => {
                                    e.stopPropagation()
                                    setViewMode(viewModeStates.EDITING)
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        }
                        {viewMode === viewModeStates.EDITING && 
                            <>
                                <IconButton
                                    onClick={e => {
                                        e.stopPropagation()
                                        setViewMode(viewModeStates.SAVING)
                                        handleSave()
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                                <IconButton
                                    onClick={e => {
                                        e.stopPropagation()
                                        setViewMode(viewModeStates.VIEWING)
                                    }}
                                >
                                    <UndoIcon />
                                </IconButton>
                            </>
                        }
                        {viewMode === viewModeStates.SAVING &&
                            <CircularProgress size={20} />
                        }
                    </div>
                    {(isLoading || viewMode === viewModeStates.SAVING) &&
                        <>
                            <Skeleton 
                                height={40} 
                                variant='rect' 
                            />
                            <Skeleton 
                                height={40} 
                                variant='rect' 
                            />
                        </>
                    }
                    {isError &&
                        <Box display="flex" justifyContent="center" width="100%">
                            <Alert variant="outlined" severity="error">
                                {error?.message || "Unable to load ET case details"}
                            </Alert>
                        </Box>
                    }
                        <>
                            {viewMode === viewModeStates.VIEWING &&
                                <>
                                    <div>
                                        <Typography variant="subtitle2">ET Case Number</Typography>
                                        <Typography>{data.caseNum ? data.caseNum : '-'}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle2">Early Conciliation Number</Typography>
                                        <Typography>{data.earlyConciliationNumber ? data.earlyConciliationNumber : '-'}</Typography>
                                    </div>
                                </>
                            }
                            {viewMode === viewModeStates.EDITING &&
                                <>
                                    <TextField 
                                        label='ET Case Number' 
                                        onChange={e => setETCaseNumber(e.target.value)} 
                                        placeholder="ET Case Number" 
                                        type='text' 
                                        value={eTCaseNumber ? eTCaseNumber : ''} 
                                        variant="outlined" 
                                    />
                                    <TextField 
                                        label='Early Conciliation Number' 
                                        onChange={e => setEarlyConciliationNumber(e.target.value)} 
                                        placeholder="Early Conciliation Number" 
                                        type='text' 
                                        value={earlyConciliationNumber ? earlyConciliationNumber : ''} 
                                        variant="outlined" 
                                    />
                                </>
                            }
                        </>
                </div>
            </CardContent>
        </Card>
    )
}