import React, { useEffect, useState, useCallback } from "react";
import styled, { withTheme } from "styled-components";
import axios from "../plugins/axios";

import {
    Box,
    Card as MuiCard,
    CardContent,
    CardHeader,
    IconButton,
    Chip as MuiChip,
} from "@material-ui/core";

import {
    AccountCircle as MyActivityIcon,
    SupervisedUserCircle as TeamActivityIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { alpha } from "@material-ui/core/styles";

import { Line as LineChart } from "react-chartjs-2";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 378px;
    position: relative;
`;

const Chip = styled(MuiChip)`
    height: 20px;
    padding: 4px 0;
    font-size: 85%;
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.common.white};
    span {
        padding-left: ${(props) => props.theme.spacing(2)}px;
        padding-right: ${(props) => props.theme.spacing(2)}px;
    }
`;

const InboundQueriesCard = ({ theme, userProfile, fromDate, toDate }) => {
    const [dbData, setDbData] = useState(null);
    const [chartPeriod, setChartPeriod] = useState("Daily");
    const [footerText, setFooterText] = useState("All Users");

    const updateDashboardTwilioData = useCallback(async () => {
        if (
            (!fromDate && toDate) ||
            (fromDate && !toDate) ||
            !userProfile.userId
        )
            return;

        try {
            var response = await axios.post(
                `${apiUrl}/getinbounddatafordashboard`,
                {
                    userId:
                        footerText == "All Users" ? null : userProfile.userId,
                    fromDate,
                    toDate,
                }
            );
            setDbData(response.data.dashboardChart);
        } catch (e) {
            console.error(e);
        }
    }, [userProfile.userId, fromDate, toDate]);

    useEffect(() => {
        //update case count when page is hit
        updateDashboardTwilioData();
    }, [updateDashboardTwilioData]);

    const data = (canvas) => {
        const ctx = canvas.getContext("2d");

        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
        gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

        return {
            labels: dbData && dbData.labels,
            datasets: dbData && dbData.datasets,
        };
    };

    const options = {
        maintainAspectRatio: false,
        //legend: {
        //    display: false
        //},
        tooltips: {
            intersect: false,
        },
        hover: {
            intersect: true,
        },
        plugins: {
            filler: {
                propagate: false,
            },
        },
        scales: {
            xAxes: [
                {
                    reverse: true,
                    gridLines: {
                        color: "rgba(0,0,0,0.0)",
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        stepSize: 500,
                    },
                    display: true,
                    borderDash: [5, 5],
                    gridLines: {
                        color: "rgba(0,0,0,0.0375)",
                        fontColor: "#fff",
                    },
                },
            ],
        },
    };

    const handleSwitchViews = () => {
        //alter the chart period which
        setChartPeriod(
            chartPeriod == "Daily"
                ? "Weekly"
                : chartPeriod == "Weekly"
                ? "Monthly"
                : "Daily"
        );
    };

    const handleSwitchIncludeViews = () => {
        //alter the chart period which
        setFooterText(
            footerText == "All Users" ? userProfile.name : "All Users"
        );
    };

    return (
        <Card mb={3}>
            <CardHeader
                action={
                    <Chip
                        label={
                            !(toDate && fromDate)
                                ? "Last week"
                                : "Custom date range"
                        }
                    />
                }
                title={"Total Inbound Queries"}
            />
            <CardContent>
                <ChartWrapper>
                    <LineChart data={data} options={options} />
                </ChartWrapper>
            </CardContent>
            <CardHeader
                action={
                    //<IconButton aria-label="settings" onClick={() => handleSwitchIncludeViews()}>
                    <IconButton
                        aria-label="settings"
                        onClick={() => handleSwitchIncludeViews()}
                    >
                        {footerText == "User" ? (
                            <MyActivityIcon />
                        ) : (
                            <TeamActivityIcon />
                        )}
                    </IconButton>
                }
                title={
                    <Box display="flex" justifyContent="flex-end" mt={-2}>
                        {footerText}
                    </Box>
                }
            />
        </Card>
    );
};

export default withTheme(InboundQueriesCard);
