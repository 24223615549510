const caseActivityTypes = {
    CALL: "Call",
    NOTE: "Note",
    INTERNAL_NOTE: "InternalNote",
    EMAIL: "Email",
    MISC: "Misc",
    ACTION: "Action",
    OUTCOME: "Outcome",
};

export default caseActivityTypes;