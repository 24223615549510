import React, { useState, useMemo } from "react";
import { actionCreators } from "../redux/reducers/caseReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PrecedentChooser from "./PrecedentChooser";
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    Card,
    Button,
    makeStyles,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Box,
    Typography,
    TextField,
    Fab,
    Grid,
    Menu,
    MenuItem,
    InputAdornment,
} from "@material-ui/core";
import Loader from "../components/Loader";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { createSharepointFile } from "../services/caseService";
import axios from "../plugins/axios";
import { setSnackAction } from "../redux/actions/snackActions";
import useDraftFiles from "../hooks/queries/useDraftFiles";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import { tableDateTime } from "../utils/dateTimeFormatters";
import sharepointService from "../services/sharepointService";
import ErrorIcon from "@material-ui/icons/Error";
import { isValidDraftFilename } from "../helpers/isValidDraftFilename";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    fabButton: {
        position: "absolute",
        zIndex: 300,
        transform: "rotate(90deg)",
        right: "-60px",
        height: "22px",
        top: "-45px",
    },
    gridContainer: {
        position: "relative",
    },
    diaTitle: {
        flexGrow: 1,
    },
    blankDocumentBtn: {
        marginBottom: theme.spacing(2),
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
}));

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;
const invalidFilenameMessage = "Filename can be alphanumeric and include hyphens, underscores, periods and spaces";

const DraftFiles = ({ caseId, setSnack }) => {
    const classes = useStyles();
    const [blankDocumentDialogOpen, setBlankDocumentDialogOpen] =
        useState(false);
    const [editingFile, setEditingFile] = useState(null);
    const [dialogFileName, setDialogFileName] = useState("");
    const [creating, setCreating] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [showPrecedents, setShowPrecedents] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isValidFilename = useMemo(() => {
        return isValidDraftFilename(dialogFileName)
    }, [dialogFileName]);

    const { data: files } = useDraftFiles({ caseId });

    const closeDocumentDialog = () => {
        setDialogFileName("");
        setBlankDocumentDialogOpen(false);
        setEditingFile(null);
    };

    const getDraftFiles = () => {
        reactQueryClient.invalidateQueries([queryKeys.draftFiles, caseId]);
    };

    const handleClick = (e, id) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setIsMenuOpen(id);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setIsMenuOpen(false);
    };

    const trimAndAppendExtension = (fileName) => {
        return `${fileName.trim()}.docx`
    }

    const createBlankDocument = async () => {
        setCreating(true);
        try {
            const filePath = await createSharepointFile(
                null,
                caseId,
                trimAndAppendExtension(dialogFileName)
            );
            window.open(filePath, "_blank");
            closeDocumentDialog();
            getDraftFiles();
        } finally {
            setCreating(false);
        }
    };

    const downloadDocument = async (f) => {
        setRequesting(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
        try {
            let response = await axios.post(
                `${apiUrl}/sharepoint/getaccesstosharepointdocument`,
                f
            );
            var info = response.data;

            if (info.link) {
                window.open(info.link, "_blank");
            }

            if (info.error == "DELETED") {
                setSnack("File has been deleted from sharepoint.", "error");
                getDraftFiles();
            }
        } finally {
            setRequesting(false);
        }
    };

    const editDocument = async (f) => {
        setAnchorEl(null);
        setIsMenuOpen(false);
        setEditingFile(f);
    };

    const copyDocument = async (f) => {
        setAnchorEl(null);
        setIsMenuOpen(false);
        try {
            const filePath = await sharepointService.copyDraftFile({ file: f });
            window.open(filePath.data, "_blank");
        } catch (e) {
            setSnack("Error copying file.", "error");
            console.error(e);
        }
        getDraftFiles();
    };

    const deleteDocument = async (f) => {
        setRequesting(true);
        setAnchorEl(null);
        setIsMenuOpen(false);
        try {
            var response = await axios.post(
                `${apiUrl}/sharepoint/deletesharepointdocument`,
                f
            );

            if (response.data === "IN USE") {
                setSnack(
                    "File is in use and cannot currently be deleted.",
                    "error"
                );
            } else {
                getDraftFiles();
            }
        } finally {
            setRequesting(false);
        }
    };

    const handleFileRenameSave = async () => {
        setRequesting(true);
        try {
            const response = await axios.post(
                `${apiUrl}/sharepoint/renamesharepointdocument`,
                { ...editingFile, name: trimAndAppendExtension(dialogFileName) }
            );
            if (response.data == "DELETED") {
                setSnack("File has been deleted from sharepoint.", "error");
                getDraftFiles();
            } else if (response.data === "IN USE") {
                setSnack(
                    "File is in use and cannot currently be renamed.",
                    "error"
                );
            } else {
                getDraftFiles();
            }
            closeDocumentDialog();
        } finally {
            setRequesting(false);
        }
    };

    const handleFilenameChange = (e) => {
        const filename = e.target.value;
        setDialogFileName(filename);
    };

    if (files == null) return <Loader />;

    return (
        <Grid container className={classes.gridContainer} spacing={6}>
            <Fab
                color="secondary"
                aria-label="ShowPrecedents"
                className={classes.fabButton}
                onClick={() => setShowPrecedents((prevCheck) => !prevCheck)}
                variant="extended"
            >
                Precedents
            </Fab>
            <Grid item xs={12} lg={showPrecedents ? 8 : 12}>
                <Card className={classes.card}>
                    <Button
                        className={classes.blankDocumentBtn}
                        variant="contained"
                        color="secondary"
                        onClick={() => setBlankDocumentDialogOpen(true)}
                    >
                        Create Blank Document
                    </Button>
                    {files.length === 0 && <h2>No Draft Files</h2>}
                    {files.length > 0 && (
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((f) => (
                                    <TableRow key={f.id}>
                                        <TableCell>{f.name}</TableCell>
                                        <TableCell>
                                            {f.createdBy.name}
                                        </TableCell>
                                        <TableCell>
                                            {tableDateTime(f.dateAdded)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(e) =>
                                                    handleClick(e, f.id)
                                                }
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                keepMounted
                                                open={isMenuOpen === f.id}
                                                onClose={handleClose}
                                                anchorEl={anchorEl}
                                            >
                                                <MenuItem
                                                    onClick={() =>
                                                        editDocument(f)
                                                    }
                                                >
                                                    Rename
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() =>
                                                        copyDocument(f)
                                                    }
                                                >
                                                    Open a copy in Word
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() =>
                                                        downloadDocument(f)
                                                    }
                                                >
                                                    Open in Word
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() =>
                                                        deleteDocument(f)
                                                    }
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}

                    <Dialog
                        open={blankDocumentDialogOpen}
                        onClose={closeDocumentDialog}
                        fullWidth
                    >
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h6"
                                    component="div"
                                    className={classes.diaTitle}
                                >
                                    Choose File Name
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="File Name"
                                value={dialogFileName}
                                onChange={handleFilenameChange}
                                helperText={
                                    dialogFileName &&
                                    !isValidFilename &&
                                    invalidFilenameMessage
                                }
                                error = {
                                    dialogFileName &&
                                    !isValidFilename
                                }
                                InputProps={
                                    dialogFileName && !isValidFilename && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={closeDocumentDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    !dialogFileName ||
                                    creating ||
                                    !isValidFilename
                                }
                                color="primary"
                                variant="contained"
                                onClick={createBlankDocument}
                            >
                                Create Document
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={!!editingFile}
                        onClose={closeDocumentDialog}
                        fullWidth
                    >
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h6"
                                    component="div"
                                    className={classes.diaTitle}
                                >
                                    Edit File Name -{" "}
                                    {editingFile?.name.replace(".docx", "")}
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="New File Name"
                                value={dialogFileName || ""}
                                onChange={(e) => {
                                    setDialogFileName(e.target.value)
                                    }
                                }
                                helperText ={
                                    dialogFileName &&
                                    !isValidFilename &&
                                    invalidFilenameMessage
                                }
                                error = {
                                    dialogFileName &&
                                    !isValidFilename
                                }
                                InputProps={
                                    dialogFileName && !isValidFilename && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={requesting}
                                color="primary"
                                onClick={closeDocumentDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={requesting || !dialogFileName || !isValidFilename}
                                color="primary"
                                variant="contained"
                                onClick={handleFileRenameSave}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </Grid>
            {showPrecedents && (
                <Grid item xs={12} lg={4}>
                    <PrecedentChooser />
                </Grid>
            )}
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) =>
            dispatch(setSnackAction(message, severity)),
    };
};

export default connect(null, mapDispatchToProps)(DraftFiles);
