import React, { useEffect } from "react";
import { CircularProgress, Paper, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import CaseHistoryItem from "../cards/CaseHistoryItem";

const useStyles = makeStyles((theme) => ({
    card: {
        marginRight: theme.spacing(2),
    },
    loader: {
        display: "flex",
        justifyContent: "center",
    },
}));

const CaseHistorySummary = ({
    handleFullScreenOpen,
    handleOpenContextMenu,
    events,
    jumptoActivityId,
    handleEventChecked,
    downloading,
    downloadCaseActivityIds,
    loadingEvents,
    onEditDescription,
}) => {
    const classes = useStyles();

    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const { mode: mode } = useSelector(state => state.caseReducer.cases[caseId]?.caseHistory || { mode: 'view' });
    const { users: internalUsers } = useSelector((state) => state.userReducer);
    const { miscActivityTypes } = useSelector((state) => state.configReducer);

    const c = cases[caseId];

    const jumpToItem = (itemIdSelected) => {
        var element = document.querySelector(`[data-id="${itemIdSelected}"]`)?.parentElement;

        if (element) {
            element.scrollIntoView();
            highlight(element);
        }
    };

    function highlight(obj) {
        var orig = obj.style.background;
        obj.style.background = "#FFCCCB";
        setTimeout(function () {
            obj.style.background = orig;
        }, 5000);
    }

    useEffect(() => {
        if (jumptoActivityId != null) {
            jumpToItem(jumptoActivityId);
        }
    }, [jumptoActivityId]);

    const handleFullScreenPreviewRequest = (caseActivityEvent) => {
        try {
            handleFullScreenOpen(caseActivityEvent);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Paper className={classes.card}>
            {loadingEvents ? (
                <div className={classes.loader}>
                    <CircularProgress size={48} />
                </div>
            ) : (
                events &&
                events.map((e, index) => (
                    <CaseHistoryItem
                        caseEvent={e}
                        mode={mode}
                        key={index}
                        miscActivityTypes={miscActivityTypes}
                        internalUsers={internalUsers}
                        downloading={downloading}
                        downloadCaseActivityIds={downloadCaseActivityIds}
                        handleMenuClick={handleOpenContextMenu}
                        handlePreviewClick={handleFullScreenPreviewRequest}
                        handleEventChecked={handleEventChecked}
                        isUnitisedTime={c?.caseSummary?.isUnitisedTime}
                        onEditDescription={onEditDescription}
                    />
                ))
            )}
        </Paper>
    );
};

export default CaseHistorySummary;
