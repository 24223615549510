import React, { useState } from "react";
import {
    Box,
    Button,
    CardContent,
    Card,
    CircularProgress,
    Divider,
    makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CappedContractProgress from "../components/CappedContractProgress";
import ContractDialog from "../components/dialogs/ContractDialog";
import useContractSummary from "../hooks/queries/useContractSummary";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(6),
    },
    divider: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
    }
}));

const CaseCappedContractCard = ({ contractId }) => {

    const classes = useStyles();
    const [isContractDialogOpen, setIsContractDialogOpen] = useState(false);

    const { data, isError, error, isLoading } = useContractSummary({
        contractId,
    });

    const handleContractDialogClose = () => {
        setIsContractDialogOpen(false);
    }

    const handleViewContractClick = () => {
        setIsContractDialogOpen(true);
    }

    let content;
    if (isLoading) {
        content =
            (
                <Box display="flex" width="100%" justifyContent="center">
                    <CircularProgress />
                </Box>
            );
    }
    else if (isError) {
        content =
            (
                <Box display="flex" width="100%" justifyContent="center">
                    <Alert variant="outlined" severity="error">
                        {error?.message || "Could not load contract"}
                    </Alert>
                </Box>
            );
    }
    else if (!data.isCapped) {
        return ("");
    }
    else {
        content = (
            <>
                <CappedContractProgress
                    used={data.nonChargeableFriendly}
                    total={data.cappedFriendly}
                    progress={data.cappedPercentage}
                    billingType={data.billingTypeFriendly}
                    showBillingType={data.isUnitisedTime}
                    showWarningState={data.cappedWarning}
                />
                <Divider className={classes.divider} />
                <Box display="flex" justifyContent="end">
                    <Button
                        onClick={handleViewContractClick}
                        color="secondary">
                        View Contract
                    </Button>
                </Box>
            </>
        )
    }

    return (
        <>
            <Card className={classes.card}>
                <CardContent>
                    {content}
                </CardContent>
            </Card>
            <ContractDialog
                open={isContractDialogOpen}
                handleClose={handleContractDialogClose}
                contract={data}
            />
        </>
    )
};

export default CaseCappedContractCard;