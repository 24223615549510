import { useMutation } from "react-query";
import queryKeys from "../../constants/queryKeys";
import reactQueryClient from "../../reactQueryClient";
import userAutomaticEmailReplyService from "../../services/userAutomaticEmailReplyService";

const useUpdateUserAutomaticEmailReply = () =>
    useMutation(
        (reply) =>
            userAutomaticEmailReplyService.updateForUser(reply),
        {
            onSuccess: (data) => {
                const queryKey = [queryKeys.userAutomaticEmailReply, data.userId];
                reactQueryClient.setQueryData(queryKey, (old) => [...(old || []), data]);
            },
        }
    );

export default useUpdateUserAutomaticEmailReply;