import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import axios from "../plugins/axios";
import DocumentDialog from "../components/DocumentDialog";
import documentService from "../services/documentService";
import PasswordProtectedFileDialog from "./dialogs/PasswordProtectedFileDialog";

import {
    Box,
    Button as MuiIconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Tooltip,
    CircularProgress,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Grid,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { setFullScreenEmail, setUserTimerRunning, openFileViewer } from "../redux/actions/userActions";

import { setEmailExceptionFilterAction } from "../redux/actions/configActions";

import {
    setReplySubjectAction,
    setReplyContactsAction,
    setReplyNonContactsAction,
    setCaseViewStateAction,
    setReplyEditorHtmlAction,
    setInboundEmailNonContactsAction,
    setInboundEmailContactsAction,
    setReplyingToAction,
    setAiGeneratedPrecedentIds,
    resetAiResponse,
} from "../redux/actions/caseActions";

import { setSnackAction } from "../redux/actions/snackActions";
import caseTabs from "../constants/caseTabs";

import formatEmailSubject from "../helpers/formatEmailSubject";
import { useEmailDraft } from "../contexts/emailDraftContext";
import useAiResponse from "../hooks/useAiResponse";
import { pendingAiResponseStatus } from "../constants/pendingAiResponseStatus";
import { aIPrecedentIdRegex, aIPrecedentTagRegex } from "../constants/regexPatterns";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const FullScreenEmail = ({
    open,
    email,
    setFullScreenEmail,
    replyEnabled,
    isUserTimerRunning,
    setUserTimerRunning,
    caseState,
    setSnackAction,
    setReplySubject,
    setReplyContacts,
    setCaseViewState,
    openFileViewer,
    userProfile,
    addBarButtons,
    setEmailExceptionFilter,
    configState,
    setInboundEmailContacts,
    setReplyingTo,
    setReplyNonContacts,
    setInboundEmailNonContacts,
    setReplyEditorHtml
}) => {
    const dispatch = useDispatch();
    const [dialogToConfirmEmailNonLegalOpen, setDialogToConfirmEmailNonLegalOpen] = useState(false);
    const [incomingEmailDomain, setIncomingEmailDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectPartnerOpen, setSelectPartnerOpen] = useState(false);
    const [partner, setPartner] = useState("");
    const [documentGuid, setDocumentGuid] = useState(null);
    const [passwordProtectedFile, setPasswordProtectedFile] = useState(null);
    const [replyLoading, setReplyLoading] = useState(false);
    const [pendingAiResponseEmail, setPendingAiResponseEmail] = useState(null);

    const caseId = caseState.currentCaseId;
    const c = caseState.cases[caseId];

    const { reset } = useEmailDraft();

    const { pendingAiResponse } = useSelector((state) => state.caseReducer);

    const { getAiResponse, allowAiResponse } = useAiResponse();

    const getEmailDomain = (email) => email.match(/(?=[^@]*$).*$/)[0];
    const checkEmailDomainIsBarred = (incDomain) => Object.values(configState.emailExceptionFilters).filter(d => d.domain === incDomain);
    const checkEmailAddressIsBarred = (incEmailAdd) => Object.values(configState.emailExceptionFilters).filter(d => d.emailAddress === incEmailAdd);

    const handleClose = () => {
        setFullScreenEmail(null);
    };

    const handleOpenAttachment = async (attachment) => {
        try {

            console.log({ attachment });

            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference: attachment.reference,
                contentType: attachment.contentType,
                filename: attachment.filename
            });

            if (previewPath == null) {
                setPasswordProtectedFile(attachment);
                return;
                //dispatch(setSnackAction(
                //    "File may be password protected, download from case files tab and open locally to view content",
                //    "warning"
                //));
                //return;
            }

            //var response = await axios.get(
            //    `${apiUrl}/document/retrieve/${attachment.reference}`,
            //    { responseType: "arraybuffer" }
            //);

            //let blob = new Blob([response.data], { type: attachment.contentType });
            let parsedFileName = attachment.filename.split(".");
            openFileViewer(
                {
                    type: parsedFileName[parsedFileName.length - 1],
                    path: previewPath,
                    name: attachment.filename,
                },
                null,
                attachment
            );
        } catch (error) {
            dispatch(setSnackAction("File may be password protected, download from case files tab and open locally to view content", "warning"));
            console.error(error);
        }
    };

    const contactsWithEmail = () => {
        let contacts = [];
        Object.values(c.contacts).forEach((contact) => {
            if ((contact.email || contact.email2) && !contact.disabled) contacts.push(contact);
        });

        return contacts;
    };

    useEffect(() => {
        if (pendingAiResponse?.status === pendingAiResponseStatus.COMPLETE && open) {
            if (pendingAiResponse?.emailId === pendingAiResponseEmail?.email.emailId)
                handleReplyFromModal(
                    {
                        ...pendingAiResponseEmail.email,
                        aiGeneratedResponse: pendingAiResponse.response,
                    },
                    pendingAiResponseEmail.isReplyAll
                );
            dispatch(resetAiResponse());
        }
    }, [pendingAiResponse, pendingAiResponseEmail, open]);

    const handleReplyFromModal = async (e, isReplyAll) => {

        // this is emaildraftcontect reset - some dispatches may be repeated - needs some consolidation
        reset();

        setFullScreenEmail(null);
        
        if (isUserTimerRunning)
            setUserTimerRunning(false);

        if (!contactsWithEmail().length) {
            setSnackAction("No contact has a valid email address", "warning");
            return;
        }

        if (e.caseActivityId && e.content) {
            setReplyingTo(e);
        }
        setReplyLoading(true);
        let emailContent = "";

        if (allowAiResponse) {
            emailContent = getAiResponse({ email: e });

            if (!emailContent) {
                if (pendingAiResponse?.emailId === e.emailId && pendingAiResponse?.status === pendingAiResponseStatus.PENDING) {
                    setPendingAiResponseEmail({ email: e, isReplyAll });
                    return;
                }
            }
        }

        const precedentTags = emailContent.match(aIPrecedentTagRegex);
        const precedentIds = [];
        precedentTags && precedentTags.forEach((tag) => {
            precedentIds.push(parseInt(tag.match(aIPrecedentIdRegex)[0], 10));
            emailContent = emailContent.replaceAll(tag, "");
        });

        if (precedentIds.length > 0)
            dispatch(setAiGeneratedPrecedentIds(caseId, precedentIds));

        setReplySubject(formatEmailSubject(e.subject, caseId));        

        if (!!emailContent) setReplyEditorHtml(caseId, emailContent.replaceAll("\n", "<br>"));

        if (e.person) {
            let toEmailAdds = [];
            //e.toAddress holds all other addresses stored in the "to"
            if (e.toAddress && isReplyAll) {
                var tmpToAdds = e.toAddress;

                let lastChar = tmpToAdds.slice(-1);
                if (lastChar === ";") {
                    tmpToAdds = tmpToAdds.slice(0, -1);
                }

                toEmailAdds = tmpToAdds.split(";");
                toEmailAdds.push(e.person);
            } else {
                toEmailAdds = e.person;
            }
            const toEmailRecs = contactsWithEmail().filter(contactsWithEmail => toEmailAdds.includes(contactsWithEmail.email));
            
            if (toEmailRecs.length === 0)
                setReplyNonContacts(caseId, [toEmailAdds]);
            else
                setReplyContacts(caseId, toEmailRecs);
            

            if (isReplyAll) {
                //now set the unknown incoming addresses
                let names = toEmailRecs.map(item => item.email);
               
                //get the email addresses that are not known and have already been included in the above setReplyContacts
                let difference = toEmailAdds.filter((x) => !names.includes(x));
                setReplyNonContacts(caseId, difference);
            }

            //if replyall then set the known and unknown cc addresses
            let fromCCEmails = [];
            if (e.ccAddresses && isReplyAll) {
                var tmpCCAdds = e.ccAddresses;

                let lastChar = tmpCCAdds.slice(-1);
                if (lastChar === ";")
                    tmpCCAdds = tmpCCAdds.slice(0, -1);                

                fromCCEmails = tmpCCAdds.split(";");

                const toEmailRecs = contactsWithEmail().filter(contactsWithEmail => fromCCEmails.includes(contactsWithEmail.email));
                setInboundEmailContacts(caseId, toEmailRecs);

                let names = toEmailRecs.map(item => item.email);                

                let difference = fromCCEmails.filter((x) => !names.includes(x));
                setInboundEmailNonContacts(caseId, difference);
            } else {
                setInboundEmailNonContacts(caseId, null);
                setInboundEmailContacts(caseId, null);
            }
        }

        setReplyLoading(false);
        setCaseViewState(caseId, {
            ...c.viewState,
            mode: caseTabs.replying,
            currentTab: 5,
        });
    };

    useEffect(() => {
        if (email) {
            const domain = getEmailDomain(email.person);
            setIncomingEmailDomain(domain);
        }
    }, [email]);

    if (!email)
        return null;

    const handleOpenConfirmBarringDialog = () => {
        setDialogToConfirmEmailNonLegalOpen(true);
    };
    
    const handleCloseEmailNonLegalDialog = (_, reason) => {
        if (reason === "backdropClick") {
            return;
        }

        setDialogToConfirmEmailNonLegalOpen(false);
    };

    const flagEmail = async (isDomain, isSpam, thisOnly) => {
        var response = await axios.post(`${apiUrl}/flagemail`, {
            domain: isDomain ? incomingEmailDomain : null,
            emailAddress: isDomain ? null : email.person,
            userId: userProfile.userId,
            graphMessageId: email.graphMessageId,
            thisOnly,
            isSpam,
        });

        if (response.status === 200) {
            setEmailExceptionFilter(response.data);
            setDialogToConfirmEmailNonLegalOpen(false);
        }
    };

    const requestMembershipDetails = async (partner) => {
        setLoading(true);
        const replyingToContent = `
                <div>
                    <div>From: ${email.person}</div>
                    <div>Date: ${moment(email.eventTime).format("DD/MM/YYYY HH:mm (G[M]TZ)")}</div>
                    <div>To : ${email.toAddress}</div>
                    <div>Subject: ${email.subject}</div>
                    ${email.content}
                </div>
            `;

        let response = await axios.post(`${apiUrl}/requestmembershipdetails`, {
            partner,
            address: email.person,
            replyingToContent,
            subject: email.subject,
        });

        if (response.status === 200) {
            handleClose();
            setSnackAction("Requested Membership Details!", "success");
        } else setSnackAction("Something went wrong", "error");

        setLoading(false);
        handleCloseSelectPartner();
    };

    const handleRequestMembershipDetails = () => {
        if (email.partner) requestMembershipDetails(email.partner);
        else setSelectPartnerOpen(true);
    };

    const handleCloseSelectPartner = (_, reason) => {
        if (reason === "backdropClick") {
            return;
        }

        setPartner("");
        setSelectPartnerOpen(false);
    };

    const handleCloseDiaViewer = () => {
        setDocumentGuid(null);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const el = e.target.closest("a");

        let id = null;

        if (el) id = el.getAttribute("id");

        if (el && e.currentTarget.contains(el) && id && id.includes("documentGuid-")) {
            let documentGuid = id.slice(13);
            setDocumentGuid(documentGuid);
        } else if (el && e.currentTarget.contains(el)) {
            let href = el.getAttribute("href");
            if (href) window.open(href, "_blank");
        }
    };

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"md"}>
                <DialogContent onClick={handleClick}>
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <div>
                                <span style={{ fontWeight: "bold" }}>From: </span>
                                {email.fromAddress ? email.fromAddress : email.person}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Sent: </span>
                                {<Moment format="DD/MM/YYYY HH:mm">{email.eventTime}</Moment>}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>To: </span>
                                {email.toAddress}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Subject: </span>
                                {email.subject}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Cc: </span>
                                {email.ccAddresses}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Bcc: </span>
                                {email.bccAddresses}
                            </div>
                            {email.attachments && email.attachments.length > 0 && (
                                <Box display="flex" flexWrap="wrap" style={{ padding: "5px 0" }}>
                                    {email.attachments.map((a) => (
                                        <Box key={a.reference}>
                                            <Tooltip title={a.filename}>
                                                <Link onClick={() => handleOpenAttachment(a)}>
                                                    {" "}
                                                    <AttachFileIcon
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        title={a.filename}
                                                        fontSize="small"
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                        {replyLoading && <CircularProgress />}
                        {replyEnabled && !replyLoading ? (
                            <div>
                                <MuiIconButton
                                    onClick={() => handleReplyFromModal(email, false)}
                                    color="primary"
                                    variant="contained"
                                    style={{ marginRight: "12px" }}
                                >
                                    Reply
                                </MuiIconButton>
                                <MuiIconButton onClick={() => handleReplyFromModal(email, true)} color="primary" variant="contained">
                                    Reply All
                                </MuiIconButton>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {checkEmailAddressIsBarred(email.person).length > 0 && (
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "red",
                                    float: "right",
                                }}
                            >
                                {" "}
                                ** Note - Future emails from this sender are barred **"
                            </span>
                        )}
                        {checkEmailDomainIsBarred(incomingEmailDomain).length > 0 && (
                            <span
                                style={{
                                    fontWeight: "bold",
                                    color: "red",
                                    float: "right",
                                }}
                            >
                                {" "}
                                ** Note - Future emails received from anyone from this domain are barred **
                            </span>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <React.Fragment>
                                    <div style={{ paddingRight: "12px" }}>
                                        <MuiIconButton onClick={handleRequestMembershipDetails} color="primary" variant="contained">
                                            Request Membership Details
                                        </MuiIconButton>
                                    </div>
                                    {addBarButtons &&
                                    checkEmailAddressIsBarred(email.person)
                                        .length === 0 &&
                                    checkEmailDomainIsBarred(
                                        incomingEmailDomain
                                    ).length === 0 ? (
                                        <div>
                                            <MuiIconButton mr={2} onClick={handleOpenConfirmBarringDialog} color="primary" variant="outlined">
                                                Flag email as Non-Legal
                                            </MuiIconButton>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                    <div dangerouslySetInnerHTML={{ __html: email.content }}></div>
                </DialogContent>
            </Dialog>
            <DocumentDialog open={!!documentGuid} onClose={handleCloseDiaViewer} documentGuid={documentGuid} />
            <Dialog
                open={dialogToConfirmEmailNonLegalOpen}
                onClose={handleCloseEmailNonLegalDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Mark future emails as non-legal</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm the level that you wish to prevent future emails from hitting the legal app
                    </DialogContentText>
                    <Grid container>
                        <Grid item xs={6}>
                            <MuiIconButton
                                style={{ height: "90px", width: "100%" }}
                                onClick={() => flagEmail(false, false, false)}
                                variant="outlined"
                                color="primary"
                            >
                                Mark emails from {email.person} as Non-Legal and return to Inbox
                            </MuiIconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiIconButton
                                style={{ height: "90px", width: "100%" }}
                                onClick={() => flagEmail(true, false, false)}
                                variant="outlined"
                                color="primary"
                            >
                                Mark all emails from the domain {incomingEmailDomain} and return to Inbox
                            </MuiIconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiIconButton
                                style={{ height: "90px", width: "100%" }}
                                onClick={() => flagEmail(false, true, false)}
                                variant="outlined"
                                color="primary"
                            >
                                Mark emails from {email.person} as SPAM
                            </MuiIconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiIconButton
                                style={{ height: "90px", width: "100%" }}
                                onClick={() => flagEmail(true, true, false)}
                                variant="outlined"
                                color="primary"
                            >
                                Mark all emails from the domain {incomingEmailDomain} as SPAM
                            </MuiIconButton>{" "}
                        </Grid>
                        <Grid item xs={12}>
                            <MuiIconButton
                                style={{ height: "90px", width: "100%" }}
                                onClick={() => flagEmail(false, false, true)}
                                variant="outlined"
                                color="primary"
                            >
                                Just return this email to Inbox as a one off
                            </MuiIconButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <MuiIconButton onClick={handleCloseEmailNonLegalDialog} color="default">
                        Cancel
                    </MuiIconButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={selectPartnerOpen}
                onClose={handleCloseSelectPartner}
            >
                <DialogTitle>Select Partnership</DialogTitle>
                <DialogContent>
                    <DialogContentText>Looks like this email wasn't sent to a partnership Inbox. Please select a partnership.</DialogContentText>
                    <Box display="flex" justifyContent="center">
                        <FormControl>
                            <InputLabel>Partnership</InputLabel>
                            <Select style={{ minWidth: "150px" }} value={partner} onChange={(e) => setPartner(e.target.value)}>
                                {Object.values(configState.partnerships)
                                    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
                                    .map((p) => (
                                        <MenuItem key={p.id} value={p.title}>
                                            {p.title}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <React.Fragment>
                            <MuiIconButton onClick={handleCloseSelectPartner} color="default">
                                Cancel
                            </MuiIconButton>
                            <MuiIconButton
                                disabled={partner === ""}
                                onClick={() => requestMembershipDetails(partner)}
                                color="primary"
                            >
                                Send Request
                            </MuiIconButton>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
            <PasswordProtectedFileDialog
                open={!!passwordProtectedFile}
                onClose={() => setPasswordProtectedFile(null)}
                fileName={passwordProtectedFile?.customName || passwordProtectedFile?.title}
                caseString={" Case " + caseId}
                attachment={passwordProtectedFile}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    open: state.userReducer.fullScreenEmail.isOpen,
    email: state.userReducer.fullScreenEmail.email,
    userProfile: state.userReducer.userProfile,
    replyEnabled: state.userReducer.fullScreenEmail.replyEnabled,
    isUserTimerRunning: state.userReducer.isUserTimerRunning,
    addBarButtons: state.userReducer.fullScreenEmail.addBarButtons,
    caseState: state.caseReducer,
    configState: state.configReducer,
});

const mapDispatchToProps = (dispatch) => ({
    setReplyingTo: (replyingTo) => dispatch(setReplyingToAction(replyingTo)),
    setFullScreenEmail: (email) => dispatch(setFullScreenEmail(email)),
    setUserTimerRunning: (isRunning) => dispatch(setUserTimerRunning(isRunning)),
    setSnackAction: (message, severity) =>  dispatch(setSnackAction(message, severity)),
    setReplySubject: (subject) => dispatch(setReplySubjectAction(subject)),
    setReplyEditorHtml: (caseId, html) => dispatch(setReplyEditorHtmlAction(caseId, html)),
    setReplyContacts: (caseId, contacts) => dispatch(setReplyContactsAction(caseId, contacts)),
    setCaseViewState: (caseId, viewState) => dispatch(setCaseViewStateAction(caseId, viewState)),
    openFileViewer: (file, caseId, attachment) => dispatch(openFileViewer(file, caseId, attachment)),
    setEmailExceptionFilter: (emailExceptions) => dispatch(setEmailExceptionFilterAction(emailExceptions)),
    setInboundEmailContacts: (caseId, contacts) => dispatch(setInboundEmailContactsAction(caseId, contacts)),
    setReplyNonContacts: (caseId, nonContacts) => dispatch(setReplyNonContactsAction(caseId, nonContacts)),
    setInboundEmailNonContacts: (caseId, emails) => dispatch(setInboundEmailNonContactsAction(caseId, emails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenEmail);
