import React, { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@material-ui/core";
import useAccountFiles from "../hooks/queries/useAccountFiles";
import useDeleteAccountFile from "../hooks/mutations/useDeleteAccountFile";
import axios from "../plugins/axios";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { tableSortingOrder } from "../constants/tableConstants";
import { getComparator, stableSort } from "../utils/tableUtils";
import useAddAccountFile from "../hooks/mutations/useAddAccountFile";
import { setSnackAction } from "../redux/actions/snackActions";
import { downloadFileFromBytes } from "../utils/fileUtils";
import {
    CloudDownload as DownloadIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
    },
    profilePhoto: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
    },
    attachNewFileInput: {
        display: "none",
    },
    attachFileButton: {
        marginLeft: theme.spacing(4),
    },
    padTop: {
        paddingTop: theme.spacing(2),
    },
}));

const tableHeadCells = [
    {
        id: "name",
        label: "Name",
        isSortable: true,
    },
    {
        id: "dateAdded",
        label: "Date Added",
        isSortable: true,
    },
];

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

function AccountFiles({ accountId, fileType, documentType }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { data, isLoading, isError, error } = useAccountFiles(
        accountId,
        fileType,
        documentType
    );
    const addAccountFile = useAddAccountFile();
    const [order, setOrder] = useState(tableSortingOrder.ASC);
    const [orderBy, setOrderBy] = useState("dateAdded");
    const [fileToRemove, setFileToRemove] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const deleteAccountFile = useDeleteAccountFile();

    const onSortClick = (property) => {
        const isAsc = orderBy === property && order === tableSortingOrder.ASC;
        setOrder(isAsc ? tableSortingOrder.DESC : tableSortingOrder.ASC);
        setOrderBy(property);
    };

    const openDeleteFilePopup = (e, form) => {
        e.stopPropagation();
        setFileToRemove(form);
        setIsDeleteOpen(true);
    };

    const downloadPdf = async (e, fileToDownload) => {
        e.stopPropagation();
        var response = await axios.get(
            `${apiUrl}/DownloadDocument/${documentType}?fileName=${fileToDownload.name}`,
            { responseType: "arraybuffer" }
        );
        downloadFileFromBytes({
            bytes: response.data,
            fileName: `${fileToDownload.displayName}`,
            fileType: `${fileToDownload.contentType}`,
        });
    };

    const handleRemoveFile = async (e) => {
        e.stopPropagation();

        deleteAccountFile.mutate(
            {
                fileType,
                accountFile: fileToRemove.name,
                accountId,
                documentType,
            },
            {
                onError: (e) =>
                    dispatch(
                        setSnackAction(
                            e?.message || "There was an error removing File",
                            "error"
                        )
                    ),
                onSuccess: () => {
                    dispatch(setSnackAction("File removed", "success"));
                },
            }
        );
        setIsDeleteOpen(false);
    };

    const handleUploadAccountFile = async (newFile) => {
        if (newFile.target.files[0]) {
            const formData = new FormData();
            formData.append("file", newFile.target.files[0]);
            formData.append("fileName", newFile.target.files[0].name);
            formData.append("accountId", accountId);
            formData.append("accountFileTypeId", fileType);

            try {
                addAccountFile.mutate(
                    { formData, accountId, fileType, documentType },
                    {
                        onError: (e) =>
                            dispatch(
                                setSnackAction(
                                    e?.message ||
                                        "There was an error adding the File",
                                    "error"
                                )
                            ),
                        onSuccess: () =>
                            dispatch(setSnackAction("File Added", "success")),
                    }
                );
            } catch (error) {
                console.error(error);
            }
        }
    };

    const tableContent = () => {
        if (isLoading)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress size={24} />
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (isError)
            return (
                <TableRow>
                    <TableCell colSpan={5}>
                        <Box display="flex" justifyContent="center">
                            <Typography>
                                {error?.message ||
                                    "Could not fetch files for account"}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            );

        if (data && data.length > 0)
            return stableSort(data, getComparator(order, orderBy)).map(
                (file) => (
                    <TableRow key={`${file.name}-${file.dateAdded}`}>
                        <TableCell>{file.displayName}</TableCell>
                        <TableCell>
                            {format(
                                new Date(file.dateAdded),
                                "dd/MM/yyyy HH:mm"
                            )}
                        </TableCell>
                        <TableCell align="center">
                            <IconButton onClick={(e) => downloadPdf(e, file)}>
                                <DownloadIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">
                            <IconButton
                                onClick={(e) => openDeleteFilePopup(e, file)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            );

        return (
            <TableRow>
                <TableCell colSpan={5}>
                    <Box display="flex" justifyContent="center">
                        <Typography>No associated documents found.</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Paper className={classes.card}>
            <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.padTop}
            >
                <Button
                    className={classes.attachFileButton}
                    variant="outlined"
                    color="primary"
                    component="label"
                >
                    <input
                        type="file"
                        variant="outlined"
                        color="primary"
                        className={classes.attachNewFileInput}
                        onChange={handleUploadAccountFile}
                    />
                    Attach new file to account
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeadCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                sortDirection={
                                    headCell.isSortable &&
                                    orderBy === headCell.id
                                        ? order
                                        : false
                                }
                            >
                                {headCell.isSortable ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : tableSortingOrder.ASC
                                        }
                                        onClick={() => onSortClick(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ))}
                        <TableCell align="center">Download</TableCell>
                        <TableCell align="center">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableContent()}</TableBody>
            </Table>

            <Dialog
                open={isDeleteOpen}
                onClose={() => setIsDeleteOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Removal
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to remove the file
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsDeleteOpen(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveFile} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default AccountFiles;
