const caseFiles = "case-files";
const eTCaseDetails = "et-case-details";
const adviserTimesheet = "adviser-timesheet";
const caseActions = "case-actions";
const caseActionCategories = "case-action-categories";
const caseActionTypes = "case-action-types";
const teams = "teams";
const caseOutcomes = "case-outcomes";
const eTClaims = "et-claims";
const caseActionsDashboard = "case-actions-dashboard";
const caseExternalContacts = "case-external-contacts";
const clientActivityTimesheet = "client-activity-timesheet";
const accountFiles = "account-files";
const youManageEmployees = "youmanage-employees";
const caseTimeEventGroups = "case-time-event-groups";
const caseFilesTree = "case-files-tree";
const draftFiles = "draft-files";
const adviceTypes = "advice-types";
const userAutomaticEmailReply = "user-automatic-email-reply";
const teamUsersForManager = "team-users-for-manager";
const voicemail = "voicemail";
const emailPreview = "email-preview";
const caseActivities = "case-activities";
const caseHistoryExport = "case-history-export";
const accountContractSummary = "account-contract-summary";
const accountLeiDetails = "account-lei-details";
const contractSummary = "contract-summary";
const caseTime = "case-time";
const cases = "cases";
const draftEmailsSummary = "draft-emails-summary";
const coreServices = "core-services";
const serviceTypes = "service-types";
const accountsSlim = "accounts-slim";
const employeeLinkedCases = "employee-linked-cases";
const caseAccountEmployees = "case-account-employees";
const seekAdviceActivity = "seek-advice-activity";
const raiseCaseRequest = "raise-case-request";
const invoiceSearchesPaged = "invoice-searches-paged";
const timesheetByActivity = "timesheet-by-activity";

const featureFlag = "feature-flag";

const queryKeys = {
    caseFiles,
    eTCaseDetails,
    adviserTimesheet,
    caseActions,
    caseActionCategories,
    caseActionTypes,
    teams,
    caseOutcomes,
    eTClaims,
    caseActionsDashboard,
    caseExternalContacts,
    clientActivityTimesheet,
    accountFiles,
    youManageEmployees,
    caseTimeEventGroups,
    caseFilesTree,
    draftFiles,
    adviceTypes,
    userAutomaticEmailReply,
    teamUsersForManager,
    voicemail,
    emailPreview,
    caseActivities,
    caseHistoryExport,
    accountContractSummary,
    accountLeiDetails,
    contractSummary,
    draftEmailsSummary,
    caseTime,
    cases,
    coreServices,
    serviceTypes,
    accountsSlim,
    employeeLinkedCases,
    caseAccountEmployees,
    seekAdviceActivity,
    raiseCaseRequest,
    invoiceSearchesPaged,
    timesheetByActivity,
    featureFlag,
};

export default queryKeys;
