
import InputControl from "../helpers/inputControl"
import RadioGroupControl from "../helpers/radioGroupControl"
import DDListControl from "../helpers/ddListControl"
import ButtonControl from "../helpers/buttonControl"
import PopupControl from "../helpers/popupControl"
import SwitchControl from "../helpers/switchControl"

export const Controls = {
    InputControl,
    RadioGroupControl,
    DDListControl,
    ButtonControl,
    PopupControl,
    SwitchControl
}

//not used 
export const PartnershipData_Temp = () => ([
    { id: '1', title: 'BICA' },
    { id: '2', title: 'AA' },
    { id: '3', title: 'RAC' },
])