import { useQuery } from "react-query";
import queryKeys from '../../constants/queryKeys'
import caseService from "../../services/caseService";

const useCaseFilesTree = (caseId, downloadableOnly, order, orderBy) => useQuery(
    [queryKeys.caseFilesTree, caseId, downloadableOnly, order, orderBy],
    () => caseService.fetchCaseFilesTree(caseId, downloadableOnly, order, orderBy),
    {
        enabled: !!caseId
    })

export default useCaseFilesTree;