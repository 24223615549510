import React, { useState } from "react";
import {
    alpha,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Typography,
    Collapse,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ContractTimeCard from "../ContractTimeCard";
import CappedContractProgress from "../CappedContractProgress";
import { abbreviatedDateTime } from "../../utils/dateTimeFormatters";
import { formatTimeDigital } from "../../helpers/formatTime";

const useStyles = makeStyles((theme) => ({
    contractDetailsContainer: {
        margin: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between"
    },
    contractDetailsTitle: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    contractDetailsSubheading: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(4),
    },
    contractDetailsDates: {
        textAlign: "right",
        color: theme.palette.primary.main,
        marginRight: theme.spacing(10),
    },
    dialogContent: {
        borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        padding: theme.spacing(7),
        paddingBottom: theme.spacing(4),
    },
    hourlyRateChip: {
        borderRadius: 4,
        background: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    usageBar: {
        marginBottom: 0,
    },
    productDetailsContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textProductDetails: {
        fontWeight: 600,
        marginRight: theme.spacing(1),
        cursor: "pointer",
    },
    arrow: {
        color: theme.palette.secondary.main,
        cursor: "pointer",
    },
    textBilling: {
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    cappedContractProgressContainer: {
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        borderRadius: 4,
        padding: theme.spacing(4, 3),
    },
}));

const ContractDialog = ({
    open,
    handleClose,
    contract
}) => {

    const classes = useStyles();

    const [expand, setExpand] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md">
            {!contract ? <Typography variant="subtitle2">Invalid contract.</Typography> :
                <>
                    <DialogTitle>
                        <div className={classes.contractDetailsContainer}>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="baseline">
                                    <Typography variant="h1" className={classes.contractDetailsTitle}>
                                        {contract?.coreService}
                                    </Typography>
                                    {contract.jobCode && <Typography variant="body1" color="primary">{`(${contract.jobCode})`}</Typography>}
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" className={classes.contractDetailsSubheading}>
                                        {contract.typeFriendly}
                                    </Typography>
                                    {contract.hourlyRateFriendly && <Chip className={classes.hourlyRateChip} label={contract.hourlyRateFriendly} />}
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box display="flex" flexDirection="column" className={classes.contractDetailsDates}>
                                    <Typography variant="caption">
                                        Start Date
                                    </Typography>
                                    <Typography variant="h6">
                                        {abbreviatedDateTime(contract.startDate)}
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" textAlign="right">
                                    <Typography variant="caption" color="primary">
                                        End Date
                                    </Typography>
                                    <Typography variant="h6" color="primary">
                                        {abbreviatedDateTime(contract.endDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                        {contract.productDetails && 
                            <div className={classes.productDetailsContainer}>
                                <Box display="flex" className={classes.productDetailsRow}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography
                                            variant="body1"
                                            color="secondary"
                                            className={classes.textProductDetails}
                                            onClick={() => setExpand(!expand)}
                                        >
                                            Product Details
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <div
                                            aria-label="expand row"
                                            className={classes.arrow}
                                            onClick={() => setExpand(!expand)}
                                        >
                                            {expand ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </div>
                                    </Box>
                                </Box>
                                <Collapse in={expand} timeout="auto" unmountOnExit>
                                    <Typography variant="body1" color="primary">
                                        {contract.productDetails}
                                    </Typography>
                                </Collapse>
                            </div>
                        }
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" className={classes.usageBar}>
                                    <Typography variant="h5" color="primary">
                                        Usage
                                    </Typography>
                                    <Box display="flex">
                                        <Typography variant="body1" color="primary" className={classes.textBilling}>
                                            Billing Type:
                                        </Typography>
                                        <Typography variant="body1" color="primary">
                                            {contract.billingTypeFriendly}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {
                                contract.isCapped &&
                                <Grid item xs={12}>
                                    <Box className={classes.cappedContractProgressContainer}>
                                        <CappedContractProgress
                                            used={contract.nonChargeableFriendly}
                                            total={contract.cappedFriendly}
                                            progress={contract.cappedPercentage}
                                            billingType={contract.billingTypeFriendly}
                                            showBillingType={contract.isUnitisedTime}
                                            showWarningState={contract.cappedWarning}
                                        />
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={4}>
                                <ContractTimeCard
                                    title="Non-Chargeable"
                                    time={contract.nonChargeableFriendly}
                                    timeInMinutes={formatTimeDigital(contract.nonChargeableDuration)}
                                    billingType={contract.billingTypeFriendly}
                                    showBillingType={contract.isUnitisedTime}
                                    showMinutesAddendum={contract.isUnitisedTime}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ContractTimeCard
                                    title="Chargeable"
                                    time={contract.chargeableFriendly}
                                    timeInMinutes={contract.chargeableDurationFriendly}
                                    billingType={contract.billingTypeFriendly}
                                    showBillingType={contract.isUnitisedTime}
                                    showMinutesAddendum={contract.isUnitisedTime}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ContractTimeCard
                                    title="Total"
                                    time={contract.totalFriendly}
                                    timeInMinutes={formatTimeDigital(contract.totalDuration)}
                                    billingType={contract.billingTypeFriendly}
                                    showBillingType={contract.isUnitisedTime}
                                    showMinutesAddendum={contract.isUnitisedTime}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            }
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContractDialog;