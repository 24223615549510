import React from 'react';

import {
	Card as MuiCard,
	CardContent,
	Box,
	TextField,
	Button as MuiIconButton,
	CircularProgress
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
	Save as SaveIcon
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const ClientNotesCard = ({ noteText, handleNoteChange, saveNote, saving, noteEdited }) => {
	return (
		<Card>
			<CardContent>
				<Box display="flex" justifyContent="flex-end">
					{saving ? <CircularProgress size={21} style={{ marginLeft: '9px' }} /> : noteEdited ? <IconButton onClick={saveNote}><SaveIcon /></IconButton> : <div></div>}
				</Box>
				<TextField
					style={{ width: '100%' }}
					label="Client Notes"
					multiline
					minRows={8}
					variant="outlined"
					value={noteText}
					onChange={handleNoteChange}
				/>
			</CardContent>
		</Card>
	)
}

export default ClientNotesCard;