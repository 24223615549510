const userRoles = {
    LEGAL_ADMIN: "legal:admin",
    LEGAL_MANAGER: "legal:manager",
    SUPER_USER: "legal:superuser",
    LEGAL_ADVISOR: "legal:advisor",
    HRC_ASSOCIATE: "legal:hrcassociate",
    AI_TRAINER: 'legal:aitrainer',
};

export default userRoles;
