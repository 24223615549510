import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import avatarStyles from '../theme/avatars';
import useCaseExternalContacts from "../hooks/queries/useCaseExternalContacts";
import useUpdateCaseExternalContact from "../hooks/mutations/useUpdateCaseExternalContact";
import { setSnackAction } from "../redux/actions/snackActions";
import phoneImage from '../images/phone.svg';
import { dial } from "../redux/actions/flexActions";
import ExternalContactDialog from "../components/ExternalContactDialog";
import {
    Avatar,
    Box,
    CardContent,
    Card,
    Button,
    Menu,
    MenuItem,
    Typography,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    MoreHoriz
} from '@material-ui/icons';
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles"

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        margin: theme.spacing(6,0),
    },
    moreIcons: {
        marginRight: theme.spacing(1),
        color: grey[700]
    },
    centText: {
        textAlign: 'center' 
    },
    phoneNo: {
        cursor: `url(${phoneImage}) 12 12, auto`
    }
}))


const ExternalContactsCard = ({ setReplyContacts, setCaseContacts, setSnackAction }) => {
    const classes = useStyles();
    const avatarClasses = avatarStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [activeContact, setActiveContact] = useState({});
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextContact, setContextContact] = useState(null);
    const { currentCaseId: caseId, cases } = useSelector(state => state.caseReducer);
    const c = cases[caseId];
    const contracts = c.account.contracts;
    const contractId = c.caseSummary.contractId;
    const accountId = c.account.accountId;


    const { data, isLoading, isError, error } = useCaseExternalContacts({ caseId });

    const {
        mutate: updateCaseExternalContact,
        isLoading: isUpdateCaseExternalContactLoading,
    } = useUpdateCaseExternalContact();


    const { hasRole } = useAuth();

    const handleDelete = async contact => {

        handleCloseContextMenu();

        let contactToSave = {
            ...contact,
            isEnabled: false
        };

        updateCaseExternalContact({ caseExternalContact: contactToSave },
            {
                onError: (e) =>
                    setSnackAction(e?.message || "There was an error removing contact details", "error"),
                onSuccess: () => {
                    setSnackAction("Successfully removed contact", "success");
                },
            }
        );

    };

    const handleEditContact = contact => {
        handleCloseContextMenu();
        setActiveContact(contact);
        setOpenDialog(true);
    };

    const dialNumber = (number, contactId) => {
        if (!hasRole(userRoles.LEGAL_ADVISOR))
            return;

        let outboundNumber = contracts.filter(
            contract => contract.contractId === contractId
        )[0].outboundNumber;

        if (outboundNumber) {
            // strip whitespace
            outboundNumber = outboundNumber.replace(/\s+/g, '');

            // replace leading '0' with '+44'
            if (outboundNumber.startsWith('0'))
                outboundNumber = '+44' + outboundNumber.substring(1);
        }

        dial(number, contactId, accountId, outboundNumber);
    };


    const DisplayContacts = 
        data && data
            .filter(ec => ec.isEnabled == true)
            .map(contact => 
                <Box
                    key={contact.externalContactId}
                    className={classes.centText}
                    mt={3} mb={2} ml={2} mr={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'>
                    <Box alignSelf="flex-end">
                        <Button onClick={ev => handleOpenContextMenu(contact, ev)}>
                            <MoreHoriz />
                        </Button>
                    </Box>
                    <Box>
                        <Avatar
                            alt={contact.name}
                            src=''
                            className={avatarClasses.large}
                        />
                    </Box>
                    <Box>
                        <Typography variant='subtitle2'>{contact.name}</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2'>{contact.organisation}</Typography>
                    </Box>
                    {contact.phone && (
                        <Box>
                            <Typography
                                className={classes.phoneNo}
                                variant='caption'
                                onClick={() => dialNumber(contact.phone, contact.externalContactId)
                                }>
                                {contact.phone}
                            </Typography>
                        </Box>
                    )}
                </Box>
            );
    

    const handleOpenContextMenu = (e, event) => {
        setContextContact(e);
        setContextAnchorEl(event.currentTarget);
    }

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextContact(null);
    }

    return (
        <div className={classes.cardContainer}>
            <Card>
                <CardContent>
                    <Box display='flex' alignItems='center' flexWrap='wrap'>
                        <Box flexGrow={1}>
                            <Typography variant='h6' gutterBottom>
                                External Contacts
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                onClick={e => setOpenDialog(true)}>
                                    <AddIcon />
                                </Button>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'>
                        <Box
                            display='flex'
                            justifyContent='space-around'
                            flexWrap='wrap'
                            width='100%'
                        >
                            {
                            isLoading ?
                                <Box display="flex" justifyContent="center" width="100%">
                                    <CircularProgress />
                                </Box>
                            :isError ?
                                <Box display="flex" justifyContent="center" width="100%">
                                    <Alert variant="outlined" severity="error">
                                       {error?.message || "Unable to load case actions"}
                                    </Alert>
                                </Box>
                            :DisplayContacts 
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <ExternalContactDialog
                open={openDialog}
                setOpen={setOpenDialog}
                caseId={caseId}
                activeContact={activeContact}
                setActiveContact={setActiveContact}
                existingContacts={data && data.filter(ec => ec.isEnabled == true)}
            />

            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={Boolean(contextAnchorEl)}
                onClose={handleCloseContextMenu}
            >
                <MenuItem onClick={() => handleEditContact(contextContact)}><EditIcon className={classes.moreIcons} />
                    Edit
                </MenuItem>
                {isUpdateCaseExternalContactLoading ?
                    <CircularProgress /> :
                    <MenuItem
                        onClick={() => handleDelete(contextContact)}>
                        <DeleteIcon className={classes.moreIcons} />Delete
                        </MenuItem>
                }
            </Menu>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnackAction: (message, severity) => dispatch(setSnackAction(message, severity)),
        setCaseContacts: (caseId, contacts) => dispatch(setCaseContacts(caseId, contacts)),
        dial: (number, contactId, accountId, outboundNumber) => dispatch(dial(number, contactId, accountId, outboundNumber)),
    }
}

export default connect(null, mapDispatchToProps)(ExternalContactsCard);


