import React, { useState, useEffect, useCallback, useMemo } from "react";
import { grey } from "@material-ui/core/colors";
import {
    makeStyles,
    Menu,
    MenuItem,
    Divider,
} from "@material-ui/core";
import {
    CloudDownload as DownloadIcon,
    Visibility,
    Description as TextIcon,
    DeleteOutlined as DeleteOutlinedIcon,
    EditOutlined as EditOutlinedIcon,
    TrendingFlatOutlined as TrendingFlatOutlinedIcon,
} from "@material-ui/icons";

import { caseFileActions, caseFileTypes, caseDocumentTypes, sortNameCol, sortByCol } from "../constants/caseFiles";
import useCaseFilesTree from "../hooks/queries/useCaseFilesTree";
import useUpdateCaseFiles from "../hooks/mutations/useUpdateCaseFiles";
import { useDispatch } from "react-redux";
import documentService from "../services/documentService";
import { createSharepointFile } from "../services/caseService";
import reactQueryClient from "../reactQueryClient";
import queryKeys from "../constants/queryKeys";
import { openFileViewer } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";

import TreeContent from "./casefiles/TreeContent";

import FolderDeleteDialog from "./dialogs/casefiles/FolderDeleteDialog";
import FileRenameDialog from "./dialogs/casefiles/FileRenameDialog";
import FileDeleteDialog from "./dialogs/casefiles/FileDeleteDialog";
import FolderNameDialog from "./dialogs/casefiles/FolderNameDialog";
import MoveDialog from "./dialogs/casefiles/MoveDialog";
import PasswordProtectedFileDialog from "./dialogs/PasswordProtectedFileDialog";

const useStyles = makeStyles((theme) => ({
    menuIcon: {
        color: grey[700],
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const supportedPreviewTypes = ["pdf", "csv", "png", "jpeg", "gif", "docx", "doc", "bmp", "xslx", "mp4", "webm", "mp3", "xls", "xlsx", "precedent"];

const Files = ({ caseId, setMilestoneViewerOpen, setDocumentGuid }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [sortName, setSortName] = useState(sortNameCol.DATE);
    const [sortBy, setSortBy] = useState(sortByCol.DESC);
    const [mobileSort, setMobileSort] = useState(sortNameCol.DATE + "-" + sortByCol.DESC);
    const { data, isLoading, isError, error } = useCaseFilesTree(caseId, false, sortNameCol.DATE, sortByCol.DESC);
    const updateCaseFiles = useUpdateCaseFiles();
    const [folderToRemove, setFolderToRemove] = useState(null);
    const [folderDeleteDialogOpen, setFolderDeleteDialogOpen] = useState(false);
    const [folderId, setFolderId] = useState(null);
    const [folderName, setFolderName] = useState("");
    const [folderDialogOpen, setFolderDialogOpen] = useState(false);
    const [filesToMarkAsRemoved, setFilesToMarkAsRemoved] = useState([]);
    const [precedentsToMarkAsRemoved, setPrecedentsToMarkAsRemoved] = useState([]);
    const [treeData, setTreeData] = useState(data);
    const [fileInfo, setFileInfo] = useState(null);
    const [fileRenameDialogOpen, setFileRenameDialogOpen] = useState(false);
    const [customFileName, setCustomFileName] = useState("");
    const [treeToSave, setTreeToSave] = useState();
    const [fileDeleteDialogOpen, setFileDeleteDialogOpen] = useState(false);
    const [fileToRemove, setFileToRemove] = useState(null);
    const [foldersToMarkAsRemoved, setFoldersToMarkAsRemoved] = useState([]);
    const [passwordProtectedFile, setPasswordProtectedFile] = useState(null)

    // context menu 
    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextItem, setContextItem] = useState(null);
    const [contextType, setContextType] = useState(null);

    const [moveDialogOpen, setMoveDialogOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [newParent, setNewParent] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [moveParent, setMoveParent] = useState(null);

    const untitledBaseName = "Untitled Folder";

    useEffect(() => {
        if (treeToSave) {
            setTreeData(treeToSave);
            const caseFilesContent = {
                treeData: treeToSave,
                filesToMarkAsRemoved,
                precedentsToMarkAsRemoved,
                foldersToMarkAsRemoved,
            };

            updateCaseFiles.mutate(
                { caseId, downloadableOnly: false, caseFilesContent },
                {
                    onError: (e) =>
                        dispatch(
                            setSnackAction(
                                e?.message ||
                                "There was an error updating case files",
                                "error"
                            )
                        ),
                    onSuccess: () => { },
                }
            );
        }
    }, [
        treeToSave,
        filesToMarkAsRemoved,
        precedentsToMarkAsRemoved,
        foldersToMarkAsRemoved,
    ]);

    useEffect(() => {
        setTreeData(data);
        //Set new parent and move parent to show correct current folder in tree
        setNewParent(newParent?.folderId ? findFolderById(data, newParent.folderId) : null);
        setMoveParent(moveParent?.folderId ? findFolderById(data, moveParent.folderId) : null);
    }, [data]);

    const findFolderById = (folders, targetId) => {
        const findParent = (folders, targetId) => {
            for (const folder of folders) {
                if (folder.docType === "Folder" && folder.folderId === targetId) {
                    return folder;
                }
                if (folder.children && folder.children.length > 0) {
                    const result = findParent(folder.children, targetId);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };
        return findParent(folders, targetId);
    };

    const updateTreeData = (items, itemToMove, currentParent, fileAction) => {
        const updatedItems = [...items];

        const findAndRemoveItem = (itemId, array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].folderId === itemId) {
                    array.splice(i, 1);
                    return true;
                } else if (array[i].children) {
                    if (findAndRemoveItem(itemId, array[i].children)) {
                        return true;
                    }
                }
            }
            return false;
        };

        const addItemToParent = (parentId, item, parentChildren) => {
            for (let i = 0; i < parentChildren.length; i++) {
                if (parentChildren[i].folderId === parentId) {
                    parentChildren[i].children.push(item);
                    return true;
                } else if (parentChildren[i].children) {
                    if (addItemToParent(parentId, item, parentChildren[i].children)) {
                        return true;
                    }
                }
            }
            return false;
        };

        const removeItem = (itemToMove, updatedItems) => {
            for (let i = 0; i < updatedItems.length; i++) {
                if (itemToMove.docType == "File" && itemToMove.dateAdded == updatedItems[i].dateAdded) {
                    //file match
                    updatedItems.splice(i, 1);
                    return;
                } else if (itemToMove.folderId != null && updatedItems[i].folderId === itemToMove.folderId) {
                    //folder match
                    updatedItems.splice(i, 1);
                    return;
                } else if (updatedItems[i].children) {
                    removeItem(itemToMove, updatedItems[i].children);
                }
            }
        };

        if (!currentParent) {
            // Move to root level
            if (fileAction === caseFileActions.MOVE || fileAction === caseFileActions.REMOVE) findAndRemoveItem(itemToMove.folderId, updatedItems);
            if (fileAction === caseFileActions.MOVE || fileAction === caseFileActions.ADD) updatedItems.push(itemToMove);
        } else {
            // Move to a new parent
            if (fileAction === caseFileActions.MOVE || fileAction === caseFileActions.REMOVE) removeItem(itemToMove, updatedItems);
            if (fileAction === caseFileActions.MOVE || fileAction === caseFileActions.ADD) addItemToParent(currentParent.folderId, itemToMove, updatedItems);
        }

        return updatedItems;
    };

    const onDocumentViewClick = async (file) => {
        if (file.type === caseDocumentTypes.PRECEDENT_DOCUMENT) {
            handlePrecedentFilePreview(
                file.precedentFileMetadata.precedentIdentifier
            );
            return;
        }

        await handleAzureFilePreview({
            documentName: file.customName || file.name,
            reference: file.azureFileMetadata.azureFileReference,
            contentType: file.azureFileMetadata.contentType,
            extension: file.extension,
        });
    };

    const handlePrecedentFilePreview = (identifier) => {
        setDocumentGuid(identifier);
        setMilestoneViewerOpen(true);
    };

    const handleAzureFilePreview = async ({
        documentName,
        reference,
        contentType,
        extension,
    }) => {
        try {
            let attachment = {
                reference,
                contentType,
                filename: documentName
            }

            const previewPath = await documentService.retrieveDocumentPreviewPath(attachment);

            if (previewPath === null) {
                setPasswordProtectedFile(attachment);
                return;
            }

            dispatch(
                openFileViewer(
                    {
                        type: extension,
                        path: previewPath,
                        name: documentName,
                    },
                    caseId,
                    attachment
                )
            );
        } catch (e) {
            setPasswordProtectedFile(attachment);
            console.error(e);
        }
    };

    const doesNameExist = useMemo(() => {
        return (folderList, name, fileType) => {
            if (!folderList) return false;
            return (
                folderList.some((folder) =>
                    fileType === caseFileTypes.FOLDER
                        ? folder.title === name
                        : folder.customName === name
                ) ||
                folderList.some((folder) =>
                    doesNameExist(folder.children, name, fileType)
                )
            );
        };
    }, []);


    const findUntitledNumberedFolder = (baseName, folderList) => {
        const usedNumbers = {};
        if (!usedNumbers[baseName]) usedNumbers[baseName] = 0;
        let nextNumber = usedNumbers[baseName];
        for (let i = 0; i < folderList.length; i++) {
            const folder = folderList[i];
            if (folder.title.startsWith(baseName)) {
                nextNumber++;
                const childNumber = findUntitledNumberedFolder(baseName, folder.children);
                nextNumber = Math.max(nextNumber, childNumber);
            }
            if (folder.children && folder.children.length > 0) {
                const childNumber = findUntitledNumberedFolder(baseName, folder.children);
                nextNumber = Math.max(nextNumber, childNumber);
            }
        }
        usedNumbers[baseName] = nextNumber;
        return nextNumber > 0 ? nextNumber : null;
    };

    const validateFileFolder = useMemo(() => {
        return (newName, fileType) => {
            let newFileFolderName = newName.trim();

            // Character limit
            if (newFileFolderName.length > 50) {
                dispatch(setSnackAction(`${fileType} name exceeds character limit`, "error"));
                return false;
            }

            // Special characters
            var format = /[`!@#$%^&*()¬+=\[\]{};':"\\|,<>\/?~]/;
            if (format.test(newFileFolderName)) {
                dispatch(setSnackAction(`Invalid ${fileType.toLowerCase()} name`, "error"));
                return false;
            }

            // Find same title (or no title folders)
            const result = (() => {
                if (newName.includes(untitledBaseName)) newName = null;
                if (!newName && fileType === caseFileTypes.FOLDER) {
                    // If unnamed folder, find all Untitled Folders and increase number
                    const existingNumber = findUntitledNumberedFolder(untitledBaseName, treeData);
                    return existingNumber !== null
                        ? `${untitledBaseName} ${existingNumber}`
                        : untitledBaseName;
                } else if (!newName && fileType === caseFileTypes.FILE) {
                    //If unnamed file, return it
                    return newName;
                } else if (doesNameExist(treeData, newName, fileType)) {
                    // If name already exists then give error
                    dispatch(setSnackAction(`${fileType} name already exists`, "error"));
                    return false;
                } else {
                    return newName;
                }
            })();

            return result;
        };
    }, [findUntitledNumberedFolder, treeData]);

    const copyToSharepoint = async (contextItem) => {
        const filePath = await createSharepointFile(
            contextItem.azureFileMetadata.azureFileReference,
            caseId,
            contextItem.name
        );
        window.open(filePath, "_blank");
        getDraftFiles();
        dispatch(setSnackAction('File successfully opened in word', "success"));
        setContextAnchorEl(null);
    };

    const getDraftFiles = () => {
        reactQueryClient.invalidateQueries([queryKeys.draftFiles, caseId]);
    };

    const handleOpenContextMenu = useCallback(
        (e, event, fileType) => {
            setContextAnchorEl(event.currentTarget);
            setContextItem(e);
            setContextType(fileType);
            setMoveParent(newParent);
        },
        [newParent]
    );

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextItem(null);
        setContextType(null);
    }

    const handleRename = useCallback((contextItem) => {
        setContextAnchorEl(null);
        if (contextType === caseFileTypes.FOLDER) {
            setFolderId(contextItem.folderId);
            setFolderName(contextItem.title);
            setFolderDialogOpen(true);
        }
        if (contextType === caseFileTypes.FILE) {
            setFileInfo(contextItem);
            setCustomFileName(contextItem.customName);
            setFileRenameDialogOpen(true);
        }
    }, [contextType]);

    const handleDelete = useCallback((contextItem) => {
        setContextAnchorEl(null);
        if (contextType === caseFileTypes.FOLDER) {
            setFolderToRemove(contextItem);
            setFolderDeleteDialogOpen(true);
        }
        if (contextType === caseFileTypes.FILE) {
            setFileToRemove(contextItem);
            setFileDeleteDialogOpen(true);
        }
    }, [contextType]);

    const handleMove = useCallback((contextItem) => {
        setContextAnchorEl(null);
        setSelectedItem(contextItem);
        setMoveDialogOpen(true);
    }, []);

    const downloadDocument = async (attachment, file) => {
        let response;
        if (attachment) {
            response = await documentService.retrieveDocumentPreviewPath({
                reference: attachment.azureFileMetadata.azureFileReference,
                contentType: attachment.azureFileMetadata.contentType,
            });
        }
        let blob;
        if (file) {
            blob = new Blob([file], { type: file.type });
        }

        let link = document.createElement("a");
        link.href = attachment ? response : window.URL.createObjectURL(blob);
        link.download = attachment ? attachment.customName || attachment.name : file.name;
        link.click();
        dispatch(setSnackAction('File has been successfully downloaded', "success"));
        setContextAnchorEl(null);
    };
    return (
        <>
            <TreeContent
                treeData={treeData}
                setTreeData={setTreeData}
                handleOpenContextMenu={handleOpenContextMenu}
                isMove={false}
                setFolderDialogOpen={setFolderDialogOpen}
                newParent={newParent}
                setNewParent={setNewParent}
                breadcrumbs={breadcrumbs}
                setBreadcrumbs={setBreadcrumbs}
                moveParent={moveParent}
                setMoveParent={setMoveParent}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortName={sortName}
                setSortName={setSortName}
                mobileSort={mobileSort}
                setMobileSort={setMobileSort}
                caseId={caseId}
                findFolderById={findFolderById}
                updateTreeData={updateTreeData}
            />

            <Menu
                anchorEl={contextAnchorEl}
                keepMounted
                open={!!contextAnchorEl}
                onClose={handleCloseContextMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {contextType == caseFileTypes.FILE && (
                    <div>

                        {supportedPreviewTypes.includes(contextItem.extension.toLowerCase()) && <MenuItem
                            onClick={() => {
                                setFileInfo(contextItem)
                                onDocumentViewClick(contextItem)
                                setContextAnchorEl(null)
                            }}
                        >
                            <Visibility className={classes.menuIcon} />
                            Preview
                        </MenuItem>}
                        {contextItem.extension.toLowerCase().startsWith("doc") && (
                            <MenuItem
                                onClick={() => copyToSharepoint(contextItem)}
                            >
                                <TextIcon className={classes.menuIcon} />
                                Open a copy in Word
                            </MenuItem>
                        )}
                        {contextItem.azureFileMetadata &&
                            <MenuItem
                                onClick={() => downloadDocument(contextItem, null)}
                            >
                                <DownloadIcon className={classes.menuIcon} />
                                Download
                            </MenuItem>
                        }
                        <Divider className={classes.divider} />
                    </div>
                )}
                <MenuItem
                    onClick={() => handleMove(contextItem)}
                >
                    <TrendingFlatOutlinedIcon className={classes.menuIcon} />
                    Move to
                </MenuItem>
                <MenuItem
                    onClick={() => handleRename(contextItem)}
                >
                    <EditOutlinedIcon className={classes.menuIcon} />
                    Rename
                </MenuItem>
                <MenuItem
                    onClick={() => handleDelete(contextItem)}
                >
                    <DeleteOutlinedIcon className={classes.menuIcon} />
                    Delete
                </MenuItem>
            </Menu>

            <MoveDialog
                updateTreeData={updateTreeData}
                treeData={treeData}
                setTreeData={setTreeData}
                setTreeToSave={setTreeToSave}
                moveDialogOpen={moveDialogOpen}
                setMoveDialogOpen={setMoveDialogOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                moveParent={moveParent}
                setMoveParent={setMoveParent}
                newParent={newParent}
                setNewParent={setNewParent}
                breadcrumbs={breadcrumbs}
                setBreadcrumbs={setBreadcrumbs}
                handleOpenContextMenu={handleOpenContextMenu}
                setFolderDialogOpen={setFolderDialogOpen}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortName={sortName}
                setSortName={setSortName}
            />

            <FolderNameDialog
                treeData={treeData}
                setTreeToSave={setTreeToSave}
                folderDialogOpen={folderDialogOpen}
                setFolderDialogOpen={setFolderDialogOpen}
                folderName={folderName}
                setFolderName={setFolderName}
                validateFileFolder={validateFileFolder}
                folderId={folderId}
                setFolderId={setFolderId}
                newParent={newParent}
                untitledBaseName={untitledBaseName}
            />

            <FileDeleteDialog
                updateTreeData={updateTreeData}
                treeData={treeData}
                setTreeToSave={setTreeToSave}
                fileDeleteDialogOpen={fileDeleteDialogOpen}
                setFileDeleteDialogOpen={setFileDeleteDialogOpen}
                fileToRemove={fileToRemove}
                caseDocumentTypes={caseDocumentTypes}
                precedentsToMarkAsRemoved={precedentsToMarkAsRemoved}
                setPrecedentsToMarkAsRemoved={setPrecedentsToMarkAsRemoved}
                filesToMarkAsRemoved={filesToMarkAsRemoved}
                setFilesToMarkAsRemoved={setFilesToMarkAsRemoved}
                newParent={newParent}
            />

            <FileRenameDialog
                treeData={treeData}
                setTreeToSave={setTreeToSave}
                fileRenameDialogOpen={fileRenameDialogOpen}
                setFileRenameDialogOpen={setFileRenameDialogOpen}
                customFileName={customFileName}
                setCustomFileName={setCustomFileName}
                validateFileFolder={validateFileFolder}
                fileInfo={fileInfo}
            />

            <FolderDeleteDialog
                updateTreeData={updateTreeData}
                treeData={treeData}
                setTreeToSave={setTreeToSave}
                folderDeleteDialogOpen={folderDeleteDialogOpen}
                setFolderDeleteDialogOpen={setFolderDeleteDialogOpen}
                folderToRemove={folderToRemove}
                foldersToMarkAsRemoved={foldersToMarkAsRemoved}
                setFoldersToMarkAsRemoved={setFoldersToMarkAsRemoved}
                newParent={newParent}
            />

            <PasswordProtectedFileDialog
                open={!!passwordProtectedFile}
                onClose={() => setPasswordProtectedFile(null)}
                fileName={passwordProtectedFile?.filename}
                caseString={" Case " + caseId}
                attachment={passwordProtectedFile}
            />
        </>
    );
};

export default Files;