import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Typography as MuiTypography,
    Grid,
    Avatar,
    Switch,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from "@material-ui/icons";
import avatarStyles from "../theme/avatars";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import AdviserAdviceTypes from "../components/AdviserAdviceTypes";
import userRoleService from "../services/userRoleService";
import userService from "../services/userService";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AdviserCard = ({ adviser }) => {
    const avatarClasses = avatarStyles();

    const { applicationRoles } = useSelector((state) => state.configReducer);
    const legalRoleIds = useMemo(
        () => applicationRoles["Legal"]?.map((x) => x.roleId),
        [applicationRoles]
    );
    const hrcRoleId = useMemo(
        () =>
            applicationRoles["Legal"]?.filter(
                (x) => x.value === userRoles.HRC_ASSOCIATE
            )[0]?.roleId,
        [applicationRoles]
    );

    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const [userRoleIds, setUserRoleIds] = useState([]);
    const [disabledSwitches, setDisabledSwitches] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [isActiveDisabled, setIsActiveDisabled] = useState(false);

    const { hasRole } = useAuth();

    useEffect(() => {
        async function getuserRoles() {
            try {
                const response = await userRoleService.getUserRoles({
                    email: adviser.email,
                    userId: adviser.userId,
                });
                setUserRoleIds(response.data.roleIds);
                setIsActive(response.data.isActive);
            } catch (e) {
                console.error(e);
            }
        }
        getuserRoles();
    }, []);

    const setRole = async (e, app, roleId, roleValue) => {
        setDisabledSwitches([...disabledSwitches, roleId]);
        const checked = e.target.checked;

        const dto = {
            userEmail: adviser.email,
            roles: [
                {
                    roleId,
                    hasRole: checked,
                    app,
                },
            ],
        };

        if (roleValue === userRoles.HRC_ASSOCIATE && checked)
            dto.roles = [
                ...dto.roles,
                ...legalRoleIds
                    .filter((id) => id !== hrcRoleId)
                    .map((id) => ({
                        roleId: id,
                        hasRole: false,
                        app: "Legal",
                    })),
            ];

        try {
            await userRoleService.updateUserRoles(dto);
            if (roleValue === userRoles.HRC_ASSOCIATE && checked) {
                setUserRoleIds((prev) => [
                    ...prev.filter((id) => !legalRoleIds.includes(id)),
                    roleId,
                ]);
                return;
            }

            const newRoleSet = userRoleIds.includes(roleId)
                ? userRoleIds.filter((r) => r !== roleId)
                : [...userRoleIds, roleId];

            setUserRoleIds(newRoleSet);
        } catch (e) {
            console.error(e);
        } finally {
            setDisabledSwitches(disabledSwitches.filter((r) => r !== roleId));
        }
    };

    const makeActive = async (e) => {
        const isChecked = e.target.checked;

        setIsActiveDisabled(true);

        try {
            await userService.setUserActive({
                userId: adviser.userId,
                isActive: isChecked,
            });
            setIsActive(isChecked);
        } catch (e) {
            console.error(e);
        } finally {
            setIsActiveDisabled(false);
        }
    };

    return (
        <Card mb={3}>
            <CardContent>
                <Typography variant="h6" mb={6}>
                    Adviser
                </Typography>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Avatar
                            alt={adviser.name}
                            src={adviser.photo}
                            className={avatarClasses.xlarge}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            {adviser.position}
                        </Typography>
                    </Grid>
                </Grid>
                {hasRole(userRoles.SUPER_USER) && (
                    <React.Fragment>
                        <Divider my={3} />
                        <AdviserAdviceTypes userId={adviser.userId} />
                        <Divider my={3} />
                        <Typography variant="h6" mb={6}>
                            Roles
                        </Typography>
                        <List component="nav">
                            <ListItem button>
                                <ListItemIcon>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                onChange={makeActive}
                                                checked={isActive}
                                                disabled={isActiveDisabled}
                                            />
                                        }
                                        labelPlacement="start"
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Is Active" />
                                {isActiveDisabled && (
                                    <CircularProgress size={24} />
                                )}
                            </ListItem>
                            {Object.entries(applicationRoles).map(
                                ([key, roles]) => (
                                    <React.Fragment key={key}>
                                        <ListItem button onClick={handleClick}>
                                            <ListItemText primary={key} />
                                            {open ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </ListItem>
                                        <Collapse
                                            in={open}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List
                                                component="div"
                                                disablePadding
                                            >
                                                {roles.map((r) => (
                                                    <ListItem
                                                        button
                                                        key={r.roleId}
                                                    >
                                                        <ListItemIcon>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        size="small"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setRole(
                                                                                e,
                                                                                key,
                                                                                r.roleId,
                                                                                r.value
                                                                            )
                                                                        }
                                                                        checked={userRoleIds.includes(
                                                                            r.roleId
                                                                        )}
                                                                        disabled={
                                                                            disabledSwitches.includes(
                                                                                r.roleId
                                                                            ) ||
                                                                            (legalRoleIds.includes(
                                                                                r.roleId
                                                                            ) &&
                                                                                userRoleIds.includes(
                                                                                    hrcRoleId
                                                                                ) &&
                                                                                r.roleId !==
                                                                                    hrcRoleId)
                                                                        }
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                                color="primary"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={r.name}
                                                        />
                                                        {disabledSwitches.includes(
                                                            r.roleId
                                                        ) && (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                )
                            )}
                        </List>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
};

export default AdviserCard;
