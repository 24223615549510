import React from "react";
import { mergeSelectedFiltersWithFetchedFilters } from "../../helpers/mergeSelectedFiltersWithFetchedFilters";
import FilterWrapper from "./FilterWrapper";
import TuilePill from "./TuilePill";

const HistoryUserFilter = ({
    users = [],
    selectedUsers = {},
    isLoading,
    onUserClick,
}) => {
    const sortUsers = () => {
        const mergedFilters = mergeSelectedFiltersWithFetchedFilters(
            selectedUsers,
            users,
            "userExternalId"
        );

        if (!mergedFilters?.length) return [];

        return mergedFilters.sort((a, b) => a?.label?.localeCompare(b?.label));
    };

    const sortedUsers = sortUsers();

    return (
        <FilterWrapper filterName="User" isLoading={isLoading} hasBtnContainer>
            {sortedUsers.length > 0
                ? sortedUsers.map((user) => (
                      <TuilePill
                          name={user.label}
                          count={user.count}
                          callbackValue={user}
                          ariaLabel={`${user.label} button`}
                          isSelected={selectedUsers.hasOwnProperty(
                              user.userExternalId
                          )}
                          onClick={onUserClick}
                      />
                  ))
                : null}
        </FilterWrapper>
    );
};

export default HistoryUserFilter;
