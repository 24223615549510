import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "../../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "../../plugins/axios";
import { actionCreators } from "../../redux/reducers/caseReducer";
import { setFullScreenEmail } from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";

import { Box, CardContent, Card, Typography } from "@material-ui/core";

import {
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
} from "@material-ui/icons";

import CaseHistorySummary from "../../components/CaseHistorySummary";

import caseActivityTypes from "../../constants/caseActivityTypes";
import NotePreviewDialog from "../../components/dialogs/NotePreviewDialog";
import useEmailByCaseActivityId from "../../hooks/queries/useEmailByCaseActivityId";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    perfScroll: {
        marginRight: theme.spacing(-3),
        maxHeight: "calc(100vh - 510px)",
    },
}));

const { NOTE, MISC, INTERNAL_NOTE, EMAIL } = caseActivityTypes;

const AICaseHistoryCard = ({ openDocument, setFullScreenEmail, caseId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [events, setEvents] = useState();

    // paging
    const [pageNumber, setPageNumber] = useState(1);
    const pagedEvents = useMemo(() => {
        return events?.slice((pageNumber - 1) * 50, pageNumber * 50);
    }, [events, pageNumber]);

    const pageUp = () => {
        if (pageNumber < Math.ceil(events.length / 50))
            setPageNumber(pageNumber + 1);
    };

    const pageDown = () => {
        if (pageNumber > 1) setPageNumber(pageNumber - 1);
    };

    // full screen modal
    const [open, setOpen] = useState(false);
    const [caseActivity, setCaseActivity] = useState(null);
    const [emailActivity, setEmailActivity] = useState(null);

    const [loadingEvents, setLoadingEvents] = useState(false);

    useEmailByCaseActivityId({
        caseActivityId: emailActivity?.caseActivityId,
        onSuccess: (email) => {
            dispatch(
                setFullScreenEmail(
                    {
                        ...email.data,
                        ...emailActivity,
                        content: email.data.content,
                        person: email.data.person,
                    },
                    true
                )
            );
            setEmailActivity(null);
        },
    });

    useEffect(() => {
        async function getAllCaseActivityDataAsync() {
            setLoadingEvents(true);

            var response = await axios.get(
                `${apiUrl}/getallcaseactivities/${caseId}`
            );

            setEvents(response.data.caseActivities);

            setLoadingEvents(false);
        }

        if (caseId) {
            getAllCaseActivityDataAsync();
            setPageNumber(1);
        }
    }, [caseId]);

    const handleFullScreenOpen = (e) => {
        if (
            e.itemType === NOTE ||
            e.itemType === INTERNAL_NOTE ||
            e.itemType === MISC
        ) {
            setCaseActivity(e);
            setOpen(true);
        }

        if (e.itemType === EMAIL) {
            setEmailActivity(e);
        }
    };

    const handleFullScreenNoteClose = () => {
        setOpen(false);
        setCaseActivity(null);
    };

    const countInfo = useMemo(() => {
        return events
            ? `${events.length} item${events.length == 1 ? "" : "s"}`
            : "loading...";
    }, [events]);

    return (
        <React.Fragment>
            <Card mb={6}>
                <CardContent>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" gutterBottom>
                                Case History ({countInfo})
                            </Typography>
                        </Box>
                    </Box>
                    {events && events.length > 50 && (
                        <Box>
                            <Typography>
                                Showing items {(pageNumber - 1) * 50 + 1} to{" "}
                                {Math.min(pageNumber * 50, events.length)}
                            </Typography>
                            <LeftIcon onClick={pageDown} />
                            <RightIcon onClick={pageUp} />
                        </Box>
                    )}
                    <PerfectScrollbar className={classes.perfScroll}>
                        <CaseHistorySummary
                            handleFullScreenOpen={handleFullScreenOpen}
                            events={pagedEvents}
                            loadingEvents={loadingEvents}
                        />
                    </PerfectScrollbar>
                </CardContent>
            </Card>
            <NotePreviewDialog
                open={open}
                caseActivity={caseActivity}
                handleFullScreenNoteClose={handleFullScreenNoteClose}
                openDocument={openDocument}
            />
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actionCreators, dispatch),
    setFullScreenEmail: (email, replyEnabled) =>
        dispatch(setFullScreenEmail(email, replyEnabled)),
});

export default connect(null, mapDispatchToProps)(AICaseHistoryCard);
